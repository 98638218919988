import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TuiAlertService,
  TuiButtonModule,
  TuiLabelModule,
  TuiNotification,
} from '@taiga-ui/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {
  Auth,
  PhoneAuthProvider,
  signInWithCredential,
} from '@angular/fire/auth';
import { FirebaseError } from '@angular/fire/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { getTranslationErrorId } from '../../../../../../utils/translate-utils';
import { CodeInputModule } from 'angular-code-input';

@Component({
  selector: 'rdw-login-phone-code',
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    TranslocoModule,
    TuiLabelModule,
    CodeInputModule,
  ],
  template: `
    <h2 class="headline2 md:headline1 text-primary mt-12 mb-5">
      {{ prefixTranslation + 'code-title' | transloco }}
    </h2>
    <p class="body1 text-primary-dark mb-8">
      {{ prefixTranslation + 'code-description' | transloco }}
    </p>

    <code-input
      [isCodeHidden]="false"
      [codeLength]="6"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </code-input>

    <button
      [showLoader]="!!(isSendingCode | async)"
      [disabled]="!codeIsComplete"
      class="w-fit mx-auto mt-10"
      (click)="enterCode()"
      tuiButton
      size="l"
    >
      {{ prefixTranslation + 'code-connection' | transloco }}
    </button>

    <button
      class="w-fit mx-auto mt-2"
      (click)="previousStep.emit()"
      appearance="flat"
      tuiButton
      size="l"
    >
      {{ prefixTranslation + 'code-back' | transloco }}
    </button>
  `,
  styles: [
    `
      code-input {
        --item-spacing: 10px;
        --item-height: 3em;
        --item-border: none;
        --item-border-bottom: 2px solid #64193b;
        --item-border-has-value: none;
        --item-border-bottom-has-value: 2px solid #888888;
        --item-border-focused: none;
        --item-border-bottom-focused: 2px solid #64193b;
        --item-shadow-focused: none;
        --item-border-radius: 0px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPhoneCodeComponent implements OnInit {
  readonly prefixTranslation = 'navbar.login.modal.';
  @Output() previousStep = new EventEmitter<void>();
  @Output() connectionComplete = new EventEmitter<void>();
  private verifyId = '';
  private code = '';

  constructor(
    private readonly auth: Auth,
    private readonly tuiAlertService: TuiAlertService,
    private readonly translocoService: TranslocoService
  ) {}

  private _isSendingCode = new BehaviorSubject<boolean>(false);

  get isSendingCode(): Observable<boolean> {
    return this._isSendingCode.asObservable();
  }

  get codeIsComplete() {
    return this.code.length === 6;
  }

  ngOnInit() {
    this.verifyId = JSON.parse(
      localStorage.getItem('confirmationResult') || '{}'
    );
  }

  onCodeChanged($event: string) {
    this.code = $event;
  }

  onCodeCompleted($event: string) {
    this.code = $event;
  }

  async enterCode() {
    this._isSendingCode.next(true);
    const phoneAuthCredential = PhoneAuthProvider.credential(
      this.verifyId,
      this.code
    );

    try {
      await signInWithCredential(this.auth, phoneAuthCredential);
      this.connectionComplete.emit();
    } catch (e) {
      if (e instanceof FirebaseError) {
        this.tuiAlertService
          .open(
            this.translocoService.translate(getTranslationErrorId(e.code)),
            {
              status: TuiNotification.Error,
            }
          )
          .subscribe();
      }
    } finally {
      this._isSendingCode.next(false);
    }
  }
}
