import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoInterface } from '../../../../shared/interfaces/photo.interface';
import { Constant } from '../../../../shared/utils/constant';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import {
  TuiPreviewDialogService,
  TuiPreviewModule,
} from '@taiga-ui/addon-preview';
import { TuiSvgModule } from '@taiga-ui/core';
import ImageViewer from 'awesome-image-viewer';

@Component({
  selector: 'rdw-establishment-photos',
  standalone: true,
  imports: [CommonModule, TuiPreviewModule, PolymorpheusModule, TuiSvgModule],
  template: `
    <div class="grid grid-cols-5 gap-x-4 h-min">
      <div
        class="flex flex-col h-full grow-0 gap-y-2"
        *ngIf="photos.length > 1"
      >
        <img
          [src]="photo.src"
          class="aspect-square rounded-lg object-cover object-center"
          alt=""
          (click)="show(index + 1)"
          *ngFor="let photo of photos | slice: 1:3; let index = index"
        />

        <button
          class="aspect-square relative"
          (click)="show(3)"
          *ngIf="photos.length > 3"
        >
          <img
            [src]="getLastPhoto()"
            class="rounded-lg object-cover object-center w-full h-full"
            alt=""
          />

          <div
            class="absolute h-full w-full bg-black/60 top-0 left-0 rounded-lg flex flex-col items-center justify-center"
          >
            <div
              class="w-12 h-12 rounded-full bg-white/80 flex justify-center items-center"
            >
              <tui-svg src="tuiIconPlusLarge" class="text-secondary"></tui-svg>
            </div>
          </div>
        </button>
      </div>

      <div class="col-span-4 aspect-[4/3] row-span-3">
        <img
          (click)="show()"
          [src]="getFirstPhoto()"
          class="h-full w-full grow-0 object-cover object-center rounded-lg max-h-screen/2"
          alt=""
        />
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstablishmentPhotosComponent {
  @Input() photos: PhotoInterface[] = [];

  constructor(
    @Inject(TuiPreviewDialogService)
    private readonly previewService: TuiPreviewDialogService
  ) {}

  show(index: number = 0): void {
    new ImageViewer({
      images: this.photos.map((elem) => ({ mainUrl: elem.src })),
      currentSelected: index,
    });
  }

  getFirstPhoto() {
    return this.photos.length ? this.photos[0].src : Constant.noImgUrl;
  }

  getLastPhoto() {
    return this.photos.length > 3 ? this.photos[3].src : Constant.noImgUrl;
  }
}
