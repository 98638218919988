import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstablishmentInterface } from '../../../../shared/interfaces/establishment.interface';
import { Constant } from '../../../../shared/utils/constant';
import { TuiLinkModule } from '@taiga-ui/core';

@Component({
  selector: 'rdw-establishment-presentation-header',
  standalone: true,
  imports: [CommonModule, TuiLinkModule],
  template: `
    <div class="flex gap-x-7">
      <img [src]="getPhoto()" class="w-16 h-16 rounded-full object-cover" />
      <div class="flex flex-col flex-1 gap-y-2">
        <h1 class="headline2 text-primary">
          {{ establishment.name }}
        </h1>
        <div
          class="flex flex-col md:flex-row gap-y-4 gap-x-7 items-start justify-start"
        >
          <a
            target="_blank"
            [href]="createMapUrl()"
            tuiLink
            icon="tuiIconMapPin"
            iconAlign="left"
          >
            {{ establishment.address }}
          </a>

          <a
            href="tel:{{ establishment.phoneNumber }}"
            *ngIf="establishment.displayPhoneNumber"
            tuiLink
            icon="tuiIconPhone"
            iconAlign="left"
          >
            {{ establishment.phoneNumber }}
          </a>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstablishmentPresentationHeaderComponent {
  @Input() establishment!: EstablishmentInterface;

  getPhoto() {
    return this.establishment.photos[0]?.src || Constant.noImgUrl;
  }

  createMapUrl() {
    const { latitude, longitude } = this.establishment.localization;
    return `https://www.google.com/maps/place/${latitude},${longitude}`;
  }
}
