export type PersonJobType = 'pro' | 'part' | 'other';
export const personJobTypeList: {
  value: PersonJobType;
  description: string;
}[] = [
  {
    value: 'pro',
    description: 'general.person-job.pro',
  },
  {
    value: 'part',
    description: 'general.person-job.part',
  },
  {
    value: 'other',
    description: 'general.person-job.other',
  },
];

export const personJobToString = (personJob: PersonJobType): string => {
  switch (personJob) {
    case 'pro':
      return 'Professionnel';
    case 'part':
      return 'Particulier';
    case 'other':
      return 'Autre';
  }
};
