import { Injectable } from '@angular/core';
import { ClientService } from '../client/client.service';
import { map, Observable, switchMap } from 'rxjs';
import {
  collection,
  collectionData,
  doc,
  docData,
  docSnapshots,
  Firestore,
  query,
  QueryDocumentSnapshot,
  where,
} from '@angular/fire/firestore';
import { EstablishmentInterface } from '../../interfaces/establishment.interface';
import { VineyardLocationEnum } from '../../enums/vineyard-location.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {
  private readonly establishmentCollection = collection(
    this.firestore,
    'establishments'
  );

  establishments$ = this.clientService.favoriteEstablishmentIds$.pipe(
    switchMap((favorites) =>
      collectionData(
        query(this.establishmentCollection, where('active', '==', true)),
        { idField: 'id' }
      ).pipe(
        map((establishments) => establishments as EstablishmentInterface[]),
        map((establishments) =>
          establishments.map((establishment) => ({
            ...establishment,
            isFavorite: favorites.includes(establishment.id),
          }))
        )
      )
    )
  ) as Observable<EstablishmentInterface[]>;

  constructor(
    private readonly clientService: ClientService,
    private readonly firestore: Firestore,
    private readonly http: HttpClient
  ) {}

  findEstablishmentById(
    id: string
  ): Observable<QueryDocumentSnapshot<EstablishmentInterface> | undefined> {
    return docSnapshots(doc(this.establishmentCollection, id)).pipe(
      map((establishmentSnapshot) => {
        if (!establishmentSnapshot.exists()) {
          return undefined;
        }

        return establishmentSnapshot as QueryDocumentSnapshot<EstablishmentInterface>;
      })
    );
  }

  findEstablishment(id: string) {
    return docData(doc(this.firestore, `establishments/${id}`), {
      idField: 'id',
    }) as Observable<EstablishmentInterface>;
  }

  searchEstablishment(date?: Date, vineyardLocation?: VineyardLocationEnum) {
    return this.http
      .post(`${environment.baseApiUrl}search`, {
        date: date?.toISOString(),
        vineyardLocation,
      })
      .pipe() as Observable<EstablishmentInterface[]>;
  }

  establishmentAvailable() {
    return this.http
      .get<string[]>(`${environment.baseApiUrl}dateavailable`)
      .pipe(map((list) => list.map((item) => new Date(item))));
  }
}
