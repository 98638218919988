<tui-root>
    <rdw-navbar></rdw-navbar>
    <router-outlet></router-outlet>
    <div class="pb-12 md:pb-0">
        <rdw-footer></rdw-footer>
    </div>

    <!--<p class="bg-yellow-500 text-white fixed bottom-0 right-0 text-xl px-2">
        {{ name }}
    </p>-->

    <ng-container ngProjectAs="tuiOverContent">
        <!-- Content over app content -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverDialogs">
        <!-- Content over dialogs -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverAlerts">
        <!-- Content over notifications -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverPortals">
        <!-- Content over dropdowns -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverHints">
        <!-- Content over hints -->
    </ng-container>
</tui-root>
