<a [routerLink]="['/establishments', establishmentId, 'offers', offer.id]"
   class="flex flex-col bg-white drop-shadow rounded-lg overflow-hidden">
    <img [alt]="" [src]="getPhoto()" class="w-full aspect-square object-center object-cover mb-2">
    <p class="p-3 text-primary text-[16px] line-clamp-1 font-bold">{{offer.name}}</p>

    <div class="grid grid-cols-2 px-3">
        <div class="flex flex-1 justify-start items-center space-x-2">
            <tui-svg [src]="'tuiIconUser'" class="h-4 w-4 text-primary"></tui-svg>
            <p class="text-sm text-primary font-light">
                <ng-container *ngIf="offer.minPeople === offer.maxPeople; else minMax">
                    {{ prefixTranslation + 'people' | transloco:{people: offer.minPeople} }}
                </ng-container>

                <ng-template #minMax>
                    {{ prefixTranslation + 'min-max-people' | transloco:{min: offer.minPeople, max: offer.maxPeople} }}
                </ng-template>
            </p>
        </div>
        <div class="flex flex-1 justify-start items-center space-x-2">
            <tui-svg [src]="'tuiIconClock'" class="h-4 w-4 text-primary"></tui-svg>
            <p class="text-sm text-primary">
                {{ prefixTranslation + 'duration' | transloco:{duration: offer.duration} }}
            </p>
        </div>
    </div>

    <div class="flex p-3">
        <p *ngIf="offer.price > 0; else freePrice"
           [innerHTML]="prefixTranslation + 'price' | transloco:{price: offer.price}"
           class="text-md text-primary"></p>

        <ng-template #freePrice>
            <p [innerHTML]="prefixTranslation + 'free' | transloco" class="text-md text-primary"></p>
        </ng-template>
    </div>
</a>
