export const environment = {
  firebase: {
    projectId: 'rd-wine-dev',
    appId: '1:137106445614:web:7dfe4e15a27cef099f56bf',
    storageBucket: 'rd-wine-dev.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAQ5tSe6Omn_M31sgo3wGJqqtlEgvCK5ZU',
    authDomain: 'rd-wine-dev.firebaseapp.com',
    messagingSenderId: '137106445614',
    measurementId: 'G-30VJD18K7R',
  },
  baseApiUrl: 'https://us-central1-rd-wine-dev.cloudfunctions.net/',
  production: false,
};
