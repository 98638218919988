<div class="px-4 md:px-28 transform -translate-y-20">

    <div class="w-full h-20 bg-white rounded-lg flex items-center px-4 gap-x-4 divide-x">
        <div class="flex-1">
            <tui-select
                    *tuiLet="listVineyardLocation"
                    [(ngModel)]="selectedVineyardLocation"
                    [tuiTextfieldCleaner]="true"
                    [valueContent]="content"
                    class="w-full"
            >
                {{ prefixTranslation + 'vineyard-filter' | transloco }}
                <tui-data-list-wrapper
                        *tuiDataList
                        [itemContent]="content"
                        [items]="listVineyardLocation"
                        size="l"
                ></tui-data-list-wrapper>
            </tui-select>

            <ng-template
                #content
                let-data
            >
                <div class="template">
                    <div class="name">{{ 'general.vineyardLocation.' + data | transloco }}</div>
                </div>
            </ng-template>
        </div>
        <div class="flex-1">
            <tui-input-date
                    [(ngModel)]="selectedDate"
                    [disabledItemHandler]="selectableDays"
                    [tuiTextfieldCleaner]="true"
                    class="w-full"
                    tuiTextfieldSize="l"
            >
                {{ prefixTranslation + 'prefDate' | transloco}}
            </tui-input-date>
        </div>
        <button [queryParams]="{'vineyardLocation': selectedVineyardLocation, 'date': getFormattedDate()}"
                [routerLink]="['/establishments']" role="link"
                tuiButton>
            {{ prefixTranslation + 'search-button' | transloco }}
        </button>
    </div>
</div>
