import { Injectable } from '@angular/core';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';
import { Auth, authState } from '@angular/fire/auth';
import { map, Observable, of, switchMap } from 'rxjs';
import { ClientVisitModel } from '../../models/client-visit.model';
import { VisitInterface } from '../../interfaces/visit.interface';

@Injectable({
  providedIn: 'root',
})
export class VisitService {
  $visits: Observable<ClientVisitModel> = authState(this.auth).pipe(
    switchMap((user) => {
      if (!user) {
        return of(new ClientVisitModel([]));
      }

      return collectionData(
        collection(this.firestore, `clients/${user.uid}/visits`),
        { idField: 'id' }
      ).pipe(
        map((documents) => documents as VisitInterface[]),
        map((visits) => new ClientVisitModel(visits))
      );
    })
  );

  constructor(
    private readonly firestore: Firestore,
    private readonly auth: Auth
  ) {}
}
