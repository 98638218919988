import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiBreadcrumbsModule, TuiToggleModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiLabelModule, TuiLinkModule } from '@taiga-ui/core';
import { TranslocoModule } from '@ngneat/transloco';
import { map, tap } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EstablishmentService } from '../../shared/services/establishment/establishment.service';
import { EstablishmentCardComponent } from '../../shared/components/establishment-card/establishment-card.component';
import { EstablishmentMapComponent } from '../../shared/components/establishment-map/establishment-map.component';

@Component({
  selector: 'rdw-favorites',
  standalone: true,
  imports: [
    CommonModule,
    TuiBreadcrumbsModule,
    TuiLinkModule,
    TranslocoModule,
    TuiButtonModule,
    TuiToggleModule,
    ReactiveFormsModule,
    TuiLabelModule,
    EstablishmentCardComponent,
    EstablishmentMapComponent,
    FormsModule,
  ],
  template: `
    <div class="flex pt-20">
      <div class="w-full px-4 md:px-28" *ngIf="favorites$ | async as favorites">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
          <ng-container *ngFor="let item of breadcrumbItems">
            <a
              class="ariane text-primary-dark"
              *tuiItem
              tuiLink
              [href]="item.routerLink"
            >
              {{ item.caption | transloco }}
            </a>
          </ng-container>
        </tui-breadcrumbs>
        <div class="flex flex-row justify-between">
          <h1 class="headline1 text-primary">
            {{ prefixTranslation + 'title' | transloco }}
          </h1>
          <div
            class="flex md:flex-row md:space-x-2 md:items-center"
            *ngIf="favorites.length"
          >
            <tui-toggle
              nativeId="show-map"
              [(ngModel)]="showMapControl"
              size="l"
              class="toggle"
            ></tui-toggle>
            <label for="show-map" class="body2 text-neutral-dark font-medium">
              {{ prefixTranslation + 'show-map' | transloco }}
            </label>
          </div>
        </div>
        <ng-container *ngIf="favorites.length; else emptyFavorites">
          <div class="flex gap-x-3">
            <div
              [ngClass]="{
                'not-display-grid': showMapControl,
                'display-grid': !showMapControl
              }"
              class="mt-8 mb-32 gap-x-5 gap-y-4"
            >
              <rdw-establishment-card
                [establishment]="establishment"
                *ngFor="let establishment of favorites"
              ></rdw-establishment-card>
            </div>

            <rdw-establishment-map
              [ngClass]="{
                'flex-1': showMapControl,
                hidden: !showMapControl
              }"
              class="mt-8 mb-32"
              [establishments]="favorites"
            ></rdw-establishment-map>
          </div>
        </ng-container>
        <ng-template #emptyFavorites>
          <p class="body1 text-primary-dark mt-6">
            {{ prefixTranslation + 'empty-favorites' | transloco }}
          </p>
          <a
            href="/"
            tuiButton
            appearance="whiteblock-active"
            class="mt-8 mb-24"
            size="l"
          >
            {{ prefixTranslation + 'empty-button' | transloco }}
          </a>
          <h2 class="headline2 text-primary mb-8">
            {{ prefixTranslation + 'title-interest' | transloco }}
          </h2>
          <ng-container
            *ngIf="
              interestingEstablishment$ | async as interestingEstablishment
            "
          >
            <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-8 mb-32 gap-x-5 gap-y-4"
            >
              <rdw-establishment-card
                [establishment]="establishment"
                *ngFor="let establishment of interestingEstablishment"
              ></rdw-establishment-card>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  `,
  styleUrls: ['./favorites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesComponent {
  interestingEstablishment$ = this.establishmentService.establishments$.pipe(
    map((establishments) =>
      establishments
        .filter((establishment) => !establishment.isFavorite)
        .slice(0, 4)
    )
  );

  favorites$ = this.establishmentService.establishments$.pipe(
    map((establishments) =>
      establishments.filter((establishment) => !!establishment.isFavorite)
    ),
    tap((establishments) =>
      console.log('favorites.component Line 96:', establishments)
    )
  );

  breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.favorites`,
      routerLink: `/favorites`,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  readonly prefixTranslation = 'favorites.';
  showMapControl = false;

  constructor(private readonly establishmentService: EstablishmentService) {}
}
