<div class="pt-20">
    <div
            *ngIf="
          establishmentAndOfferSnapshot$ | async as establishmentAndOfferSnapshot;
          else loading
        "
            class="w-full px-4 md:px-28"
    >
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
            <ng-container *ngFor="let item of breadcrumb">
                <a
                        *tuiItem
                        [routerLink]="item.routerLink"
                        class="ariane text-primary-dark"
                        tuiLink
                >
                    {{ item.caption | transloco }}
                </a>
            </ng-container>
        </tui-breadcrumbs>

        <div class="mb-16">
            <h1 class="headline2 text-primary mb-2">
                {{ establishmentAndOfferSnapshot.offer?.name }}
            </h1>
            <a
                    [href]="createMapUrl(establishmentAndOfferSnapshot.establishment)"
                    icon="tuiIconMapPin"
                    iconAlign="left"
                    target="_blank"
                    tuiLink
            >
                {{ establishmentAndOfferSnapshot.establishment?.address }}
            </a>
        </div>

        <div class="flex flex-row gap-x-8">
            <div class="flex flex-col w-full lg:w-2/3">
                <div class="mb-16">
                    <img [src]="getOfferPhoto(establishmentAndOfferSnapshot)"
                         alt="" class="w-full h-auto max-h-screen/2 object-cover object-center rounded-lg">

                    <div class="flex mt-2 gap-x-5">
                        <div class="flex gap-x-3 px-5 py-4 bg-primary-light rounded-full">
                            <tui-svg [src]="'tuiIconClock'" class="h-5 w-5 text-primary-dark"></tui-svg>
                            <p class="text-primary-dark">
                                {{ prefixTranslation + 'duration' | transloco:{duration: establishmentAndOfferSnapshot.offer?.duration} }}
                            </p>
                        </div>

                        <div class="flex gap-x-3 px-5 py-4 bg-primary-light rounded-full">
                            <tui-svg [src]="'tuiIconUser'" class="h-5 w-5 text-primary-dark"></tui-svg>
                            <p class="text-primary-dark">
                                {{ prefixTranslation + 'people' | transloco:{
                                min: establishmentAndOfferSnapshot.offer?.minPeople,
                                max: establishmentAndOfferSnapshot.offer?.maxPeople
                            } }}
                            </p>
                        </div>

                        <div class="flex gap-x-3 px-5 py-4 bg-primary-light rounded-full">
                            <tui-svg [src]="'tuiIconGlobe'" class="h-5 w-5 text-primary-dark"></tui-svg>
                            <p [title]="prefixTranslation + 'language' | transloco:{language: getLanguage(establishmentAndOfferSnapshot.establishment?.languages)} "
                               class="text-primary-dark line-clamp-1">
                                {{ prefixTranslation + 'language' | transloco:{language: getLanguage(establishmentAndOfferSnapshot.establishment?.languages)  | slice:0:50} }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mb-16 flex gap-x-6">
                    <img [src]="getPhoto(establishmentAndOfferSnapshot)"
                         class="h-16 w-16 object-center object-cover rounded-full">

                    <div class="flex flex-col justify-center gap-y-3">
                        <p class="text-primary-dark text-sm">
                            {{ prefixTranslation + 'experience' | transloco | uppercase}}
                        </p>
                        <p class="text-primary-dark font-bold text-[24px]">
                            {{ establishmentAndOfferSnapshot.establishment?.name }}
                        </p>
                    </div>
                </div>

                <div class="mb-16">
                    <h2 class="headline3 mb-5 text-primary-dark">
                        {{ prefixTranslation + 'description' | transloco }}
                    </h2>
                    <p [innerHTML]="displayDescription(establishmentAndOfferSnapshot.offer?.description)"
                       class="text-primary-dark text-sm">
                    </p>
                </div>

                <div class="mb-16">
                    <rdw-establishment-map
                            *ngIf="establishmentAndOfferSnapshot.establishment as establishment"
                            [establishmentName]="establishment.name"
                            [localization]="establishment.localization"
                    ></rdw-establishment-map>
                </div>

                <div *ngIf="offersFromEstablishmentSnapshot$ | async as offers" class="mb-20">
                    <h2 class="headline3 mb-5 text-primary-dark">
                        {{ prefixTranslation + 'other-offers' | transloco }}
                    </h2>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-6">
                        <rdw-offer-card *ngFor="let offer of offers"
                                        [establishmentId]="establishmentAndOfferSnapshot.establishment!.id"
                                        [offer]="offer" class="flex-1"></rdw-offer-card>
                    </div>
                </div>
            </div>

            <div *ngIf="establishmentAndOfferSnapshot.offer as offer" class="relative w-1/3 hidden lg:block">
                <rdw-book-offer-content
                        (sendBookForm)="bookVisit(establishmentAndOfferSnapshot)"
                        [availabilityTimeSlots]="availabilityTimeSlots"
                        [bookForm]="bookForm"
                        [daysAvailable]="daysAvailable"
                        [isBooking]="isBooking" [languages]="getLanguageChoice(establishmentAndOfferSnapshot)"
                        [possiblePersons]="getNbPersonChoice(establishmentAndOfferSnapshot)">
                </rdw-book-offer-content>

                <ng-template
                    [(tuiDialog)]="open"
                    let-observer
                >
                    <rdw-book-offer-content
                            (sendBookForm)="bookVisit(establishmentAndOfferSnapshot)"
                            [availabilityTimeSlots]="availabilityTimeSlots"
                            [bookForm]="bookForm"
                            [daysAvailable]="daysAvailable"
                            [isBooking]="isBooking" [languages]="getLanguageChoice(establishmentAndOfferSnapshot)"
                            [possiblePersons]="getNbPersonChoice(establishmentAndOfferSnapshot)">
                    </rdw-book-offer-content>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<button (click)="openBookModal()" class="fixed bottom-28 right-8 lg:hidden" tuiButton>
    {{ prefixTranslation + 'book-button' | transloco }}
</button>

<ng-template #loading></ng-template>
