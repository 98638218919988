import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitInterface } from '../../../../shared/interfaces/visit.interface';
import { TuiTagModule } from '@taiga-ui/kit';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiButtonModule, TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';
import { Constant } from '../../../../shared/utils/constant';
import { RouterLink } from '@angular/router';
import { addHours, isBefore } from 'date-fns';

@Component({
  selector: 'rdw-visit-card',
  standalone: true,
  imports: [
    CommonModule,
    TuiTagModule,
    TranslocoModule,
    TuiSvgModule,
    TuiButtonModule,
    TuiLinkModule,
    RouterLink,
  ],
  templateUrl: './visit-card.component.html',
  styleUrls: ['./visit-card.component.scss'],
})
export class VisitCardComponent {
  @Input() visit!: VisitInterface;
  @Input() isFutureVisit = false;
  @Output() cancelButtonClick = new EventEmitter<VisitInterface>();

  readonly prefixTranslation = 'visits.visit-card.';
  protected readonly escape = escape;

  getTag() {
    if (this.isFutureVisit) {
      return `${this.prefixTranslation}next-tag`;
    }

    return `${this.prefixTranslation}passed-tag`;
  }

  getSrcImage() {
    if (this.visit.photo) {
      return this.visit.photo;
    }

    return Constant.noImgUrl;
  }

  cancelButton() {
    this.cancelButtonClick.emit(this.visit);
  }

  isBefore6Hours() {
    const before = addHours(this.visit.start.toDate(), -6);
    return isBefore(new Date(), before);
  }
}
