<div class="flex pt-20">
    <div class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
            <ng-container *ngFor="let item of breadcrumbItems">
                <a
                        *tuiItem
                        [href]="item.routerLink"
                        class="ariane text-primary-dark"
                        tuiLink
                >
                    {{ item.caption | transloco }}
                </a>
            </ng-container>
        </tui-breadcrumbs>

        <h1 class="headline1 text-primary mb-3">
            {{ prefixTranslation + 'title' | transloco }}
        </h1>

        <p class="text-sm italic">Dernière mise à jour: 13/02/2023</p>

        <h2>
            1. OBJET
        </h2>

        <p>
            La société Rendez Wine SAS (ci-après “Rendez Wine”) édite une plateforme de réservation en ligne d’activités
            d’oenotourisme (dégustation, visite, atelier oenologie, accord mets-vins…) accessible sur un site à
            l’adresse www.rendezwine.com ou sous forme d’application mobile (ci-après la “Solution”).
        </p>

        <p>
            L’utilisation (y compris l’accès) à la Solution emporte reconnaissance et acceptation, sans réserve ni
            condition, des présentes conditions générales d’utilisation (ci-après « CGU ») applicables notamment à
            l’ensemble des services de la Solution.
        </p>

        <p>
            Les présentes CGU ont essentiellement pour objet de définir les conditions et modalités de la mise à la
            disposition des services de Rendez Wine de recherche de domaines viticoles, de réservation en ligne
            d’activités d’œnotourisme (dégustation, visite, atelier œnologie, accord mets-vins ...) dans les domaines
            viticoles (ci-après dénommés les “Professionnels”).
        </p>

        <p>
            En cliquant sur le bouton “S’inscrire”, vous reconnaissez avoir pris connaissance et accepter l’intégralité
            des présentes conditions générales d’utilisation.
        </p>

        <h2>
            2. DESCRIPTION DE LA SOLUTION
        </h2>

        <h3>
            2.1 Réserver en ligne et en temps réel des activités (visite, dégustation, atelier œnologie, accord
            mets-vins ...) proposées par les Professionnels..
        </h3>

        <p>
            La Solution permet à l'Utilisateur, de rechercher un domaine viticole selon différents critères, et de
            réserver des activités auprès des Professionnels.

            La réservation peut être prise en ligne, par le biais de la Solution

            La disponibilité d’une activité est indiquée au moment de la demande de réservation effectuée par
            l’Utilisateur. Rendez Wine met à disposition des Professionnels tous les outils pour planifier et mettre à
            jour leurs disponibilités. De cette manière, Rendez Wine garantit de faire tout son possible pour que la
            disponibilité soit effective. Pour autant, Rendez Wine ne peut être tenu responsable de la fiabilité et de
            la bonne tenue à jour du planning de chaque Professionnel. Le Professionnel est seul responsable d’une
            éventuelle indisponibilité. L’Utilisateur accepte dans ce cadre d’être contacté par le Professionnel
            concerné afin d’effectuer une nouvelle réservation dans l’hypothèse où le Professionnel ne serait plus
            disponible au créneau demandé initialement par l’Utilisateur. A cet effet, ce dernier est informé et accepte
            que ses données à caractère personnel (nom, prénom, e-mail) soient communiquées au Professionnel concerné.
            Si vous souhaitez en savoir plus sur l’utilisation de vos données, consultez notre Politique de
            Confidentialité.

            Tout différend avec un Professionnel doit être traité directement par l’Utilisateur avec celui-ci. Le cas
            échéant, sans qu’il ne soit déduit aucune obligation à sa charge ni engagement ou garantie, Rendez Wine fera
            son possible pour permettre le règlement amiable du différend qui opposerait un Utilisateur à un
            Professionnel.
        </p>

        <h3>
            2.2 Service payant
        </h3>

        <p>
            La réservation d’une activité sur Rendez Wine est payante. En cas d’activité payante, celle-ci sera réglée
            sur le site internet ou sur La Solution, via notre prestataire de paiement Des tarifs pourront
            éventuellement être applicables à l’avenir, notamment en fonction de l'évolution des services proposés, de
            l'évolution du réseau, de la technique et/ou des contraintes légales. L'Utilisateur en sera alors dûment
            informé par la modification des présentes conditions générales ou par l'insertion dans la Solution de
            conditions particulières relatives aux services payants.
        </p>

        <h3>
            2.3 Bénéficier de promotions proposées par les Professionnels référencés sur la Solution
        </h3>

        <p>
            La Solution peut permettre à l'Utilisateur, si et seulement il réserve une activité par le biais de
            celle-ci, de bénéficier de promotions sur l’achat de vin chez les Professionnels référencés sur la Solution.
            Tous les Professionnels ne proposent pas de promotion. Les conditions de validité de ces promotions ne sont
            pas explicitées sur la Solution.
        </p>

        <h2>
            3. UTILISATION DE LA SOLUTION ET CREATION DE COMPTE
        </h2>

        <h3>
            3.1 Conditions d’utilisation de la Solution
        </h3>

        <p>
            L’utilisation de la Solution est strictement réservée aux personnes physiques âgées de 18 ans ou plus,
            capables de souscrire des contrats en droit français. Toute utilisation ou inscription sur le site d’une
            personne mineure est strictement interdite. Toutefois, une personne mineure peut accompagner une personne
            majeure dans le cadre d’une activité réservée par cette personne majeure. Est considéré comme utilisateur
            (ci-après « Utilisateur ») de la Solution toute personne qui accède à la Solution, la visite et/ou l’utilise
            dans l’une de ses fonctionnalités.
            Ainsi, en étant utilisateur de la Solution, vous déclarez et garantissez avoir 18 ans ou plus.
        </p>

        <h3>
            3.2 Création de compte
        </h3>

        <p>
            La création de compte est facultative et n’est pas requise dans le cadre de l’utilisation de la Solution.

            Néanmoins, l’Utilisateur a la faculté de se créer un compte personnel sur la Solution qui lui permet
            notamment, sans que cette liste ne soit considérée limitative, de réserver des activités sans avoir à
            ressaisir ses coordonnées, de suivre l’historique de ses réservations et avis, d’accéder facilement aux
            Professionnels produisant du vin à son goût, et de rajouter en favoris des Professionnels.
        </p>

        <p>
            Pour créer votre compte, vous pouvez :
            • Vous connectez à la Solution via numéro de téléphone, en saissisant également nom, prénom et email. En
            utilisant une telle fonctionnalité, vous comprenez que Rendez Wine récupérera votre identifiant, nom, prénom
            et adresse email afin de pré-remplir les réservations. Pour supprimer votre compte, vous pouvez nous faire
            part de cette demande par email à l’adresse contact@rendezwine.com. Si vous souhaitez en savoir plus sur
            l’utilisation de vos données dans le cadre de votre compte, consultez notre Politique de Confidentialité
        </p>

        <p>
            A l’occasion de la création de votre compte, et ce quelle que soit la méthode choisie pour ce faire, vous
            vous engagez à fournir des informations personnelles exactes et conformes à la réalité et à les mettre à
            jour, par l’intermédiaire de votre profil ou en en avertissant Rendez Wine, afin d’en garantir la pertinence
            et l’exactitude tout au long de votre relation contractuelle avec Rendez Wine.
        </p>

        <p>
            Vous vous engagez à ne pas créer ou utiliser, sous votre propre identité ou celle d’un tiers, d’autres
            comptes que celui initialement créé. Le cas échéant, Rendez Wine se réserve le droit de suspendre ou
            résilier un compte à tout moment.
        </p>

        <h3>
            3.3 Modalités d’utilisation de la Solution
        </h3>

        <p>
            L’Utilisateur reconnait expressément que toute réservation effectuée sur le Site est une réservation avec
            obligation de paiement.

            L’Utilisateur est informé que la réservation ne pourra être considéré comme effective avant le parfait
            encaissement par la Société des sommes dues par l’Utilisateur.

            La Société utilise la solution de paiement en ligne STRIPE.

            La réservation de l’Utilisateur est enregistrée et validée dès acceptation du paiement par le fournisseur de
            la solution de paiement en ligne

            Le compte bancaire de l’Utilisateur sera débité du montant correspondant uniquement lorsque (i) les données
            de la carte bancaire utilisée auront été vérifiées et (ii) le débit aura été accepté par la banque ayant
            émis la carte bancaire.

            L’impossibilité de débiter les sommes dues entraînera la nullité immédiate de la vente.

            La carte bancaire peut notamment être refusée si elle est arrivée à expiration, si elle a atteint le montant
            maximal de dépense auquel l’Utilisateur a droit ou si les données saisies sont incorrectes.

            Le cas échéant, la réservation validée par l’Utilisateur ne sera considérée comme effective que lorsque le
            centre de paiement bancaire sécurisé aura donné son accord sur la transaction. Dans le cadre des procédures
            de contrôle, la Société pourra avoir à demander à l’Utilisateur toutes les pièces nécessaires à la
            finalisation de sa réservation. Ces pièces ne seront pas utilisées à d’autres fins que celles-ci.
        </p>

        <h2>
            4. AVIS DES UTILISATEURS
        </h2>

        <h3>
            4.1 Conditions de publication des avis
        </h3>

        <p>
            Pour publier un avis, l'Utilisateur doit être majeur, avoir visité un Professionnel par l'intermédiaire de
            la Solution Rendez Wine et avoir honoré sa réservation chez ce Professionnel.
        </p>

        <p>
            Pour publier un avis, l'Utilisateur doit être majeur, avoir visité un Professionnel par l'intermédiaire de
            la Solution Rendez Wine et avoir honoré sa réservation chez ce Professionnel.
        </p>

        <p>
            L'avis doit concerner exclusivement le Professionnel chez qui l’Utilisateur s'est rendu. Tout avis portant
            sur un autre Professionnel sera supprimé par Rendez Wine.
        </p>

        <h2>
            5. POLITIQUE D’ANNULATION
        </h2>

        <p>
            Toute activité réservée via la Solution peut être annulée sans frais par l’Utilisateur dans un délai de 6h
            avant la réservation. Pour cela, il suffit de cliquer sur le bouton d’annulation accessible sur la
            réservation dans la Solution.
        </p>

        <h2>
            6. OBLIGATIONS DE L’UTILISATEUR
        </h2>

        <h3>
            6.1 Communication d’informations exactes, complètes et mises à jour
        </h3>

        <p>
            L’Utilisateur s’engage à fournir des informations exactes et complètes et de les maintenir à jour, tout
            particulièrement les données nécessaires à son identification, en vue de l'ouverture d'un compte personnel,
            mais également les données nécessaires à la validation d’une réservation.
        </p>

        <h3>
            6.2 Respect des droits de propriété intellectuelle attachés à la Solution
        </h3>

        <p>
            La Solution, et chacun des éléments qui la composent, notamment mais non limitativement, les textes, images,
            photographies, illustrations, documents, etc., sont, sauf mentions particulières, la propriété exclusive de
            Rendez Wine.

            En conséquence, en application des dispositions du code de la propriété intellectuelle, des dispositions
            législatives et réglementaires de tous pays et des conventions internationales, toute reproduction ou
            représentation, intégrale ou partielle, de la Solution, ou de l'un quelconques des éléments qui le composent
            est interdite de même que leur altération. A ce titre, il est notamment interdit à tout Utilisateur
            d’adapter, arranger, modifier, corriger, associer, traduire en toutes langues ou tous langages, mettre sur
            le marché à titre gratuit ou onéreux, commercialiser, tout ou partie de la Solution ou de quelconque des
            éléments qui la composent, quels qu’en soient le moyen et le support.
        </p>

        <h3>
            6.3 Cession de droits de propriété intellectuelle sur les contenus publiés
        </h3>

        <p>
            L’Utilisateur cède à Rendez Wine l’ensemble des droits de propriété intellectuelle éventuellement attachés
            aux contenus qu’il publie, de manière expresse à titre gracieux, sans aucune autre contrepartie que celle
            consistant dans le droit de bénéficier des services de la Solution, et tout particulièrement les droits
            patrimoniaux sur les contenus enregistrés et/ou mis en ligne par lui sur la Solution, en ce compris (sans
            que ce soit limitatif) les droits de représentation, reproduction, traduction, adaptation, modification,
            exploitation, utilisation, que ce soit de manière partielle ou totale, sous quelque forme que ce soit, sur
            tout support actuel ou à venir, pour toute la durée légale du droit d’auteur, en France et partout dans le
            monde, à des fins commerciales ou non.
        </p>

        <h2>
            7. LIMITATIONS DE RESPONSABILITE
        </h2>

        <h3>
            7.1 Fonctionnement du réseau Internet
        </h3>

        <p>
            Compte tenu des spécificités du réseau Internet, Rendez Wine n'offre aucune garantie de continuité du
            service, n'étant tenue à cet égard qu’à une obligation de moyens. La responsabilité de Rendez Wine ne peut
            pas être engagée en cas de dommages liés à l'impossibilité temporaire d'accéder à l'un des services proposés
            par la Solution.
        </p>

        <h3>
            7.2 Informations liées aux Professionnels
        </h3>

        <p>
            Il appartient à l'Utilisateur de faire toutes vérifications qui semblent nécessaires ou opportunes avant de
            procéder à une quelconque réservation auprès des Professionnels présents sur la Solution.

            Rendez Wine ne garantit aucunement et de quelque façon que ce soit les produits, services et/ou pratiques
            commerciales des Professionnels présents sur sa Solution. Rendez Wine ne garantit pas à l'Utilisateur qu'il
            soit satisfait des produits, services et/ou pratiques commerciales qu'il a obtenus suite à une réservation
            auprès de l’un des Professionnels par le biais de la Solution.

            Lorsque Rendez Wine fournit sur sa Solution des détails concernant des prix ou une gamme de prix concernant
            un Professionnel partenaire, ces informations ne sont fournies qu'à titre indicatif et par souci de
            commodité. En aucun cas Rendez Wine ne garantit l'exactitude de telles informations.
        </p>

        <h3>
            7.3 Accident chez le Professionnel
        </h3>

        <p>
            Rendez Wine ne peut être tenu responsable des incidents et/ou accidents qui pourraient survenir lorsque
            l’Utilisateur se trouve chez le Professionnel. Seule la responsabilité de ce dernier est en jeu.
        </p>

        <h3>
            7.4 Modification de la Solution
        </h3>

        <p>
            Toutes les informations contenues sur la Solution sont susceptibles d'être modifiées à tout moment, compte
            tenu de l'interactivité de la Solution, sans que cela puisse engager la responsabilité de Rendez Wine.
        </p>

        <h3>
            7.5 Utilisation de la Solution
        </h3>

        <p>
            Rendez Wine fait ses meilleurs efforts, mais sans aucune garantie et sans qu’aucune obligation de résultat
            ne lui soit attribuée, pour que les informations présentées soient détaillées, complètes, vérifiées ou
            exactes. Ainsi, les documents, informations, fiches descriptives, et, en général, tout contenu présent sur
            la Solution sont fournis en "l'état", sans aucune garantie expresse ou tacite de quelle que sorte que ce
            soit.

            L'Utilisateur reconnaît expressément que les photos présentes sur la Solution ne sont pas contractuelles.

            De façon générale, l'Utilisateur accepte et reconnaît que l’effectivité de la réservation n’est pas garantie
            par Rendez Wine. La disponibilité est vérifiée en temps réel informatiquement et l’activité est réellement
            réservée informatiquement. Toutefois, Rendez Wine ne pouvant pas matériellement vérifier l'exactitude des
            renseignements recueillis en temps réel auprès des Professionnels, sa responsabilité ne peut en aucun cas
            être engagée si l'Utilisateur ne parvient pas effectivement à bénéficier de l’activité en question, à
            l’heure et à la date prévue. Néanmoins, Rendez Wine s’engage à faire ses meilleurs efforts pour proposer à
            l’Utilisateur une nouvelle activité, dans l’hypothèse où l’Utilisateur justifie avoir régulièrement
            sollicité une réservation qui ne lui a pas été accordée par le Professionnel.
        </p>

        <h2>
            8. DUREE - RESILIATION
        </h2>

        <p>
            L’Utilisateur est autorisé à accéder à la Solution et utiliser ses Services pour une durée indéterminée.

            La clôture du compte personnel de l’Utilisateur à son initiative vaut résiliation des présentes CGU, qui
            demeureront néanmoins applicables pour toute réservation effectuée sur la Solution sans pour autant avoir un
            compte personnel actif. Pour fermer un compte personnel, il vous suffit de nous faire part de cette demande
            par email à l’adresse contact@rendezwine.com.

            En cas de manquement par l’Utilisateur à l’une des dispositions des présentes CGU, Rendez Wine peut
            résilier, de plein droit, à tout moment et sans préavis, les CGU, aux adresses électroniques par
            l’Utilisateur sur la Solution, sans qu'il soit nécessaire d'effectuer aucune autre formalité judiciaire ou
            extrajudiciaire.

            La résiliation des CGU pour quelque cause que ce soit peut entraîner l’arrêt automatique et définitif de
            l’accès à la Solution et à ses services.
        </p>

        <h3>
            8.1 Suspension ou arrêt définition du ou des service(s)
        </h3>

        <p>
            En cas d'inexécution ou de non-respect par l'Utilisateur de l'une des obligations et stipulations prévues
            par les présentes CGU, Rendez Wine pourra, selon les circonstances, modifier, suspendre, limiter ou
            supprimer l'accès au(x) service(s), sans que celui-ci ne puisse réclamer aucune indemnité quelconque. Rendez
            Wine sera en droit de réclamer des indemnités destinées à compenser le préjudice subi.
        </p>

        <h3>
            8.2 Prévention contre l’alcoolisme
        </h3>

        <p>
            Rendez Wine n’est en aucun cas responsable du respect ou non par les Partenaires des dispositions légales ou
            réglementaires de lutte contre l’alcoolisme et notamment de la loi n°91- 32 du 10 janvier 1991 relative à la
            lutte contre le tabagisme et l'alcoolisme.

            Rendez Wine rappelle néanmoins que l’abus d’alcool est dangereux pour la santé et est à consommer avec
            modération.

            Rendez Wine rappelle également que la consommation d’alcool est interdite aux mineurs et qu’il est interdit
            de recevoir dans les débits de boissons des mineurs de moins de seize ans qui ne sont pas accompagnés de
            leur père, mère, tuteur ou toute autre personne de plus de dix- huit ans en ayant la charge ou la
            surveillance.
        </p>

        <h2>
            9. DONNEES PERSONNELLES
        </h2>

        <p>
            Dans le cadre de l’utilisation de la Solution par l’Utilisateur, Rendez Wine est amené à collecter et
            traiter certaines de vos données personnelles. En utilisant la Solution ou en vous créant un compte
            personnel, vous reconnaissez et acceptez le traitement de vos données personnelles par Rendez Wine
            conformément à la loi applicable et aux stipulations de la Politique de Confidentialité.
        </p>

        <h2>
            10. MODIFICATION DES CGU
        </h2>

        <p>
            Rendez Wine se réserve ainsi le droit de modifier les CGU à tout moment. En cas de modification, la nouvelle
            version des CGU se substituera à la version précédente, elle sera diffusée et applicable de plein droit et
            avec effet immédiat aux Utilisateurs, sans que cela n’induise le moindre préjudice.
        </p>

        <h2>
            11. DROIT APPLICABLE - LITIGE
        </h2>

        <h3>
            11.1 Loi applicable et compétence juridictionnelle
        </h3>

        <p>
            Les personnes qui accèdent à la Solution sont soumises à la législation et à la réglementation en vigueur en
            France, à l’exclusion de toute autre. Les CGU sont régies par le droit français. Toutes difficultés
            d’interprétation, d’exécution et tous différends relatifs aux CGU et plus généralement à l’accès à la
            Solution et l’utilisation de ses services seront de la compétence exclusive des tribunaux compétents de
            Bobigny et ce y compris pour les procédures conservatoires, les procédures d’urgence, en cas de référé,
            d’appel en garantie, de requête ou de pluralité de défendeurs.

            Vous pouvez également présenter, le cas échéant, vos réclamations relatives à notre Solution ou à nos
            services, sur la plateforme de résolution des litiges mise en ligne par la Commission Européenne accessible
            ici. La Commission Européenne se chargera de transmettre votre réclamation aux médiateurs nationaux
            compétents. Conformément aux règles applicables à la médiation, vous êtes tenus, avant toute demande de
            médiation, d’avoir fait préalablement part par écrit à Rendez Wine de tout litige afin d’obtenir une
            solution amiable. Vous pouvez ainsi nous faire part de votre demande par email à l’adresse
            contact@rendezwine.com

            Conformément aux articles du code de la consommation L.611-1 et suivant, il est prévu que pour tout litige
            de nature contractuelle n’ayant pu être résolu dans le cadre d’une réclamation préalablement introduite
            auprès de notre service clients, vous pouvez, en votre qualité de consommateur, recourir gratuitement à la
            médiation en contactant l’Association Nationale des Médiateurs (ANM) soit par courrier en écrivant au 62,
            rue Tiquetonne 75002 PARIS soit par e-mail en remplissant le formulaire de saisine en ligne à l’adresse
            suivante: www.anm-conso.com

            Le site internet suivant www.economie.gouv.fr/mediation-conso comporte également toutes informations utiles
            en cas de litige transfrontalier.
        </p>

        <h3>
            11.2 Traduction - Nullité partielle - Dissociation - Titres
        </h3>

        <p>
            En cas de rédaction des présentes CGU en plusieurs langues ou de traduction, seule la version française fera
            foi. Dans l'hypothèse où une disposition des présentes CGU serait nulle, illégale, inopposable ou
            inapplicable d'une manière quelconque, la validité, la légalité ou l'application des autres dispositions des
            présentes CGU n'en seraient aucunement affectées ou altérées, les autres stipulations des CGU demeurant en
            vigueur et conservant leur plein et entier effet.
        </p>

        <h3>
            11.3 Absence de renonciation
        </h3>

        <p>
            Sauf stipulation contraire prévue éventuellement dans les présentes CGU, aucune tolérance, inaction,
            abstention ou omission, aucun retard de Rendez Wine pour se prévaloir de l'un quelconque de ses droits
            conformément aux termes des présentes, ne portera atteinte audit droit, ni ne saurait impliquer une
            renonciation pour l'avenir à se prévaloir d'un tel droit. Au contraire, ledit droit demeurera pleinement en
            vigueur.
        </p>

        <h3>
            11.4 Convention de preuve
        </h3>

        <p>
            Compte tenu de la nature de leurs échanges et en particulier de la dématérialisation de leur relation,
            Rendez Wine et l’Utilisateur acceptent que l'ensemble des enregistrements informatiques dans le système
            d'information de Rendez Wine aura, en cas de litige, valeur de preuve.
        </p>

        <h2>
            12. MENTIONS LEGALES
        </h2>

        <p>
            La Solution est éditée par la société Rendez Wine SAS, societe par actions simplifiee immatriculée par
            tribunal de commerce de Dijon, sous le numéro 910 328 111, et dont le siège social est au 33 RUE DES
            ÉCHALIERS 21200 BEAUNE (France) (email : contact@rendezwine.com), représentée par son Président Monsieur
            Corentin STEPHAN.
        </p>

        <p class="mb-10">
            Pour toute question, vous pouvez contacter Rendez Wine SAS en utilisant le formulaire de contact.
        </p>
    </div>
</div>
