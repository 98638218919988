import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { LoginModalComponent } from '../../components/navbar/components/login-modal/login-modal.component';
import { TuiDialogService } from '@taiga-ui/core';
import { SuccessBookingModalComponent } from '../../components/modal/success-booking-modal/success-booking-modal.component';
import { ConfirmCancelBookingComponent } from '../../components/modal/confirm-cancel-booking/confirm-cancel-booking.component';
import { VisitInterface } from '../../interfaces/visit.interface';
import { Auth, authState } from '@angular/fire/auth';
import { EMPTY, of, switchMap } from 'rxjs';
import { RegistrationModalComponent } from '../../components/modal/registration-modal/registration-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly loginDialog = this.dialogService.open(
    new PolymorpheusComponent(LoginModalComponent, this.injector),
    { closeable: false }
  );

  private readonly successBookingDialog = this.dialogService.open(
    new PolymorpheusComponent(SuccessBookingModalComponent, this.injector),
    { closeable: false }
  );

  private readonly registrationDialog = this.dialogService.open(
    new PolymorpheusComponent(RegistrationModalComponent, this.injector),
    { closeable: false, dismissible: false }
  );

  constructor(
    private readonly dialogService: TuiDialogService,
    private readonly injector: Injector,
    private readonly auth: Auth
  ) {}

  showLoginDialog() {
    this.loginDialog.subscribe();
  }

  showSuccessBookingModal(completeAction?: () => void) {
    this.successBookingDialog.subscribe({
      complete: () => {
        !!completeAction && completeAction();
      },
    });
  }

  showRegistrationModal(completeAction?: () => void) {
    this.registrationDialog.subscribe({
      complete: () => {
        !!completeAction && completeAction();
      },
    });
  }

  showCancelBookingModal(visit: VisitInterface) {
    authState(this.auth)
      .pipe(
        switchMap((user) => {
          if (!user) {
            return of(EMPTY);
          }

          return this.dialogService.open(
            new PolymorpheusComponent(
              ConfirmCancelBookingComponent,
              this.injector
            ),
            {
              closeable: false,
              data: {
                visit,
                userId: user.uid,
              },
            }
          );
        })
      )
      .subscribe();
  }
}
