import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { EstablishmentService } from '../../shared/services/establishment/establishment.service';
import { AllEstablishmentComponent } from './components/all-establishment/all-establishment.component';
import { Meta } from '@angular/platform-browser';
import { VineyardLocationComponent } from './components/vineyard-location/vineyard-location.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';

@Component({
  selector: 'rdw-home',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    AllEstablishmentComponent,
    VineyardLocationComponent,
    SearchBarComponent,
  ],
  template: `
    <div
      class="aspect-square md:aspect-[21/9] bg-cover bg-center flex flex-col mb-8"
    >
      <div
        style="background-image: url('assets/images/bg-home.png')"
        class="h-full w-full bg-cover bg-center"
      >
        <div
          class="flex flex-col justify-center items-center bg-black/30 h-full px-2"
        >
          <h1 class="headline1 text-white text-center mb-8">
            {{ prefixTranslation + 'header.title' | transloco }}
          </h1>
          <p class="body1 text-white text-center">
            {{ prefixTranslation + 'header.description' | transloco }}
          </p>
        </div>
      </div>
    </div>
    <rdw-search-bar></rdw-search-bar>
    <rdw-vineyard-location></rdw-vineyard-location>
    <ng-container
      *ngIf="establishmentService.establishments$ | async as establishments"
    >
      <rdw-all-establishment
        [mapOptionEnabled]="true"
        [establishments]="establishments || []"
      ></rdw-all-establishment>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  prefixTranslation = 'home.';

  constructor(
    public readonly establishmentService: EstablishmentService,
    private readonly meta: Meta
  ) {}

  ngOnInit() {
    this.meta.addTag({
      name: 'description',
      content:
        'Rentrer en relation avec les viticulteurs bourguignons facilement et rapidement afin de vivre des expériences inédites et originales au cœur d’un des plus beaux vignobles au monde.',
    });
  }
}
