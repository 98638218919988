import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { OfferInterface } from '../../../../shared/interfaces/offer.interface';
import { OfferCardComponent } from '../../../../shared/components/offer-card/offer-card.component';
import { off } from '@angular/fire/database';

@Component({
  selector: 'rdw-establishment-offers',
  standalone: true,
  imports: [CommonModule, TranslocoModule, OfferCardComponent],
  templateUrl: './establishment-offers.component.html',
  styleUrls: ['./establishment-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstablishmentOffersComponent {
  readonly prefixTranslation = 'establishments.establishment-offers.';

  @Input() offers: OfferInterface[] = [];
  @Input() establishmentId!: string;
  protected readonly off = off;
}
