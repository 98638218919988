import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';
import { Constant } from '../../../../shared/utils/constant';

@Component({
  selector: 'rdw-vineyard-location',
  standalone: true,
  imports: [CommonModule, TranslocoModule, RouterLink],
  templateUrl: './vineyard-location.component.html',
  styleUrls: ['./vineyard-location.component.scss'],
})
export class VineyardLocationComponent {
  prefixTranslation = 'home.vineyard-location.';
  readonly vineyardLocationList = Constant.vineyardDisplayFilter;
}
