import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly translocoService: TranslocoService,
    private readonly alertService: TuiAlertService
  ) {}

  showSuccess(key: string, params: { [p: string]: string }) {
    this.alertService
      .open(this.translocoService.translate(`general.success.${key}`, params), {
        status: TuiNotification.Success,
      })
      .subscribe();
  }

  showFirebaseError(code: string, params: { [p: string]: string }) {
    this.alertService
      .open(
        this.translocoService.translate(`general.firebase.${code}`, params),
        {
          status: TuiNotification.Error,
        }
      )
      .subscribe();
  }

  showError(errorKey: string, params: { [p: string]: string }) {
    this.alertService
      .open(
        this.translocoService.translate(`general.error.${errorKey}`, params),
        {
          status: TuiNotification.Error,
        }
      )
      .subscribe();
  }
}
