<div class="flex pt-20">
    <div *ngIf="visitService.$visits | async as clientVisit" class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
            <ng-container *ngFor="let item of breadcrumbItems">
                <a
                        *tuiItem
                        [href]="item.routerLink"
                        class="ariane text-primary-dark"
                        tuiLink
                >
                    {{ item.caption | transloco }}
                </a>
            </ng-container>
        </tui-breadcrumbs>
        <h1 class="headline1 text-primary mb-10">
            {{ prefixTranslation + 'next-title' | transloco }}
        </h1>
        <ng-container *ngIf="clientVisit.futureVisits.length; else emptyVisits">
            <div
                    class="flex flex-col gap-y-10"
            >
                <rdw-visit-card (cancelButtonClick)="displayCancelModal(visit)"
                                *ngFor="let visit of clientVisit.futureVisits"
                                [isFutureVisit]="true"
                                [visit]="visit"></rdw-visit-card>
            </div>
        </ng-container>
        <ng-template #emptyVisits>
            <p class="body1 text-primary-dark">
                {{ prefixTranslation + 'empty-future-visits' | transloco }}
            </p>
            <a
                    appearance="whiteblock-active"
                    class="mt-8 mb-24"
                    href="/"
                    size="l"
                    tuiButton
            >
                {{ prefixTranslation + 'empty-button' | transloco }}
            </a>
        </ng-template>

        <h1 class="headline1 text-primary mt-24 mb-10">
            {{ prefixTranslation + 'passed-title' | transloco }}
        </h1>
        <ng-container *ngIf="clientVisit.passedVisits.length; else emptyPassedVisits">
            <div
                    class="flex flex-col gap-y-10"
            >
                <rdw-visit-card *ngFor="let visit of clientVisit.passedVisits" [isFutureVisit]="false"
                                [visit]="visit"></rdw-visit-card>
            </div>
        </ng-container>
        <ng-template #emptyPassedVisits>
            <p class="body1 text-primary-dark">
                {{ prefixTranslation + 'empty-passed-visits' | transloco }}
            </p>
            <a
                    appearance="whiteblock-active"
                    class="mt-8 mb-24"
                    href="/"
                    size="l"
                    tuiButton
            >
                {{ prefixTranslation + 'empty-button' | transloco }}
            </a>
        </ng-template>

        <div class="mb-10"></div>
    </div>
</div>
