import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiTabsModule } from '@taiga-ui/kit';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'rdw-establishment-tabs',
  standalone: true,
  imports: [CommonModule, TuiTabsModule, TranslocoModule, RouterLink],
  template: `
    <tui-tabs
      [(activeItemIndex)]="activeItemIndex"
      class="gap-x-16 border-b border-solid border-gray-300"
    >
      <a
        [routerLink]="['/establishments', establishmentId]"
        fragment="establishment-description"
        routerLinkActive
        tuiTab
      >
        <p class="headline4">
          {{ prefixTranslation + 'our-story' | transloco }}
        </p>
      </a>
      <a
        [routerLink]="['/establishments', establishmentId]"
        fragment="establishment-offers"
        routerLinkActive
        tuiTab
      >
        <p class="headline4">
          {{ prefixTranslation + 'our-offers' | transloco }}
        </p>
      </a>
      <a
        [routerLink]="['/establishments', establishmentId]"
        fragment="establishment-location"
        routerLinkActive
        tuiTab
      >
        <p class="headline4">
          {{ prefixTranslation + 'localization' | transloco }}
        </p>
      </a>
    </tui-tabs>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstablishmentTabsComponent {
  @Input() establishmentId!: string;

  activeItemIndex = 0;
  readonly prefixTranslation = 'establishments.establishment-tabs.';
}
