<div class="flex pt-20">
    <div class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
            <ng-container *ngFor="let item of breadcrumbItems">
                <a
                        *tuiItem
                        [routerLink]="item.routerLink"
                        class="ariane text-primary-dark"
                        tuiLink
                >
                    {{ item.caption | transloco }}
                </a>
            </ng-container>
        </tui-breadcrumbs>

        <h1 class="headline1 text-primary mb-3">
            {{ prefixTranslation + 'title' | transloco }}
        </h1>

        <p class="text-sm italic">Dernière mise à jour: 13/02/2023</p>
        <p>
            Veuillez lire attentivement les présentes Conditions de Service (ci-après, les "Conditions de Service")
            étant
            donné qu'elles contiennent des informations importantes concernant vos droits, recours et obligations
            juridiques
            et qu’elles régissent les relations commerciales avec la Société.
        </p>
        <p>
            En vous vous inscrivant sur le Site, vous acceptez de respecter les présentes Conditions de Service et d'y
            être
            lié.
        </p>
        <p>
            Les présentes Conditions de Service constituent un accord légalement contraignant (ci-après, l’« Accord »)
            entre
            vous et la Société (comme définie à l’Article I) régissant les Services de Paiement (définis ci-dessous)
            effectués
            au travers de la Plate-forme Rendez Wine ou en lien avec celle-ci.
        </p>

        <h2>
            Article I. Définitions
        </h2>

        <p>
            Les termes utilisés avec une majuscule initiale et non autrement définis dans l’un des articles du présent
            Contrat
            ont le sens qui leur est donné ci-après, sans qu’il y ait lieu de distinguer selon qu’ils sont utilisés au
            singulier, au pluriel, à l’infinitif ou conjugué.
        </p>

        <!--TODO: tableau-->

        <h2>
            Article II. Présentation du Site
        </h2>

        <p>
            En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique,
            il est
            précisé aux Utilisateurs du Site l’identité des différents intervenants dans le cadre de sa réalisation et
            de son
            suivi :
        </p>

        <ul>
            <li>
                Propriétaire : Rendez Wine, Société par actions simplifiée au capital de 1 500 €, immatriculée sous le
                numéro
                910 328 111 au R.C.S. de Dijon et domiciliée au 33 rue des échaliers, 21200 Beaune (ci-après, la «
                Société ») ,
            </li>

            <li>
                Responsable publication : la Société,
            </li>

            <li>
                Webmaster : la Société,
            </li>

            <li>
                Hébergeur : la Société.
            </li>
        </ul>

        <h2>
            Article III. Étendue des Services de Rendez Wine
        </h2>

        <p>
            La Plateforme Rendez Wine est une place de marché en ligne qui permet à des professionnels de l’œnotourisme
            et
            domaines viticoles (ci-après, les « Hôtes) de publier des informations sur des activités touristiques qu’ils
            proposent et de les rendre disponibles à la réservation. Chaque Activité proposée à la réservation par un
            Hôte
            fera l’objet d’une page dédiée sur le Site (ci-après « Annonce »), dont la description, les images ainsi que
            d’autres informations pratiques seront fournies par l’Hôte. Ces activités peuvent prendre la forme, de
            manière non
            exhaustive, de visite, de dégustation, de parcours de découverte, de leçon, ainsi que d’autres activités en
            lien
            avec le tourisme et le vin.
        </p>

        <p>
            Les Utilisateurs pourront découvrir sur la Plateforme l’ensemble des Activités proposées par les Hôtes et
            effectuer une Réservation pour l’un ou plusieurs d’entre-elles.
        </p>

        <p>
            En complément de leur Activité, les Utilisateurs pourront demander à bénéficier d’Options que l’Hôte qui
            animent
            l’Activité réservée par l’Utilisateur leur aura préalablement proposées.
        </p>

        <p>
            Les Hôtes sont seuls responsables de leurs Activités et des Options qu’ils proposent. Lorsque des
            Utilisateurs
            font ou acceptent une Réservation, un contrat les lie aux Hôtes directement. Rendez Wine n’est pas et ne
            devient
            pas partie à un quelconque contrat passé entre des Utilisateurs et des Hôtes, n’est pas impliquée dans la
            relation
            contractuelle entre des Utilisateurs et des Hôtes et n’est ni courtier immobilier ni assureur. Rendez Wine
            n’agit
            pas en qualité de mandataire d’un quelconque Utilisateur ou d’un quelconque Hôte.
        </p>

        <p>
            Si Rendez Wine peut faciliter la résolution des litiges, Rendez Wine ne contrôle ni ne garantit l’existence,
            la
            qualité, la sécurité, l’adéquation ou la légalité des Activités proposées par un Hôtes, la véracité ou
            l’exactitude des descriptions des Activités, des Notations, des Commentaires ou autre tout autre Contenu
            d’Hôte
            (tel que défini ci-dessous), ou les prestations ou le comportement de tout Hôte ou tiers.
        </p>

        <p>
            Si vous choisissez d’utiliser la Plateforme Rendez Wine en tant qu’Hôte, votre qualité à l’égard de Rendez
            Wine se
            limite à celle d’entrepreneur tiers indépendant, et non d’employé, mandataire, membre d’une coentreprise ou
            associé de Rendez Wine pour quelque motif que ce soit, vous agissez exclusivement pour votre propre compte
            et à
            votre seul profit, et non pour le compte ou au profit de Rendez Wine. Rendez Wine ne vous dirige pas ou ne
            vous
            contrôle pas, et ne saurait être considérée comme vous dirigeant ou vous contrôlant, que ce soit de manière
            générale ou en application des présentes Conditions de Service. Vous reconnaissez que vous avez le plein
            pouvoir
            discrétionnaire d’annoncer vos Activités ou autrement de vous engager dans d’autres activités commerciales
            ou
            d’autres emplois.
        </p>

        <p>
            Afin de promouvoir la Plateforme Rendez Wine et d’accroître la visibilité des Activités auprès de Visiteurs
            potentiels, les Annonces et autres formes de Contenu d’Hôtes peuvent être publiées sur d’autres sites
            Internet,
            dans des applications et des e-mails, ainsi que dans des publicités en ligne et hors ligne conformément à
            l’article « Contenu » des présentes Conditions de Service. Afin d‘aider les Hôtes qui parlent différentes
            langues,
            Rendez Wine met à disposition des outils automatiques pour permettre aux Hôtes de traduire les Activités et
            autres
            formes de Contenu d’Hôtes, entièrement ou partiellement, en d’autres langues. Les Hôtes sont libres
            d'utiliser ces
            outils à leur entière discrétion. Rendez Wine ne peut garantir l’exactitude ou la qualité de ces traductions
            et il
            appartient aux Hôtes de vérifier l’exactitude de ces traductions.
        </p>

        <p>
            La Plateforme Rendez Wine peut contenir des liens vers des sites Internet ou des ressources tiers (ci-après,
            les «
            Services Tiers »). Ces Services Tiers peuvent être soumis à des conditions de service et à des pratiques en
            matière de protection des données à caractère personnel différentes. Rendez Wine ne saurait être tenue
            responsable
            de la disponibilité ou de l’exactitude de ces Services Tiers, ou du contenu, des produits ou services
            disponibles
            auprès de ces Services Tiers. Les liens vers ces Services tiers ne sauraient être interprétés comme une
            approbation par Rendez Wine de ces Services Tiers.
        </p>

        <p>
            Rendez Wine n'est pas responsable en cas de pannes ou coupures d'Internet ou des infrastructures de
            télécommunications qui ne relèvent pas de son contrôle et qui peuvent conduire à des interruptions de
            l'accessibilité de la plateforme Rendez Wine. Rendez Wine peut, de façon temporaire et en prenant en compte
            les
            intérêts légitimes des Hôtes (par exemple par une notification préalable), limiter la disponibilité de la
            Plateforme Rendez Wine ou de certaines zones ou fonctionnalités de la Plateforme Rendez Wine si cela est
            nécessaire en raison de capacités limitées, pour préserver la sécurité ou l’intégrité de nos serveurs, ou
            pour
            effectuer des opérations de maintenance afin d’assurer le bon fonctionnement ou d’améliorer le
            fonctionnement de
            la Plateforme Rendez Wine. Cette clause s'applique sans préjudice de l’Article « Responsabilité » des
            présentes
            Conditions de Service. Rendez Wine peut améliorer ou modifier la Plateforme Rendez Wine et ajouter de
            nouveaux
            Services à tout moment. Rendez Wine informera les Hôtes de toute modification effectuée sur la Plateforme
            Rendez
            Wine, à moins que ces modifications ne soient mineures ou qu'elles n'aient pas d'effet matériel sur les
            obligations contractuelles des parties.
        </p>

        <h2>
            Article IV. Éligibilité, Utilisation de la Plateforme Rendez Wine, Vérification des Hôtes
        </h2>

        <p>
            Vous devez avoir 18 ans minimum et avoir la capacité de contracter pour accéder à la Plateforme Rendez Wine,
            l’utiliser ou créer un Compte Rendez Wine. En accédant à la Plateforme Rendez Wine ou en l’utilisant, vous
            certifiez avoir au moins 18 ans et avoir la capacité et le pouvoir de contracter.
        </p>

        <p>
            Rendez Wine peut soumettre l’accès et l’utilisation de la Plateforme Rendez Wine ou à certaines zones ou
            fonctionnalités de la Plateforme Rendez Wine, à certaines conditions ou exigences, comme la soumission à une
            procédure de vérification, la satisfaction de certains critères de qualité et d’éligibilité, l’atteinte de
            certains seuils de Notations ou de Commentaires, ou à un historique de réservations et d’annulations d’un
            Hôte.
        </p>

        <p>
            La vérification des utilisateurs sur Internet est difficile, et nous déclinons toute responsabilité en ce
            qui
            concerne la confirmation de l’identité d’un quelconque Hôte, ou Utilisateur. Nonobstant ce qui précède, par
            souci
            de transparence et aux fins de la prévention des fraudes, sous réserve des lois applicables, nous pouvons,
            sans y
            être tenus, demander aux Hôtes de nous fournir une pièce d’identité officielle ou autres informations, ou de
            se
            soumettre à d’autres contrôles destinés à vérifier l’identité et les antécédents des Hôtes, consulter des
            bases de
            données tierces ou d’autres sources d’information pour vérifier si des Hôtes y figurent, et demander des
            rapports
            à des prestataires de services, et si nous avons suffisamment d’informations pour identifier un Hôtes,
            obtenir des
            extraits de fichiers d’infractions pénales ou sexuelles (ou similaires) auprès des autorités locales (si
            disponibles).
        </p>

        <p>
            L’accès à certaines zones et fonctionnalités de la Plateforme Rendez Wine et leur utilisation peuvent être
            soumis
            à des politiques, normes ou règles distinctes, ou peuvent être conditionnés à votre acceptation de
            conditions
            supplémentaires avant que vous ne puissiez accéder aux zones ou fonctionnalités concernées de la Plateforme
            Rendez
            Wine. En cas de conflit entre les présentes Conditions de Service et d’autres conditions applicables à une
            zone ou
            fonctionnalité spécifique de la Plateforme Rendez Wine, ces dernières l’emporteront en ce qui concerne votre
            accès
            à cette zone ou fonctionnalité et votre utilisation de cette zone ou fonctionnalité, sauf mention contraire
            dans
            ces dernières.
        </p>

        <h2>
            Article V. Modification des présentes Conditions
        </h2>

        <p>
            Rendez Wine se réserve le droit de modifier à tout moment les présentes Conditions de Service conformément
            au
            présent Article. Si nous modifions les présentes Conditions de Service, nous publierons les Conditions
            modifiées
            sur la Plateforme Rendez Wine en modifiant la date « Dernière mise à jour » figurant en haut des Conditions.
            Nous
            vous informerons également des modifications par e-mail dans un délai minimum de trente (30) jours avant la
            date
            de leur prise d’effet. Si vous désapprouvez les Conditions de Service modifiées, vous pouvez résilier le
            présent
            Contrat avec effet immédiat. Nous vous informerons de votre droit de résilier le Contrat dans l’e-mail de
            notification. Si vous ne résiliez pas votre Contrat avant la date de prise d’effet des Conditions de Service
            modifiées et que vous continuez d’accéder à la Plateforme Rendez Wine ou de l’utiliser, vous serez réputé
            avoir
            accepté les Conditions de Service modifiées.
        </p>

        <h2>
            Article VI. Création d’un compte
        </h2>

        <p>
            Vous devez créer un compte (ci-après, le « Compte Rendez Wine ») pour pouvoir accéder à certaines
            fonctionnalités
            de la Plateforme Rendez Wine et les utiliser, telles que publier une Activité. Si vous créez un Compte
            Rendez Wine
            pour une société ou autre personne morale, vous certifiez avoir le pouvoir d’engager juridiquement cette
            personne
            morale et de nous accorder toutes les autorisations et licences prévues par les présentes Conditions de
            Service.
        </p>

        <p>
            Vous devez saisir des informations exactes, à jour et complètes au cours de la procédure d’enregistrement et
            tenir
            à jour les informations de votre Compte Rendez Wine ainsi que celles figurant sur la page publique de votre
            profil
            sur votre Compte Rendez Wine.
        </p>

        <p>
            Il vous appartient de préserver la confidentialité et la sécurité de vos identifiants de Compte Rendez Wine,
            et il
            vous est interdit de divulguer vos identifiants à un quelconque tiers. Si vous pensez ou si vous avez des
            raisons
            de croire que vos identifiants ont été perdus, volés, détournés ou compromis d’une quelconque manière, ou en
            cas
            d’utilisation non autorisée avérée ou présumée de votre Compte Rendez Wine, vous devez en informer
            immédiatement
            Rendez Wine. Vous êtes responsable de toutes les activités menées avec votre Compte Rendez Wine, sauf si
            vous
            n’avez pas autorisé ces activités et que vous n’avez pas fait preuve de négligence par ailleurs (par
            exemple, en
            ne signalant pas l’utilisation non autorisée ou la perte de vos identifiants).
        </p>

        <h2>
            Article VII. Contenu
        </h2>

        <p>
            Rendez Wine permet aux Hôtes de créer, charger, publier, envoyer, recevoir ou stocker du contenu, tel que du
            texte, des photos, du contenu audio ou vidéo, ou d’autres documents et informations sur ou via la Plateforme
            Rendez Wine (le « Contenu des Hôtes »), et d’accéder et consulter le Contenu des Hôtes et tout contenu mis à
            disposition par Rendez Wine sur ou via la Plateforme Rendez Wine, y compris le contenu propriétaire de
            Rendez Wine
            et tout contenu sous licence ou qu’un tiers a autorisé Rendez Wine à utiliser (ci-après, le « Contenu de
            Rendez
            Wine », et conjointement avec le Contenu des Hôtes, le « Contenu»).
        </p>

        <p>
            La Plateforme Rendez Wine, le Contenu de Rendez Wine et le Contenu des Hôtes peuvent être entièrement ou
            partiellement protégés par le droit d’auteur, le droit des marques et/ou d’autres lois Françaises ou
            d’autres
            pays. Vous reconnaissez et convenez que la Plateforme Rendez Wine et le Contenu, y compris tous les droits
            de
            propriété intellectuelle associés, sont la propriété exclusive de Rendez Wine et/ou de ses concédants de
            licences
            ou tiers lui ayant octroyé une autorisation. Vous vous interdisez de retirer, altérer ou masquer une
            quelconque
            mention de droit d’auteur, marque de commerce, marque de service ou autres mentions de droits de propriété
            intégrés dans, ou accompagnant la Plateforme Rendez Wine, le Contenu de Rendez Wine ou le Contenu des Hôtes.
            Toutes les marques de commerce, marques de service, logos, appellations commerciales et autres signes
            distinctifs
            de Rendez Wine utilisés sur ou en lien avec la Plateforme Rendez Wine et le Contenu de Rendez Wine sont des
            marques de commerce ou des marques déposées de Rendez Wine en France et dans d’autres pays. Les marques de
            commerce, marques de service, logos, appellations commerciales et autres désignations propriétaires de tiers
            utilisés sur ou en lien avec la Plateforme Rendez Wine, le Contenu de Rendez Wine et le Contenu des Hôtes
            sont
            utilisés à uniquement des fins d’identification et peuvent être la propriété de leurs propriétaires
            respectifs.
        </p>

        <p>
            Il vous est interdit d’utiliser, de reproduire, d’adapter, de modifier, de créer des œuvres dérivées de, de
            distribuer, de concéder une licence sur, de vendre, de transférer, de présenter publiquement, de
            transmettre, de
            diffuser ou d’exploiter de toute autre manière la Plateforme Rendez Wine ou le Contenu collectif, sauf si
            vous
            êtes propriétaire de plein droit du Contenu d’Hôte concerné, ou si les présentes Conditions de Service vous
            y
            autorisent expressément. Aucune licence ni aucun droit ne vous est concédé(e) implicitement ou autrement au
            titre
            de tout droit de propriété intellectuelle appartenant à ou contrôlé par Rendez Wine ou ses concédants, sauf
            pour
            les licences et droits expressément concédés dans les présentes Conditions.
        </p>

        <p>
            Sous réserve de votre respect des présentes Conditions de Service, Rendez Wine vous octroie une licence
            limitée,
            non exclusive, non cessible, révocable, et ne pouvant faire l’objet d’une sous-licence pour utiliser le Site
            sur
            votre ou vos appareils personnels, et accéder au Contenu collectif mis à votre disposition sur ou via la
            Plateforme Rendez Wine, et le consulter, uniquement pour votre usage personnel et non commercial.
        </p>

        <p>
            En chargeant, en publiant ou en mettant à disposition d’une autre manière tout Contenu d’Hôte sur ou via la
            Plateforme Rendez Wine, vous accordez à Rendez Wine une licence non exclusive, mondiale, libre de droits,
            irrévocable, perpétuelle (ou pour la durée de la protection), cessible et pouvant faire l’objet d’une
            sous-licence
            sur ce Contenu d’Hôte pour accéder à, utiliser, stocker, reproduire, modifier, créer des œuvres dérivées de,
            distribuer, publier, transmettre, diffuser et exploiter de toute autre manière ce Contenu de Hôte afin de
            fournir
            et/ou promouvoir la Plateforme Rendez Wine sur tout support. Si le Contenu de Hôte (y compris les Images
            vérifiées) comprend des informations personnelles, ce Contenu de Hôte sera uniquement utilisé auxdites fins
            si ces
            dernières sont conformes au droit relatif à la protection des données personnelles applicable, conformément
            à
            notre Politique de Confidentialité. Sauf avec votre consentement exprès, Rendez Wine ne revendiquera aucun
            droit
            de propriété sur un quelconque Contenu de Hôte, et aucune clause des présentes Conditions de Service ne sera
            réputée limiter vos droits d’utilisation ou d’exploitation de votre Contenu des Hôtes.
        </p>

        <p>
            Rendez Wine peut offrir aux Hôtes la possibilité de faire appel à des photographes pour faire des prises de
            vue de
            leurs Activités proposés par l'Hôte, lesquelles sont mises à la disposition des Hôtes afin qu’ils les
            insèrent
            dans leurs Annonces avec ou sans filigrane ou marquage comportant la mention « Photo vérifiée par Rendez
            Wine » ou
            autre mention similaire (les « Images vérifiées »). Il vous appartient de vérifier que votre Activité est
            fidèlement représentée dans les Images vérifiées et vous devez cesser d’utiliser les Images vérifiées sur ou
            via
            la Plateforme Rendez Wine si elles ne représentent plus correctement votre Annonce, si vous ne fournissez
            plus le
            Service proposé par l'Hôte présenté ou si votre Compte Rendez Wine est clôturé ou suspendu pour une
            quelconque
            raison. Vous reconnaissez et convenez que Rendez Wine a le droit d’utiliser toute Image vérifiée à des fins
            publicitaires, de marketing ou commerciales, y compris une utilisation limitée par des tiers autorisés, sur
            tout
            support. Si Rendez Wine n’est pas le propriétaire exclusif d’Images vérifiées, en utilisant ces Images
            vérifiées
            sur ou via la Plateforme Rendez Wine, vous accordez à Rendez Wine une licence exclusive, mondiale, libre de
            droits, irrévocable, perpétuelle (ou pour la durée de la protection), cessible et pouvant faire l’objet
            d’une
            sous-licence pour utiliser ces Images vérifiées à des fins publicitaires, de marketing ou commerciales sur
            tout
            support. Rendez Wine vous concède une licence limitée, non exclusive, non cessible, révocable et ne pouvant
            faire
            l’objet d’une sous-licence pour utiliser des Images vérifiées en dehors de la Plateforme Rendez Wine
            uniquement
            pour votre usage personnel et non commercial.
        </p>

        <p>
            Vous êtes seul responsable de l’ensemble du Contenu d’Hôte que vous mettez à disposition sur ou via la
            Plate-forme
            Rendez Wine. Par conséquent, vous déclarez et garantissez que : vous êtes soit le propriétaire unique et
            exclusif
            de l’ensemble du Contenu d’Hôte que vous mettez à disposition sur ou via la Plateforme Rendez Wine, soit que
            vous
            disposez de tous les droits, licences, consentements et autorisations nécessaires pour octroyer à Rendez
            Wine les
            droits sur ce Contenu d’Hôte, comme le prévoient les présentes Conditions de Service ; et ni le Contenu
            d’Hôte, ni
            votre publication, chargement, publication, soumission ou transfert du Contenu d’Hôte, ni l’utilisation par
            Rendez
            Wine du Contenu des Hôtes (ou de toute partie de celui-ci) telle que prévue par les présentes Conditions de
            Service n’enfreindra, ne détournera ni ne violera aucun(e) brevet, droit d’auteur, marque commerciale,
            secret de
            fabrique, droits moraux ou autres droits exclusifs ou de propriété intellectuelle, ou droits de publicité ou
            de
            confidentialité de tiers, ni n’entraînera la violation de toute loi ou règlement applicable.
        </p>

        <p>
            Vous vous interdisez de poster, charger, publier, soumettre ou transmettre tout Contenu d’Hôte qui : est
            frauduleux, faux, trompeur (directement ou par omission ou manquement à mettre à jour des informations) ou
            mensonger ; est diffamatoire, calomnieux, obscène, pornographique, vulgaire ou choquant ; incite à la
            discrimination, au fanatisme, au racisme, à l’intolérance, à la haine, au harcèlement ou à causer un
            préjudice à
            l’encontre d’un individu ou d’un groupe ; est violent ou menaçant ou fait la promotion de la violence ou
            d’actes
            menaçants à l’encontre de toute autre personne ou de tout animal ; encourage les activités ou l’usage de
            substances illégales ou dangereuses ; ou enfreint le Contenu de Rendez Wine ou toute autre politique de
            Rendez
            Wine. Rendez Wine peut supprimer ou désactiver l’accès à tout Contenu d’Hôte qui est contraire au droit
            applicable, aux présentes Conditions de Service ou aux politiques ou normes en vigueur de Rendez Wine, ou
            potentiellement nuisibles ou inacceptables pour Rendez Wine, ses Utilisateurs, ses Hôtes, des tiers ou des
            biens.
            Lorsque Rendez Wine supprime ou désactive le Contenu d’Hôte, Rendez Wine informe le Visiteur et lui donne
            les
            raisons de cette décision sauf si cette notification empêche ou gêne la découverte d'une fraude ou d'une
            autre
            activité illégale, nuit à l'intérêt légitime des autres Hôtes ou de tiers, ou enfreint le droit applicable.
            Vous
            pouvez faire appel de cette décision en contactant le Service Client.
        </p>

        <p>
            Rendez Wine respecte les lois sur les droits d’auteur et attend de ses Hôtes qu’ils en fassent de même. Si
            vous
            pensez qu’un quelconque contenu sur la Plateforme Rendez Wine viole vos droits d’auteur, veuillez-nous en
            informer.
        </p>

        <h2>
            Article VIII. Commissions
        </h2>

        <p>
            Rendez Wine facture des frais aux Hôtes (les « Commissions ») en contrepartie de l’utilisation de la
            Plateforme
            Rendez Wine. Les Commissions sont entièrement supportées par l'Hôte. Les Options proposées par les Hôtes aux
            Visiteurs, en complément de leurs Activités, sont également assujettis aux Commissions.
        </p>

        <p>
            Les Commissions de Rendez Wine sont calculées de la façon suivante :
        </p>

        <ul>
            <li>
                Pour les Activités réservées par un Utilisateur sur le site ou l’application mobile : 4€ HT par
                Réservation
                multiplié par le nombre d’Adulte attachés à la Réservation
            </li>

            <li>
                Pour les Activités siglées « Rendez Wine », en accord avec l’Hôte, réservées par un Utilisateur depuis
                le site
                ou l’application : la totalité du prix de la Réservation sera reversé à Rendez Wine.
            </li>

            <li>
                Pour les Activités de l’onglet « Œnotourisme » : 20% du Prix par adulte attaché à la Réservation
                multiplié par
                le nombre d’Adulte attachés à la Réservation.
            </li>
        </ul>

        <p>
            Aucune dérogation aux présentes Conditions de Service concernant le calcul des Commissions ne pourront être
            valable sans un écrit formellement rédigé par le Président de Rendez Wine ou le Directeur Général de Rendez
            Wine.
        </p>

        <p>
            Les Commissions applicables (y compris les éventuelles Taxes applicables) sont communiquées à l’Hôte avant
            qu’il
            ne publie une Activité. Rendez Wine se réserve le droit de modifier à tout moment les Commissions et en
            informera
            les Hôtes dans un délai raisonnable avant la prise d’effet des nouveaux tarifs. Ces modifications de
            Commissions
            n'auront pas d'effet sur les réservations effectuées avant la date de prise d'effet de la modification des
            frais.
        </p>

        <p>
            Vous êtes tenu de payer les Commissions dus à Rendez Wine.
        </p>

        <p>
            A chaque réservation d’une Activité par un Utilisateur, les paiements des Activités sont reversés sur un
            compte
            dédié à l’Hôte et ouvert à son nom chez Stripe, le partenaire de Paiement de Rendez Wine.
        </p>

        <p>
            Si le Net Partenaire figurant sur une Facture de Commission est négatif, l’Hôte s’engage à reverser le
            montant de
            sa dette envers Rendez Wine sous trente (30) jours. En cas de retard, Rendez Wine pourra facturer des
            intérêts de
            retard calculés sur la base de la mention indiquée sur la Facture de Commission non soldée.
        </p>

        <p>
            Sauf mention contraire sur la Plateforme Rendez Wine, les Commissions ne sont pas remboursables.
        </p>

        <h2>
            Article IX. Publication d’une Annonce
        </h2>

        <p>
            Lorsqu’un Hôte crée une Annonce sur la Plateforme Rendez Wine, il doit fournir des informations complètes et
            exactes sur l’Activité proposée (comme sa description, son emplacement et les dates de disponibilité),
            préciser
            les déficiences, les restrictions et les conditions applicables et fournir d’autres informations utiles
            demandées
            par Rendez Wine. Il appartient à l’Hôte de tenir à jour les informations de ses Annonces (y compris les
            dates de
            disponibilité).
        </p>

        <p>
            L’Hôte est le seul responsable de la fixation du prix (Taxes applicables comprises, ainsi que toute autre
            dépense
            ou frais) pour son Annonce (ci-après, le « Prix affiché »). Une fois qu’un Utilisateur a réservé, l’Hôte ne
            pourra
            pas lui demander un prix supérieur à celui figurant dans la demande de réservation sauf en cas d’achat de
            prestation supplémentaire par le Visiteur une fois sur place.
        </p>

        <p>
            Les Commissions sont intégralement supportées par l’Hôte. Ainsi, le prix de l’Activité proposée dans
            l’Annonce ne
            peut être différent du prix habituellement par l’Hôte en direct ou sur son site internet pour cette même
            Activité.
        </p>

        <p>
            Toute Annonce publiée par un Hôte fait mention de la politique d’annulation applicable dans laquelle figure
            les
            conditions de remboursement d’une réservation annulée à l’initiative d’un Visiteur. En publiant l’Annonce,
            l’Hôte
            s’engage à respecter la politique d’annulation attachée à ladite Annonce publiée.
        </p>

        <p>
            Aucune des conditions énoncées dans l’Annonce d’un Hôte, notamment celles relatives aux annulations, ne doit
            être
            contraire aux présentes Conditions ou aux conditions d’annulation
        </p>

        <p>
            Les photos, animations ou vidéos (collectivement, les « Images ») utilisées dans l’Annonce d’un Hôte doivent
            refléter fidèlement la qualité et l’état de l’Activité proposée par l'Hôte. Rendez Wine se réserve le droit
            d’imposer un nombre minimum d’Images, dans un format, une taille et une résolution spécifiques, pour chaque
            Annonce.
        </p>

        <p>
            L’insertion et le classement des Annonces dans les résultats de recherche sur la Plateforme Rendez Wine
            peuvent
            varier et dépendent de différents facteurs, comme les paramètres de recherche et les préférences de
            l’Utilisateur,
            les exigences de l’Hôte, les prix et les dates de disponibilité, le nombre et la qualité des Images, le
            service
            client et l’historique des annulations, le type d’Activités proposées par l'Hôte et/ou la facilité de
            réservation.
        </p>

        <p>
            Lorsqu’un Hôte accepte une demande de réservation d’un Visiteur, il conclut un accord juridiquement
            contraignant
            avec ce Visiteur et est alors tenu de fournir l’Activité proposée par l'Hôte au Visiteur de la manière
            décrite
            dans l’Annonce à la date de la demande de réservation. Il accepte également de payer les Commissions
            applicables
            et les éventuelles Taxes applicables.
        </p>

        <p>
            En acceptant les présentes Conditions de Service, l’Hôte s’engage à être couvert par un contrat d’assurance
            en
            responsabilité civile professionnelle.
        </p>

        <p>
            En acceptant les présentes Conditions de Service, l’Hôte s’engage à souscrire un contrat d’assurance adaptée
            à
            chacune des Activités qu’il proposera sur la Plateforme. Veuillez lire attentivement votre contrat
            d’assurance, et
            en particulier, assurez-vous de bien comprendre les exclusions de garantie éventuelles ainsi que les
            franchises
            applicables, et vérifiez entre autres si votre contrat d’assurance couvre les actions ou omissions des
            Visiteurs
            (et des personnes pour lesquelles le Visiteur a réservé, le cas échéant) pendant leur participation à votre
            Activité ou Événement proposé par l'Hôte.
        </p>

        <h2>
            Article X. Conditions relatives aux paiements
        </h2>

        <p>
            En règle générale, Rendez Wine collectera les Frais totaux auprès d'un Visiteur au moment où la demande de
            réservation du Visiteur est acceptée par l'Hôte (ci-après, « les Frais Totaux »), ou à tout autre moment
            mutuellement convenu par le Visiteur et Rendez Wine.
        </p>

        <p>
            Rendez Wine procédera en général aux Versements sur la Méthode de versement que l’Hôte aura choisie.
        </p>

        <p>
            Le temps nécessaire pour recevoir les Versements une fois délivrés par Rendez Wine peut dépendre de la
            Méthode de
            versement sélectionnée et du délai de traitement du prestataire de ladite Méthode de versement. Rendez Wine
            peut
            retarder ou annuler tout Versement afin d'empêcher toute activité illégale ou fraude, à des fins
            d'évaluation des
            risques, de sécurité ou d'investigation.
        </p>

        <p>
            Le Versement pour une réservation sera les Frais Totaux liés à cette réservation moins les Commissions et
            les
            Taxes applicables.
        </p>

        <p>
            En cas d'annulation par le Visiteur d'une réservation confirmée conformément à la Politique d'Annulation
            applicable de l’Activité ou de l’Évènement, Rendez Wine procèdera au Remboursement total des Frais Totaux au
            Visiteur et aucun Versement ne sera dû à l’Hôte, conformément à la Politique d'Annulation applicable.
        </p>

        <p>
            En cas d'annulation par le Visiteur d'une réservation confirmée non-conformément à la Politique d'Annulation
            applicable de l’Expérience ou de l’Évènement (hors du délai de rétractation prévu), Rendez Wine procèdera au
            Versement des Frais Totaux moins les Commissions et les Taxes applicables à l’Hôte.
        </p>

        <p>
            Les Hôtes pourront être amenés à acheter des services supplémentaires.
        </p>

        <p>
            Afin de recevoir les Services Supplémentaires, l’Hôte doit disposer d'une Méthode de paiement valable liée à
            son
            Compte Rendez Wine. Rendez Wine procédera en général aux Prélèvement sur la Méthode de versement que l’Hôte
            aura
            choisie en fonction de la période de souscription choisie.
        </p>

        <p>
            Un règlement différé, après transmission d’une facture à l’Hôte par Rendez Wine, pourra être effectué dans
            le
            cadre où l’Hôte ne dispose pas d’autres moyens de paiement valables ou si, sur une période donnée, le
            montant des
            Frais Totaux dus à l’Hôte sont inférieurs au montant des Commissions et de la Taxe Applicable aux
            Commissions.
        </p>

        <p>
            Si Rendez Wine ne pas effectuer un Versement, un remboursement ou payer d'autres sommes étant dues à l’Hôte
            dans
            la période définie par son Etat, pays ou tout autre autorité publique selon le droit applicable aux
            propriétés non
            réclamées, Rendez Wine pourra débloquer les sommes dues conformément à ses obligations légales, tel qu'exigé
            par
            la loi.
        </p>

        <h2>
            Article XI. Modifications de réservation, Annulations et Remboursements
        </h2>

        <p>
            Les Visiteurs peuvent annuler à tout moment une réservation confirmée. Sous réserve de la politique
            d’annulation
            de l’Annonce définies par l'Hôte, Rendez Wine remboursera le montant de la réservation au Visiteur
            conformément à
            ces conditions de remboursement.
        </p>

        <p>
            Lorsqu’un Visiteur annule une réservation sans respecter les conditions de remboursement stipulées dans la
            Politique d’annulation de l’Annonce annulée, Rendez Wine percevra les Commissions liés à cette réservation.
        </p>

        <p>
            Si un Hôte annule une réservation confirmée, le Visiteur sera intégralement remboursé le montant total payé
            pour
            cette réservation. En outre, Rendez Wine pourra publier un commentaire automatique sur l’Annonce annulée par
            l’Hôte, indiquant qu’une réservation a été annulée.
        </p>

        <p>
            Pour les Activités, Événements et autres Services proposés par l'Hôte, si des intempéries créent des
            conditions
            dangereuses ou inconfortables pour les Visiteurs, les Hôtes peuvent modifier ou annuler un Service proposé
            par
            l'Hôte. En cas de changement substantiel dans l’itinéraire ou si le Service proposé par l'Hôte doit être
            annulé,
            Rendez Wine, en concertation avec l’Hôte et/ou les Visiteurs, s’efforcera de proposer aux Visiteurs une
            autre date
            pour le Service proposé par l'Hôte, un remboursement approprié ou une nouvelle réservation.
        </p>

        <p>
            Dans certains cas, Rendez Wine peut annuler une réservation en cours ou confirmée pour le compte d'un Hôte
            ou
            Visiteur et effectuer les remboursements et paiements appropriés. Lorsque Rendez Wine annule une
            réservation,
            Rendez Wine en informe les Visiteurs concernés et donne les raisons de cette mesure, sauf si cette
            notification
            empêche ou gêne la découverte ou la prévention d'une fraude ou autres activités illégales, nuit aux intérêts
            légitimes des autres Visiteurs ou tiers, ou enfreint le droit applicable.
        </p>

        <p>
            Rendez Wine se réserve tout droit d’annulation d’une réservation effectué sur le Site.
        </p>

        <h2>
            Article XII. Framing
        </h2>

        <p>
            Rendez Wine offre aux Hôtes la possibilité d’ajouter un module de réservation lié à Rendez Wine directement
            sur
            son site internet.
        </p>

        <p>
            Ce module de réservation est un extrait du code source de Rendez Wine (ci-après, le « Module de Réservation
            ») et
            permet aux visiteurs du site internet de l’Hôte de réserver une Activité de l’Hôte en dehors de la
            Plateforme
            Rendez Wine.
        </p>

        <p>
            Le code mis à disposition de l’Hôte reste la propriété exclusive de Rendez Wine.
        </p>

        <p>
            Rendez Wine se dégage de toutes responsabilités quant à l’impact que pourrez avoir l’intégration du Module
            de
            Réservation sur le site de l’Hôte notamment sur les points suivants :
        </p>

        <ul>
            <li>
                Atteinte à la performance du site de l’Hôte,
            </li>

            <li>
                Atteinte à l’ergonomie du site de l’Hôte,
            </li>

            <li>
                Problème de comptabilité des utilisateurs du site de l’Hôte,
            </li>

            <li>
                Atteinte au bon fonctionnement du serveur hébergeant le site de l’Hôte,
            </li>

            <li>
                Tout autre problème technique dont le Module de Réservation pourrait en être la source.
            </li>
        </ul>

        <p>
            Il appartient à l’Hôte de ne pas intégrer le Module de réservation sur son site internet si ledit Module de
            Réservation ne s’avérera pas compatible ou affecte de quelques manières que ce soit le bon comportement de
            son
            site.
        </p>

        <p>
            Des Commissions différent peuvent s’appliquer aux réservations d’Activités effectuées depuis un Module de
            Réservation par rapport aux réservations effectuées directement sur la Plateforme. L’Hôte en est clairement
            informé depuis l’interface de gestion de son Compte Rendez Wine.
        </p>

        <h2>
            Article XIII. Litiges entre Hôtes et/ou Visiteurs
        </h2>

        <p>
            L’Hôte reste le seul responsable de l’exactitude de ces données et en cas de litige avec un Visiteur ou tout
            autre
            acteur, Rendez Wine ne pourra en être tenu pour responsable.
        </p>

        <h2>
            Article XIV. Arrondis
        </h2>

        <p>
            En général, Rendez Wine prend en charge le paiement des montants qui sont payables par les/aux Visiteurs ou
            Hôtes
            dans la plus petite unité prise en charge par une monnaie donnée (p. ex., les centimes américains, les
            centimes
            d’Euro ou autres devises prises en charge). Si un prestataire de paiement tiers de Rendez Wine ne prend pas
            en
            charge les paiements dans la plus petite unité prise en charge par une monnaie donnée, Rendez Wine peut, à
            sa
            seule discrétion, arrondir les sommes affichées payables par les/aux Visiteurs ou Hôtes à l’entier
            fonctionnel le
            plus proche (supérieur ou inférieur) de la monnaie libellée (c.-à-d. au dollar, à l’euro ou à toute autre
            devise
            prise en charge le/la plus proche). Par exemple, Rendez Wine peut arrondir un montant de 11,50 € à l’entier
            supérieur 12 € et arrondir un montant de 11,49 € à l’entier inférieur 11 €.
        </p>

        <h2>
            Article XV. Taxes
        </h2>

        <p>
            En tant qu’Hôte, il vous appartient de déterminer vos obligations de déclaration, de collecte, de versement,
            ou
            d’inclusion dans votre Prix affiché, de toute TVA applicable ou autre taxe indirecte sur les ventes, taxes
            sur les
            touristes ou autres (ci-après, les « Taxes »).
        </p>

        <p>
            Les réglementations fiscales peuvent nous imposer de recueillir des données fiscales pertinentes concernant
            les
            Hôtes. Si un Hôte ne nous fournit pas les documents requis conformément au droit applicable (numéro
            d'identification fiscale, par exemple) pour nous acquitter de notre obligation (le cas échéant) de retenir
            des
            Taxes à la source sur les versements que nous vous faisons, nous nous réservons le droit de retenir les
            versements
            pouvant aller jusqu'aux montants imposables comme la législation l’exige jusqu'à résolution.
        </p>

        <p>
            L’Hôte comprend que toute agence, service et/ou autorité publics compétents (l’ « Administration fiscale »)
            dont
            dépend votre Hébergement peuvent exiger la collecte de Taxes auprès des Visiteurs ou des Hôtes sur les Prix
            affichés, et le reversement de ces Taxes à l’Administration fiscale appropriée. Les lois varient selon les
            pays
            mais ces Taxes peuvent être perçues et reversées sous la forme d’un pourcentage du Prix affiché fixé par les
            Hôtes, d’un montant fixe par jour, ou autres variantes (les « Taxes d’occupation »).
        </p>

        <p>
            Les Visiteurs et les Hôtes conviennent que nous pourrons chercher à recouvrer des sommes supplémentaires
            auprès
            des Visiteurs si les Taxes collectées et/ou reversées s’avéraient insuffisantes pour honorer pleinement vos
            obligations envers l’Administration fiscale, et acceptent que leur seul recours en matière de Taxes
            d’occupation
            collectées consiste à rembourser les Taxes d’occupation collectées par Rendez Wine auprès de
            l’Administration
            fiscale concernée, conformément aux procédures applicables fixées par cette Administration fiscale.
        </p>

        <h2>
            Article XVII. Activités interdites
        </h2>

        <p>
            Il appartient à l’Hôte de se conformer à l’ensemble des lois, règles, règlements et obligations fiscales
            applicables à l’utilisation de la Plateforme Rendez Wine. En lien avec l’utilisation de la Plateforme Rendez
            Wine,
            l’Hôte s’interdit d’aider d’autres personnes à, ou de permettre à d’autres personnes de :
        </p>

        <ul>
            <li>
                enfreindre ou contourner toutes lois ou règlements applicables, accords passés avec des tiers, droits de
                tiers,
                ou nos Conditions;
            </li>

            <li>
                utiliser la Plateforme Rendez Wine ou le Contenu à des fins commerciales ou à d’autres fins qui ne sont
                pas
                expressément autorisées par les présentes Conditions, ou d’une manière laissant croire, à tort, à une
                approbation de Rendez Wine, à l’existence d’un partenariat avec Rendez Wine, ou induisant en erreur de
                toute
                autre manière quant à vos liens avec Rendez Wine ;
            </li>

            <li>
                reproduire, stocker, accéder à ou utiliser de quelque manière que ce soit toute information, y compris
                des
                informations nominatives concernant tout autre Hôte ou Visiteur, disponible sur la Plateforme Rendez
                Wine, en
                contradiction avec la Charte de protection des données à caractère personnel de Rendez Wine ou les
                présentes
                Conditions, ou en portant atteinte aux droits au respect de la vie privée de Visiteurs, d’Hôtes ou de
                tiers ;
            </li>

            <li>
                utiliser la Plateforme Rendez Wine pour diffuser des messages commerciaux indésirables (« spam ») ;
            </li>

            <li>
                proposer, en tant qu’Hôte, une Activité dont vous n’êtes pas propriétaire ou que vous n’êtes pas
                autorisé à
                proposer sur la Plateforme Rendez Wine ;
            </li>

            <li>
                contacter un autre Hôte à toute fin autre que pour poser une question sur votre propre réservation,
                votre
                Annonce, ou l’utilisation de la Plateforme Rendez Wine par le Hôte, y compris, sans limitation, pour
                recruter ou
                solliciter un Hôte pour participer à des services tiers, applications ou sites Internet sans notre
                autorisation
                écrite préalable ;
            </li>

            <li>
                discriminer ou harceler toute personne en raison de son origine ethnique ou nationale, de sa religion,
                de son
                sexe, de son identité sexuelle, de son handicap physique ou mental, de son état de santé, de son état
                civil, de
                son âge ou de son orientation sexuelle, ou adopter un quelconque comportement violent, dangereux, abusif
                ou
                perturbateur ;
            </li>

            <li>
                diluer, ternir ou nuire autrement à la marque Rendez Wine de quelque façon que ce soit, y compris
                l’usage non
                autorisé du Contenu, l’enregistrement et/ou l’utilisation du mot Rendez Wine ou de termes dérivés dans
                des noms
                de domaine, noms commerciaux, marques ou autres identifiants de source, ou l’enregistrement et/ou
                l’utilisation
                de noms de domaine, noms commerciaux, marques ou autres identifiants de source qui imitent étroitement
                ou sont
                similaires de façon à prêter à confusion avec les noms de domaine, marques, slogans, campagnes
                publicitaires ou
                Contenu collectif de Rendez Wine ;
            </li>

            <li>
                utiliser des robots, spiders, crawlers, scrapers ou autres moyens ou processus automatiques pour accéder
                à la
                Plateforme Rendez Wine, récupérer des données ou autre contenu sur la Plateforme Rendez Wine ou
                interagir avec
                la Plateforme Rendez Wine à toute autre fin ;
            </li>

            <li>
                éviter, détourner, retirer, désactiver, endommager, décoder, ou tenter de contourner d’une autre manière
                toute
                mesure technologique mise en place par Rendez Wine ou tout fournisseur de Rendez Wine ou de tout autre
                tiers
                afin de protéger la Plateforme Rendez Wine ;
            </li>

            <li>
                tenter de déchiffrer, décompiler, désassembler ou procéder à l’ingénierie inverse de tout logiciel
                utilisé pour
                fournir la Plateforme Rendez Wine ;
            </li>

            <li>
                utiliser, réexporter, importer ou transférer l’Application sans y être autorisé par les lois des
                États-Unis, les
                lois relatives au contrôle des exportations en vigueur dans votre pays, et toutes les autres lois
                applicables ;
            </li>

            <li>
                porter atteinte aux droits de tiers ou nuire à qui que ce soit.
            </li>
        </ul>

        <p>
            Vous reconnaissez que Rendez Wine n’a pas d'obligation générale de surveiller le Contenu des Hôtes ou de
            rechercher activement des faits et circonstances indiquant une activité illégale, mais que Rendez Wine a le
            droit
            de vérifier, désactiver l’accès à, ou modifier le Contenu des Hôtes, pour pouvoir exploiter, protéger et
            améliorer
            la sécurité de la Plateforme Rendez Wine (y compris, sans limitation, aux fins de prévention des fraudes,
            d’’évaluation des risques, pour des enquêtes et pour l’assistance utilisateurs) ; vérifier le respect des
            présentes Conditions par les Hôtes ; se conformer au droit applicable, à l’ordonnance d’un tribunal, ou aux
            requêtes de la police, de la justice ou d’autres agences publiques ou organes de l’État ; réagir au Contenu
            des
            Hôtes qu’elle juge nuisible ou inacceptable ; ou aux autres fins prévues par les présentes Conditions. Les
            Hôtes
            s’engagent à coopérer avec Rendez Wine et à l’aider en toute bonne foi, à fournir à Rendez Wine les
            informations
            requises et à prendre les mesures raisonnables demandées par Rendez Wine, en lien avec toute enquête menée
            par
            Rendez Wine ou un mandataire de Rendez Wine sur l’utilisation ou l’utilisation abusive de la Plateforme
            Rendez
            Wine.
        </p>

        <h2>
            Article XVIII. Durée du contrat et résiliation
        </h2>

        <p>
            Le présent Contrat sera valable jusqu’à ce que l’Hôte ou Rendez Wine résilie le Contrat tel que décrit
            ci-dessous.
        </p>

        <p>
            L’Hôte peut résilier le présent Contrat à tout moment en envoyant un e-mail à Rendez Wine. Lorsqu’un Hôte
            supprime
            son Compte Rendez Wine en tant qu’Hôte, toutes les réservations confirmées seront automatiquement annulées
            et les
            Visiteurs recevront un remboursement intégral. Si l’Hôte supprime son Compte Rendez Wine en tant que
            Visiteur,
            toutes les réservations confirmées seront automatiquement annulées et le remboursement éventuel dépendra des
            conditions d’annulation de l’Annonce.
        </p>

        <p>
            Sans limiter ses droits spécifiés ci-dessous, Rendez Wine peut résilier le présent Contrat pour convenance à
            tout
            moment en vous donnant un préavis de dix (10) jours par e-mail envoyé à votre adresse e-mail enregistrée.
        </p>

        <p>
            Rendez Wine peut immédiatement et sans préavis résilier le présent Contrat et/ou cesser de fournir l’accès
            d’un
            Hôte à la Plateforme Rendez Wine si l’Hôte a commis un manquement substantiel à ses obligations au titre des
            présentes Conditions de Service, s’il a violé les lois et règlements applicables ou des droits de tiers, ou
            si une
            telle action est nécessaire pour protéger la sécurité ou la propriété de Rendez Wine, de ses Hôtes, de ses
            Utilisateurs ou de tiers (par exemple, en cas de comportement frauduleux d’un Hôte).
        </p>

        <p>
            En outre, Rendez Wine peut prendre l’une quelconque des mesures suivantes pour se conformer au droit
            applicable, à
            l’ordonnance d’un tribunal, ou aux requêtes de la police, de la justice ou d’autres agences publiques ou
            organes
            de l’État, ou si un Hôte a violé les présentes Conditions de Service, les lois et règlements applicables ou
            les
            droits de tiers, si l’Hôte a fourni des informations inexactes, frauduleuses, obsolètes ou incomplètes lors
            de la
            création de son Compte Rendez Wine, de la création de l’Annonce ou ultérieurement, ses Annonces ou Services
            d’Hôte
            ne remplissent pas les critères de qualité ou d’éligibilité à tout moment, si l’Hôte a reçu à plusieurs
            reprises
            des Notations médiocres ou des Commentaires négatifs, ou Rendez Wine est informée par ailleurs ou reçoit des
            plaintes concernant ses prestations ou son comportement, si l’Hôte a à plusieurs reprises annulé des
            réservations
            confirmées ou qu’il n’a pas répondu à des demandes de réservation sans motif légitime, ou si Rendez Wine
            considère
            que cette mesure est nécessaire pour protéger la sécurité ou la propriété de Rendez Wine, de ses Hôtes, de
            ses
            Visiteurs ou de tiers, ou pour prévenir les fraudes ou autres activités illégales :
        </p>

        <ul>
            <li>
                refuser d’afficher, supprimer ou retarder la publication des Annonces, Notations, Commentaires ou autre
                Contenu
                des Hôtes ;
            </li>

            <li>
                annuler des réservations en cours ou confirmées ;
            </li>

            <li>
                limiter son utilisation de l’accès d’un Hôte à la Plateforme Rendez Wine ;
            </li>

            <li>
                révoquer temporairement ou définitivement un statut spécial associé au Compte Rendez Wine d’un Hôte ;
            </li>

            <li>
                suspendre temporairement ou, en cas d’infractions graves et répétées, définitivement, le Compte Rendez
                Wine de
                l’Hôte et cesser de lui fournir un accès à la Plateforme Rendez Wine.
            </li>
        </ul>

        <p>
            En cas d’infractions mineures et au besoin, l’Hôte est avisé de toute mesure envisagée par Rendez Wine et
            aura la
            possibilité de résoudre le problème d’une façon raisonnablement satisfaisante pour Rendez Wine.
        </p>

        <p>
            Si Rendez Wine prend l’une quelconque des mesures décrites ci-dessus, Rendez Wine pourra rembourser
            intégralement
            les Visiteurs pour toutes les réservations confirmées, quelque soient les politiques d’annulation
            préexistantes,
            et l’Hôte n’aura aucun droit à aucune compensation pour les réservations en cours ou confirmées qui ont été
            annulées.
        </p>

        <p>
            En cas de résiliation du présent Contrat, l’Hôte n’a pas droit à une restauration de son Compte Rendez Wine
            ou de
            l’un quelconque de son Contenus d’Hôtes. Si l’accès d’un Hôte à la Plateforme Rendez Wine ou son utilisation
            de la
            Plateforme Rendez Wine ont été limités, si son Compte Rendez Wine a été suspendu, ou que Rendez Wine a
            résilié le
            présent Contrat, l’Hôte ne pourra pas créer un nouveau Compte Rendez Wine ni accéder à la Plateforme Rendez
            Wine
            ou l’utiliser via le Compte Rendez Wine d’un autre Hôte.
        </p>

        <h2>
            Article XIX. Responsabilité
        </h2>

        <p>
            Rendez Wine peut être tenue responsable en vertu des dispositions légales applicables en cas de faute
            intentionnelle et grave commise par Rendez Wine, ses représentants légaux, administrateurs ou mandataires.
            Rendez
            Wine est également responsable en vertu des dispositions légales applicables en cas de responsabilité de
            plein
            droit ou en cas d'atteinte à la vie, à l'intégrité physique et/ou à la santé. La responsabilité de Rendez
            Wine est
            engagée en cas de manquements à des obligations contractuelles essentielles, commis par eux, ses
            représentants
            légaux, administrateurs ou mandataires ; cette responsabilité est limitée aux dommages prévisibles typiques
            pouvant survenir. Les obligations contractuelles essentielles sont des obligations de Rendez Wine dont
            l’Hôte
            attend la bonne exécution et sur l'accomplissement desquelles l’Hôte doit pouvoir compter pour la bonne
            exécution
            du contrat.
        </p>

        <p>
            Les Hôtes sont seuls responsables des Activités, Événements ou autres Services d'accueil qu'ils soumettent,
            proposent et fournissent. Rendez Wine n’est qu’un intermédiaire entre l’Hôte et le Visiteur. En aucun cas
            Rendez
            Wine ne pourra être tenu responsable de tout incident, litige ou plainte résultant d’évènement ayant eu lieu
            pendant la réalisation de l’Activité de l’Hôte par un Visiteur.
        </p>

        <p>
            Il est de la responsabilité de l’Hôte de comprendre l'ensemble des lois, règles et réglementations qui
            peuvent
            s'appliquer à son Activité, Événement ou autre Service d'accueil et de s’y conformer d'obtenir les licences,
            permis ou homologations requis avant de proposer une Activité, un Événement ou un autre Service d'accueil et
            de
            s’assurer que l’Annonce et/ou sa proposition d’Activité, d'Événement ou autre Service d'accueil n'enfreint
            aucun
            accord que l’Hôte pourrait avoir conclu avec des tiers.
        </p>

        <p>
            Tout incident intervenant pendant la réalisation de l’Activité de l’Hôte par le Visiteur est de la
            responsabilité
            exclusive de l’Hôte, qui s’engage par ailleurs à être couvert par un contrat d’assurance adapté.
        </p>

        <p>
            L’Hôte reste responsable de l'acquisition de tous les Équipements, y compris les fournitures, véhicules,
            locaux et
            autres éléments (« Équipements ») requis pour proposer une Activité, Événement ou un autre Service
            d'accueil.
            L’Hôte convient d'assumer l'entière responsabilité de veiller à ce que les Équipements utilisés dans votre
            Activité, Événement ou autre Service d'accueil soient en bon état et conformes avec toutes les lois
            relatives à la
            sécurité, aux Équipements et aux capacités opérationnelles. Sous réserve d'exigences légales contraires,
            l’Hôte
            assume tout risque de détérioration ou de perte de ses Équipements.
        </p>

        <h2>
            Article XX. 17. Indemnisation
        </h2>

        <p>
            Dans les limites autorisées par le droit applicable, l’Hôte accepte de décharger, défendre (si Rendez Wine
            le
            souhaite), indemniser et dégager Rendez Wine, ses sociétés affiliées et filiales, y compris, sans
            limitation,
            Rendez Wine, ainsi que leurs dirigeants, administrateurs, employés et agents, de toute responsabilité
            concernant
            tout(e) réclamation, responsabilité, dommage, perte et dépense, y compris, sans limitation, les frais
            juridiques
            et comptables raisonnables, découlant de ou liés de quelque façon que ce soit à son non-respect des
            présentes
            Conditions de Service ou son utilisation inappropriée de la Plateforme Rendez Wine, son interaction avec
            tout
            Visiteur, y compris, sans limitation, tout préjudice, perte ou dommage (compensatoire, direct, accessoire,
            immatériel ou autre) de toute sorte lié à ou résultant de cette interaction, de ce séjour, sa violation de
            toute
            loi, tout règlement ou droit de tiers. Conformément à cette section, cette obligation d'indemnisation
            s'applique
            uniquement si et dans la mesure où les demandes, responsabilités, dommages et intérêts, pertes et dépenses
            ont été
            suffisamment causées par la violation fautive d'une obligation contractuelle.
        </p>

        <h2>
            Article XXI. 18. Remarques
        </h2>

        <p>
            Nous vous invitons et encourageons à nous faire part de vos remarques commentaires et suggestions afin que
            nous
            puissions améliorer la Plateforme Rendez Wine (« Remarques »). Vous pouvez soumettre des Remarques en nous
            envoyant un e-mail, au travers de la page « Contact » de la Plateforme Rendez Wine, ou par tout autre moyen
            de
            communication. Aucune Remarque que vous nous transmettez n'est considérée comme confidentielle ou
            propriétaire. En
            nous transmettant vos Remarques, vous nous octroyez une licence non exclusive, mondiale, libre de droits,
            irrévocable, perpétuelle et pouvant faire l'objet d'une sous-licence pour utiliser et publier ces idées et
            matériaux à quelque fin que ce soit, sans aucune compensation à votre
        </p>

        <h2>
            Article XXII. Résolution des litiges
        </h2>

        <p>
            Si le pays de résidence de l’Hôte est dans l'Espace Economique Européen ("EEE"), il peut accéder à la
            plateforme
            en ligne de résolution des litiges de la Commission européenne ici : http://ec.europa.eu/consumers/odr.
        </p>
    </div>
</div>
