import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiBreadcrumbsModule } from '@taiga-ui/kit';
import { TuiLinkModule } from '@taiga-ui/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'rdw-privacy-policy',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TuiBreadcrumbsModule,
    TuiLinkModule,
    RouterLink,
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.privacy-policy`,
      routerLink: `/privacy-policy`,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  readonly prefixTranslation = 'privacy-policy.';
}
