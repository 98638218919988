import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiBooleanHandler, TuiDay, TuiLetModule } from '@taiga-ui/cdk';
import {
  listVineyardLocation,
  VineyardLocationEnum,
} from '../../../../shared/enums/vineyard-location.enum';
import { EstablishmentService } from '../../../../shared/services/establishment/establishment.service';
import { firstValueFrom, map } from 'rxjs';
import {
  TuiDataListWrapperModule,
  TuiInputDateModule,
  TuiSelectModule,
} from '@taiga-ui/kit';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { format } from 'date-fns';

@Component({
  selector: 'rdw-search-bar',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TuiButtonModule,
    TuiDataListWrapperModule,
    TuiLetModule,
    TuiSelectModule,
    FormsModule,
    TuiTextfieldControllerModule,
    TuiInputDateModule,
    RouterLink,
  ],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  readonly prefixTranslation = 'home.search-bar.';

  selectedDate?: TuiDay;
  selectedVineyardLocation?: VineyardLocationEnum;
  daysAvailable: TuiDay[] = [];
  readonly listVineyardLocation = listVineyardLocation;

  constructor(public readonly establishmentService: EstablishmentService) {}

  async ngOnInit() {
    this.daysAvailable = await firstValueFrom(
      this.establishmentService
        .establishmentAvailable()
        .pipe(map((item) => item.map((date) => TuiDay.fromUtcNativeDate(date))))
    );
  }

  selectableDays: TuiBooleanHandler<TuiDay> = (date) => {
    return !this.daysAvailable.find((item) => {
      return item.daySame(date);
    });
  };

  getFormattedDate() {
    if (!this.selectedDate) {
      return undefined;
    }

    return format(this.selectedDate.toUtcNativeDate(), 'yyyy-MM-dd');
  }
}
