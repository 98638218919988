import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { IconComponent } from '../icon/icon.component';
import { IconType } from '../icon/types/icon.type';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { ScrollService } from '../../services/scroll/scroll.service';
import { NavbarLoginComponent } from './components/navbar-login/navbar-login.component';

@Component({
  selector: 'rdw-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    TranslocoModule,
    IconComponent,
    RouterLinkActive,
    NavbarLoginComponent,
  ],
  styleUrls: ['./navbar.component.scss'],
  template: `
    <ng-container *ngIf="currenState$ | async as currenState">
      <header
        [ngClass]="{
          'header-transparent': shouldBeTransparent(currenState),
          'header-white': !shouldBeTransparent(currenState)
        }"
        class="header"
      >
        <nav class="navbar">
          <a href="/" class="flex flex-col justify-center">
            <img
              [src]="
                shouldBeTransparent(currenState)
                  ? 'assets/logo/logo-white.png'
                  : 'assets/logo/logo.png'
              "
              class="w-auto h-8"
              alt="Rendez Wine logo"
            />
            <span class="sr-only">Accueil</span>
          </a>

          <ul
            class="hidden md:flex md:flex-row md:justify-center md:items-center md:space-x-12"
          >
            <li>
              <a href="/help">
                <rdw-icon
                  iconName="help"
                  id="help"
                  className="help-icon h-8 w-auto my-auto"
                ></rdw-icon>
                <span class="sr-only">Lien vers l'aide</span>
              </a>
            </li>
            <li>
              <a
                href="/favorites"
                class="navbar-link body1 flex flex-row items-center space-x-1"
              >
                <rdw-icon
                  iconName="heart"
                  id="help"
                  className="help-icon mr-1 h-6 w-auto"
                ></rdw-icon>
                <span>Favoris</span>
              </a>
            </li>
            <li>
              <rdw-navbar-login
                [classname]="
                  shouldBeTransparent(currenState)
                    ? 'text-white'
                    : 'text-primary'
                "
              ></rdw-navbar-login>
            </li>
          </ul>
        </nav>
      </header>

      <nav
        class="fixed bottom-0 left-0 w-full md:hidden px-3 py-4 bg-white shadow-lg z-10"
      >
        <ul class="flex justify-between items-center">
          <li *ngFor="let item of navigationItems" class="flex-1">
            <a
              routerLinkActive
              #rla="routerLinkActive"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="item.path"
              class="text-sm font-montserrat text-center flex flex-col w-full justify-center items-center gap-y-1"
            >
              <rdw-icon
                [iconName]="item.icon"
                [className]="
                  'h-6 ' + (rla.isActive ? item.classActive : item.class)
                "
              ></rdw-icon>
              <span
                [class.text-primary-dark]="rla.isActive"
                [class.font-medium]="rla.isActive"
                [class.text-neutral-dark]="!rla.isActive"
                >{{ prefixTranslation + item.name | transloco }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  readonly prefixTranslation = 'navbar.';
  navigationItems: {
    name: string;
    path: string;
    icon: IconType;
    class: string;
    classActive: string;
  }[] = [
    {
      name: 'discover',
      path: '/',
      icon: 'wine',
      class: 'stroke-neutral-dark',
      classActive: 'stroke-primary-dark',
    },
    {
      name: 'favorites',
      path: '/favorites',
      icon: 'heart',
      class: 'fill-neutral-dark',
      classActive: 'fill-primary-dark',
    },
    {
      name: 'visits',
      path: '/visits',
      icon: 'calendar',
      class: 'fill-neutral-dark',
      classActive: 'fill-primary-dark',
    },
    {
      name: 'profile',
      path: '/profile',
      icon: 'person',
      class: 'fill-neutral-dark',
      classActive: 'fill-primary-dark',
    },
  ];

  /*TODO: add language selection */

  currenState$: Observable<[NavigationEnd, number]> = combineLatest([
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((e) => e as NavigationEnd)
    ),
    this.scrollService.scrollY$,
  ]);

  private readonly routeWithTransparentNavbar = ['/about', '/'];

  constructor(
    private readonly router: Router,
    private readonly scrollService: ScrollService
  ) {}

  @HostListener('window:scroll') onScroll(): void {
    this.scrollService.updateScrollY(window.scrollY);
  }

  shouldBeTransparent(currentState: [NavigationEnd, number]): boolean {
    return (
      this.routeWithTransparentNavbar.includes(currentState[0].url) &&
      currentState[1] <= 30
    );
  }
}
