import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {
  TuiBreadcrumbsModule,
  TuiDataListWrapperModule,
  TuiInputDateModule,
  TuiSelectModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDialogModule,
  TuiLinkModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { OfferService } from '../../shared/services/offer/offer.service';
import { EstablishmentService } from '../../shared/services/establishment/establishment.service';
import { EstablishmentInterface } from '../../shared/interfaces/establishment.interface';
import { OfferInterface } from '../../shared/interfaces/offer.interface';
import { Constant } from '../../shared/utils/constant';
import { TuiCurrencyPipeModule } from '@taiga-ui/addon-commerce';
import { EstablishmentMapComponent } from '../establishment/components/establishment-map/establishment-map.component';
import { EstablishmentOffersComponent } from '../establishment/components/establishment-offers/establishment-offers.component';
import { OfferCardComponent } from '../../shared/components/offer-card/offer-card.component';
import { tuiIconGlobe, tuiIconUser } from '@taiga-ui/icons';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LanguageType } from '../../shared/types/language.type';
import { BookService } from '../../shared/services/book/book.service';
import { AvailabilityInterface } from '../../shared/interfaces/availability.interface';
import { TuiDay } from '@taiga-ui/cdk';
import { doc, docData, Firestore, Timestamp } from '@angular/fire/firestore';
import { RequestBookingInterface } from '../../shared/interfaces/request-booking.interface';
import { Auth, authState } from '@angular/fire/auth';
import { ClientInterface } from '../../shared/interfaces/client.interface';
import { ModalService } from '../../shared/services/modal/modal.service';
import { BookOfferContentComponent } from './components/book-offer-content/book-offer-content.component';

@Component({
  selector: 'rdw-offer',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TuiBreadcrumbsModule,
    TuiLinkModule,
    TuiCurrencyPipeModule,
    EstablishmentMapComponent,
    EstablishmentOffersComponent,
    OfferCardComponent,
    TuiSvgModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    TuiDataListWrapperModule,
    TuiInputDateModule,
    TuiButtonModule,
    RouterLink,
    BookOfferContentComponent,
    TuiDialogModule,
  ],
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit {
  readonly prefixTranslation = 'offers.offer.';
  availabilityTimeSlots: AvailabilityInterface[] = [];

  isBooking = false;

  client$ = authState(this.auth).pipe(
    switchMap((user) =>
      !user
        ? of(undefined)
        : docData(doc(this.firestore, `clients/${user.uid}`), {
            idField: 'id',
          }).pipe(map((client) => client as ClientInterface))
    )
  ) as Observable<ClientInterface | undefined>;

  open = false;

  offersFromEstablishmentSnapshot$: Observable<OfferInterface[]> =
    this.route.params.pipe(
      switchMap(({ id, offerId }) => {
        if (!id) {
          return of([]);
        }

        return this.offerService
          .findOffersFromEstablishmentById(id)
          .pipe(
            map((offers) => offers.filter((offer) => offer.id !== offerId))
          );
      })
    );
  bookForm: FormGroup;
  dateAvailable$ = this.route.params.pipe(
    switchMap(({ id }) => {
      if (!id) {
        return of([]);
      }

      return this.bookService.getDateAvailable(id).pipe(
        map((list) => {
          return list.map((element) => {
            const start = element['start'] as Timestamp;
            const date = start.toDate();
            return new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
          });
        })
      );
    })
  );
  daysAvailable: Date[] = [];
  breadcrumb: {
    caption: string;
    routerLink: string;
    routerLinkActiveOptions?: any;
  }[] = [];
  protected readonly tuiIconUser = tuiIconUser;
  protected readonly tuiIconGlobe = tuiIconGlobe;
  private offerFromEstablishmentSnapshot$ = this.route.params.pipe(
    switchMap(({ id, offerId }) => {
      if (!id) {
        return of(undefined);
      }

      return this.offerService.findOfferFromEstablishmentById(id, offerId);
    })
  );
  private establishmentSnapshot$ = this.route.params.pipe(
    switchMap(({ id }) => {
      if (!id) {
        return of(undefined);
      }

      return this.establishmentService.findEstablishment(id);
    })
  );
  establishmentAndOfferSnapshot$: Observable<{
    establishment: EstablishmentInterface | undefined;
    offer: OfferInterface | undefined;
  }> = combineLatest([
    this.establishmentSnapshot$,
    this.offerFromEstablishmentSnapshot$,
  ]).pipe(
    map(([establishment, offer]) => ({ establishment, offer })),
    tap(async ({ establishment, offer }) => {
      if (!offer && !!establishment) {
        await this.router.navigate(['/establishments', establishment.id]);
      }

      if (!offer && !establishment) {
        await this.router.navigate(['/establishments']);
      }
    })
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly offerService: OfferService,
    private readonly establishmentService: EstablishmentService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly bookService: BookService,
    private readonly auth: Auth,
    private readonly firestore: Firestore,
    private readonly modalService: ModalService,
    private readonly translateService: TranslocoService
  ) {
    const now = new Date();
    this.bookForm = this.fb.group({
      nbPerson: new FormControl<number | undefined>(undefined, [
        Validators.required,
      ]),
      language: new FormControl<LanguageType | undefined>(undefined, [
        Validators.required,
      ]),
      date: new FormControl<TuiDay>(
        new TuiDay(now.getFullYear(), now.getMonth(), now.getDate()),
        [Validators.required]
      ),
      availability: new FormControl<AvailabilityInterface | undefined>(
        undefined,
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
    });

    this.bookForm.get('date')?.valueChanges.subscribe(async () => {
      await this.loadAvailabilities();
    });
  }

  async ngOnInit() {
    this.daysAvailable = await firstValueFrom(this.dateAvailable$);
    const establishmentAndOffer = await firstValueFrom(
      this.establishmentAndOfferSnapshot$
    );

    const { establishment, offer } = establishmentAndOffer;

    if (!establishment) {
      this.breadcrumb = [];
      return;
    }

    if (!offer) {
      this.breadcrumb = [];
      return;
    }

    this.breadcrumb = [
      {
        caption: `breadcrumb.our-domain`,
        routerLink: `/establishments`,
      },
      {
        caption: establishment.name,
        routerLink: `/establishments/${establishmentAndOffer.establishment?.id}`,
      },
      {
        caption: offer.name,
        routerLink: `/establishments/${establishmentAndOffer.establishment?.id}/offers/${establishmentAndOffer.offer?.id}`,
        routerLinkActiveOptions: { exact: true },
      },
    ];
    await this.loadAvailabilities();
  }

  async loadAvailabilities(): Promise<void> {
    this.availabilityTimeSlots = await firstValueFrom(
      this.route.params.pipe(
        switchMap(({ id, offerId }) => {
          if (!id || !offerId) {
            return of([]);
          }

          const date = this.bookForm.getRawValue()['date'] as TuiDay;

          return this.bookService
            .getAvailability({
              establishmentId: id,
              startDate: date.toUtcNativeDate().toUTCString(),
              offerId: offerId,
            })
            .pipe(
              tap((availabilities) => {
                if (!availabilities.length) {
                  this.bookForm.patchValue({ availability: undefined });
                  this.bookForm.get('availability')?.disable();
                } else {
                  this.bookForm.get('availability')?.enable();
                }
              })
            );
        })
      )
    );
  }

  createMapUrl(establishment: EstablishmentInterface | undefined) {
    if (!establishment) {
      return '';
    }

    const { latitude, longitude } = establishment.localization;
    return `https://www.google.com/maps/place/${latitude},${longitude}`;
  }

  getPhoto(establishmentAndOfferSnapshot: {
    establishment: EstablishmentInterface | undefined;
    offer: OfferInterface | undefined;
  }) {
    const { establishment } = establishmentAndOfferSnapshot;

    if (!establishment || !establishment.photos[0]) {
      return Constant.noImgUrl;
    }

    return establishment.photos[0].src;
  }

  getOfferPhoto(establishmentAndOfferSnapshot: {
    establishment: EstablishmentInterface | undefined;
    offer: OfferInterface | undefined;
  }) {
    const { offer } = establishmentAndOfferSnapshot;
    if (!offer || !offer.photo?.src) {
      return Constant.noImgUrl;
    }

    return offer.photo.src;
  }

  getNbPersonChoice(establishmentAndOfferSnapshot: {
    establishment: EstablishmentInterface | undefined;
    offer: OfferInterface | undefined;
  }) {
    const stop = establishmentAndOfferSnapshot.offer?.maxPeople;
    const start = establishmentAndOfferSnapshot.offer?.minPeople;
    const step = 1;

    if (!stop || !start) {
      return [];
    }

    return Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );
  }

  getLanguageChoice(establishmentAndOfferSnapshot: {
    establishment: EstablishmentInterface | undefined;
    offer: OfferInterface | undefined;
  }) {
    return establishmentAndOfferSnapshot.establishment?.languages || [];
  }

  getDateAvailability(establishmentAndOfferSnapshot: {
    establishment: EstablishmentInterface | undefined;
    offer: OfferInterface | undefined;
  }): Observable<AvailabilityInterface[]> {
    const values = this.bookForm.getRawValue();
    const date = values.date as Date;

    if (
      !establishmentAndOfferSnapshot.establishment ||
      !establishmentAndOfferSnapshot.offer
    ) {
      return of([]);
    }

    return this.bookService.getAvailability({
      establishmentId: establishmentAndOfferSnapshot.establishment?.id,
      startDate: date.toISOString(),
      offerId: establishmentAndOfferSnapshot.offer?.id || '',
    });
  }

  async bookVisit(establishmentAndOfferSnapshot: {
    establishment: EstablishmentInterface | undefined;
    offer: OfferInterface | undefined;
  }) {
    const { establishment, offer } = establishmentAndOfferSnapshot;
    if (this.bookForm.valid && !!establishment && !!offer) {
      const client = await firstValueFrom(this.client$);

      if (!client) {
        this.modalService.showLoginDialog();
        return;
      }

      this.isBooking = true;

      try {
        const values = this.bookForm.getRawValue();
        const nbPerson = values.nbPerson as number;
        const availability = values.availability as AvailabilityInterface;
        const language = values.language as LanguageType;

        console.log('offer.component Line 345:', availability);

        const successUrl =
          window.location.origin +
          this.router.serializeUrl(
            this.router.createUrlTree(['/visits'], {
              queryParams: { booking: 'success' },
            })
          );

        const requestBookingInterface: RequestBookingInterface = {
          offerId: offer?.id || '',
          comment: '',
          startDate: availability.startDate.toString(),
          endDate: availability.endDate.toString(),
          nbPerson,
          language,
          establishmentId: establishment.id,
          eventId: availability.eventId,
          userFirstName: 'Eddy',
          userLastName: 'test',
        };

        if (offer.price > 0) {
          const resultFromStripe = await firstValueFrom(
            this.bookService.bookViaStripe({
              ...requestBookingInterface,
              successUrl: successUrl,
              cancelUrl: window.location.origin + this.router.url,
            })
          );
          window.location.replace(resultFromStripe.url);
        } else {
          const bookFreeVisitInterface = await firstValueFrom(
            this.bookService.bookAvailability(requestBookingInterface)
          );
          if (bookFreeVisitInterface.status === 'CREATED') {
            await this.router.navigate(['visits'], {
              queryParams: { booking: 'success' },
            });
          } else {
            /* TODO display alert */
          }
        }
      } catch (e) {
        console.error('offer.component Line 368:', e);
      }
    }
  }

  getLanguage(languages: LanguageType[] | undefined): string {
    if (!languages) {
      return '';
    }

    return languages
      .map((elem) =>
        this.translateService.translate(`general.languages.${elem}`)
      )
      .join(', ');
  }

  displayDescription(description?: string) {
    if (!description) {
      return '';
    }

    return description.replace(/\r\n|\r|\n/g, '<br>');
  }

  openBookModal() {
    this.open = true;
  }
}
