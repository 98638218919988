import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { EstablishmentService } from '../../shared/services/establishment/establishment.service';
import { TuiBreadcrumbsModule } from '@taiga-ui/kit';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiLinkModule } from '@taiga-ui/core';
import { EstablishmentPresentationHeaderComponent } from './components/establishment-presentation-header/establishment-presentation-header.component';
import { EstablishmentPhotosComponent } from './components/establishment-photos/establishment-photos.component';
import { EstablishmentTabsComponent } from './components/establishment-tabs/establishment-tabs.component';
import { EstablishmentMapComponent } from './components/establishment-map/establishment-map.component';
import { OfferService } from '../../shared/services/offer/offer.service';
import { OfferInterface } from '../../shared/interfaces/offer.interface';
import { EstablishmentOffersComponent } from './components/establishment-offers/establishment-offers.component';

@Component({
  selector: 'rdw-establishment',
  standalone: true,
  imports: [
    CommonModule,
    TuiBreadcrumbsModule,
    TranslocoModule,
    TuiLinkModule,
    EstablishmentPresentationHeaderComponent,
    EstablishmentPhotosComponent,
    EstablishmentTabsComponent,
    EstablishmentMapComponent,
    EstablishmentOffersComponent,
  ],
  template: `
    <div class="pt-20">
      <div
        class="w-full px-4 md:px-28"
        *ngIf="
          establishmentSnapshot$ | async as establishmentSnapshot;
          else loading
        "
      >
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
          <ng-container *ngFor="let item of breadcrumbItems">
            <a
              class="ariane text-primary-dark"
              *tuiItem
              tuiLink
              [href]="item.routerLink"
            >
              {{ item.caption | transloco }}
            </a>
          </ng-container>
        </tui-breadcrumbs>

        <div class="mb-16">
          <rdw-establishment-presentation-header
            [establishment]="establishmentSnapshot"
          ></rdw-establishment-presentation-header>
        </div>

        <div class="mb-12">
          <rdw-establishment-photos
            [photos]="establishmentSnapshot.photos"
          ></rdw-establishment-photos>
        </div>

        <div class="mb-10">
          <rdw-establishment-tabs
            [establishmentId]="establishmentSnapshot.id"
          ></rdw-establishment-tabs>
        </div>

        <p
          [innerHTML]="displayDescription(establishmentSnapshot.description)"
          class="text-primary-dark body1 mb-10"
          id="establishment-description"
        ></p>

        <div class="mb-10">
          <rdw-establishment-offers
            [establishmentId]="establishmentSnapshot.id"
            *ngIf="offersFromEstablishmentSnapshot$ | async as offers"
            [offers]="offers"
          ></rdw-establishment-offers>
        </div>

        <div class="mb-20">
          <rdw-establishment-map
            [establishmentName]="establishmentSnapshot.name"
            [localization]="establishmentSnapshot.localization"
          ></rdw-establishment-map>
        </div>
      </div>
    </div>
    <ng-template #loading></ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstablishmentComponent {
  breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.our-domain`,
      routerLink: `/establishments`,
      routerLinkActiveOptions: { exact: false },
    },
  ];

  establishmentSnapshot$ = this.route.params.pipe(
    switchMap(({ id }) => {
      if (!id) {
        return of(undefined);
      }

      return this.establishmentService.findEstablishment(id).pipe(
        tap(async (establishment) => {
          if (!establishment) {
            await this.router.navigate(['/']);
          } else {
            this.breadcrumbItems.push({
              caption: establishment.name,
              routerLink: `establishments/${establishment.id}`,
              routerLinkActiveOptions: { exact: true },
            });
          }
        })
      );
    })
  );

  offersFromEstablishmentSnapshot$: Observable<OfferInterface[]> =
    this.route.params.pipe(
      switchMap(({ id }) => {
        if (!id) {
          return of([]);
        }

        return this.offerService.findOffersFromEstablishmentById(id);
      })
    );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly establishmentService: EstablishmentService,
    private readonly offerService: OfferService,
    private readonly router: Router
  ) {}

  displayDescription(description: string) {
    return description.replace(/\r\n|\r|\n/g, '<br>');
  }
}
