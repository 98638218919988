import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiLinkModule } from '@taiga-ui/core';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'rdw-footer',
  standalone: true,
  imports: [
    CommonModule,
    TuiLinkModule,
    RouterLink,
    TranslocoModule,
    IconComponent,
  ],
  template: `
    <footer
      class="flex flex-col w-full bg-primary-dark px-5 py-14 md:px-28 md:py-20"
    >
      <div class="flex flex-col md:flex-row md:justify-between">
        <div>
          <a tuiLink [href]="['/']">
            <rdw-icon iconName="rdwhite" className="fill-white"></rdw-icon>
            <span class="sr-only">Accueil</span>
          </a>
          <div class="flex flex-col mt-11 mb-7 gap-y-1 md:flex-row md:gap-x-4">
            <a
              class="body1 font-bold text-white"
              tuiLink
              [href]="item.path"
              *ngFor="let item of routingItems"
              >{{ prefixTranslation + item.name | transloco | uppercase }}</a
            >
          </div>
        </div>

        <div class="mb-8">
          <p class="body2 text-white">
            {{ prefixTranslation + 'join-community' | transloco }}
          </p>
          <div class="flex mt-3 gap-x-2">
            <a href="https://www.facebook.com/RendezWine" target="_blank">
              <rdw-icon iconName="fb"></rdw-icon>
              <span class="sr-only">Rendez Wine - Facebook</span>
            </a>
            <a href="https://www.instagram.com/rendez_wine/" target="_blank">
              <rdw-icon iconName="insta" className="fill-white"></rdw-icon>
              <span class="sr-only">Rendez Wine - Instagram</span>
            </a>
          </div>
        </div>
      </div>

      <hr class="h-px w-full border-white mb-3.5" />

      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="flex flex-wrap gap-x-5">
          <a
            class="body1 text-white"
            tuiLink
            [href]="item.path"
            *ngFor="let item of privacyItems"
            >{{ prefixTranslation + item.name | transloco }}</a
          >
        </div>

        <p class="body2 text-white mt-3.5 md:mt-0">
          {{ prefixTranslation + 'copyright' | transloco }}
        </p>
      </div>
    </footer>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  readonly prefixTranslation = 'footer.';

  routingItems = [
    { name: 'about', path: '/about' },
    { name: 'contact', path: '/contact' },
    { name: 'our-domain', path: '/establishments' },
    { name: 'faq', path: '/help' },
  ];

  privacyItems = [
    { name: 'cgu', path: '/cgu' },
    { name: 'cgs', path: '/cgs' },
    { name: 'privacy-policy', path: '/privacy-policy' },
    { name: 'refund-policy', path: '/refund-policy' },
  ];
}
