<h2 class="headline1 text-primary px-4 md:px-28">
    {{ prefixTranslation + 'title' | transloco }}
</h2>

<div class="grid grid-cols-1 grid-rows-5 md:grid-cols-4 md:grid-rows-2 mt-8 px-4 md:px-28 gap-x-3 gap-y-2 mb-10 md:[&>*:nth-child(3)]:col-span-2 md:[&>*:nth-child(3)]:row-span-2">

    <a *ngFor="let vineyard of vineyardLocationList" [queryParams]="{vineyardLocation: vineyard.name}"
       [routerLink]="['/establishments']"
       class="w-full rounded overflow-hidden aspect-[4/3] relative">

        <p class="absolute top-0 left-0 bg-black/40 w-full h-full flex justify-center items-center text-center px-4 text-white headline5 font-semibold">
            {{ 'general.vineyardLocation.' + vineyard.name | transloco }}
        </p>

        <img [src]="vineyard.photo" alt="" class="object-cover object-center w-full h-full">
    </a>

</div>
