import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { OfferInterface } from '../../interfaces/offer.interface';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(private readonly firestore: Firestore) {}

  findOffersFromEstablishmentById(id: string) {
    return collectionData(
      collection(this.firestore, `establishments/${id}/packs`),
      { idField: 'id' }
    ) as Observable<OfferInterface[]>;
  }

  findOfferFromEstablishmentById(id: string, offerId: string) {
    return docData(
      doc(this.firestore, `establishments/${id}/packs/${offerId}`),
      { idField: 'id' }
    ) as Observable<OfferInterface>;
  }
}
