import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-profile-desktop-content',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  template: `
    <h1 class="headline1 text-primary mb-3">
      {{ prefixTranslation + 'title' | transloco }}
    </h1>
    <p class="body1 text-primary-dark">
      {{ prefixTranslation + 'description' | transloco }}
    </p>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDesktopContentComponent {
  readonly prefixTranslation = 'profile.';
}
