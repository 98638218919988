import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'rdw-button',
  standalone: true,
  imports: [CommonModule],
  template: `
    <button
      [class.button-primary]="buttonType === 'primary'"
      [disabled]="isDisabled"
      class="px-8 py-4 text-center rounded-[10px] font-montserrat font-bold text-[16px] flex justify-center items-center"
      (click)="buttonClick.emit()"
    >
      <span [class.mr-2.5]="!!iconRight">{{ buttonText }}</span>
      <img
        [src]="'assets/icons/' + iconRight + '.svg'"
        alt="Rendez Wine icon"
        *ngIf="iconRight"
      />
    </button>
  `,
  styles: [
    '.button-primary {@apply bg-primary-dark text-white hover:bg-primary disabled:bg-primary-dark/50}',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() buttonText!: string;
  @Input() iconRight?: string;
  @Input() buttonType: 'primary' | 'outline' = 'primary';
  @Input() isDisabled = false;
  @Output() buttonClick = new EventEmitter<void>();
}
