import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstablishmentCardComponent } from '../../shared/components/establishment-card/establishment-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import {
  TuiBreadcrumbsModule,
  TuiDataListWrapperModule,
  TuiInputDateModule,
  TuiSelectModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { AllEstablishmentComponent } from '../home/components/all-establishment/all-establishment.component';
import { EstablishmentService } from '../../shared/services/establishment/establishment.service';
import { Meta } from '@angular/platform-browser';
import {
  listVineyardLocation,
  VineyardLocationEnum,
} from '../../shared/enums/vineyard-location.enum';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  firstValueFrom,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { TuiBooleanHandler, TuiDay, TuiLetModule } from '@taiga-ui/cdk';
import { EstablishmentInterface } from '../../shared/interfaces/establishment.interface';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EstablishmentMapComponent } from '../../shared/components/establishment-map/establishment-map.component';

@Component({
  selector: 'rdw-establishment-search',
  standalone: true,
  imports: [
    CommonModule,
    EstablishmentCardComponent,
    TranslocoModule,
    TuiBreadcrumbsModule,
    TuiButtonModule,
    TuiLinkModule,
    TuiToggleModule,
    AllEstablishmentComponent,
    TuiSelectModule,
    FormsModule,
    TuiLetModule,
    TuiDataListWrapperModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    TuiInputDateModule,
    TuiLoaderModule,
    EstablishmentMapComponent,
  ],
  templateUrl: './establishment-search.component.html',
  styleUrls: ['./establishment-search.component.scss'],
})
export class EstablishmentSearchComponent implements OnInit {
  breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.our-domain`,
      routerLink: `/establishments`,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  showMapControl = false;

  selectedDate?: TuiDay;
  selectedVineyardLocation?: VineyardLocationEnum;

  establishments$ = new BehaviorSubject<EstablishmentInterface[]>([]);
  daysAvailable: TuiDay[] = [];

  readonly prefixTranslation = 'establishments.';
  readonly listVineyardLocation = listVineyardLocation;

  isLoadingStartPage = true;
  isLoadingEstablishments = false;

  constructor(
    public readonly establishmentService: EstablishmentService,
    private readonly meta: Meta,
    private readonly route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.meta.addTag({
      name: 'description',
      content:
        'Rentrer en relation avec les viticulteurs bourguignons facilement et rapidement afin de vivre des expériences inédites et originales au cœur d’un des plus beaux vignobles au monde.',
    });

    this.daysAvailable = await firstValueFrom(
      this.establishmentService
        .establishmentAvailable()
        .pipe(map((item) => item.map((date) => TuiDay.fromUtcNativeDate(date))))
    );

    await firstValueFrom(
      this.route.queryParams.pipe(
        tap((value) => {
          this.selectedVineyardLocation = value['vineyardLocation'];
          this.selectedDate = value['date']
            ? TuiDay.fromUtcNativeDate(new Date(value['date']))
            : undefined;
        }),
        switchMap(() => this.loadEstablishment({}))
      )
    );

    this.isLoadingStartPage = false;
  }

  async loadEstablishment(value: {
    vineyardLocation?: VineyardLocationEnum;
    date?: TuiDay;
  }) {
    this.isLoadingEstablishments = true;
    const { vineyardLocation, date } = value;

    if (vineyardLocation === null) {
      this.selectedVineyardLocation = undefined;
    }

    if (date === null) {
      this.selectedDate = undefined;
    }

    this.establishments$.next(
      await firstValueFrom(
        this.establishmentService
          .searchEstablishment(
            date
              ? date.toUtcNativeDate()
              : this.selectedDate?.toUtcNativeDate(),
            vineyardLocation ? vineyardLocation : this.selectedVineyardLocation
          )
          .pipe(shareReplay())
      )
    );

    this.isLoadingEstablishments = false;
  }

  selectableDays: TuiBooleanHandler<TuiDay> = (date) => {
    return !this.daysAvailable.find((item) => {
      return item.daySame(date);
    });
  };
}
