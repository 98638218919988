<div class="flex pt-20">
    <div class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
            <ng-container *ngFor="let item of breadcrumbItems">
                <a
                        *tuiItem
                        [routerLink]="item.routerLink"
                        class="ariane text-primary-dark"
                        tuiLink
                >
                    {{ item.caption | transloco }}
                </a>
            </ng-container>
        </tui-breadcrumbs>

        <h1 class="headline1 text-primary mb-3">
            {{ prefixTranslation + 'title' | transloco }}
        </h1>

        <p class="text-sm italic">Dernière mise à jour: 13/02/2023</p>

        <p>
            La présente Charte de protection des données à caractère personnel décrit la façon dont nous collectons,
            utilisons, traitons et divulguons les informations vous concernant, y compris les informations personnelles,
            en lien avec votre accès et votre utilisation de la Plateforme Rendez Wine et des Services de paiement.
        </p>

        <p>
            Si vous rencontrez un terme sans définition dans la présente Charte de protection des données à caractère
            personnel (comme « Annonce » ou « Plateforme Rendez Wine »), ce terme aura la même définition que celle
            figurant dans nos Conditions de service.
        </p>

        <p>
            Lorsque cette charte fait mention des termes « Rendez Wine », « nous », « notre » ou « nos », ils font
            référence à la société Rendez Wine, laquelle est responsable des informations vous concernant, conformément
            à la présente Charte de protection des données à caractère personnel (le « Responsable de traitement »).
        </p>

        <p>
            Le Responsable de traitement est Rendez Wine.
        </p>

        <p>
            La présente Charte de protection des données à caractère personnel s’applique également aux Services de
            paiement qui vous sont fournis par Rendez Wine conformément aux Conditions de service relatives aux
            Paiements (« Conditions de paiement »). Ces services de paiement sont : STRIPE
        </p>

        <h2>
            1. Informations Collectées
        </h2>

        <p>
            Nous collectons trois catégories générales d’informations.
        </p>

        <h3>
            1.1 Informations que vous nous communiquez.
        </h3>

        <h4>
            1.1.1 Les informations qui sont nécessaires à l’utilisation de la Plateforme Rendez Wine.
        </h4>

        <p>
            Nous demandons et recueillons les informations personnelles suivantes vous concernant lorsque vous utilisez
            la Plateforme Rendez Wine. Ces informations sont nécessaires pour la bonne exécution de l’accord entre vous
            et nous et pour nous permettre de respecter nos obligations légales. Sans elles, nous pourrions ne pas être
            en mesure de vous fournir tous les services demandés.
        </p>

        <p>
            Informations de compte. Lors de la création de votre Compte Rendez Wine, nous vous demandons de nous
            communiquer certaines informations comme vos prénom, nom, adresse e-mail et numéro de téléphone.
        </p>

        <p>
            Informations de profil et d’Annonce. Afin d’utiliser certaines fonctionnalités de la Plateforme Rendez Wine
            (telles que la réservation ou la création d’une Annonce), nous pouvons vous demander de fournir des
            informations supplémentaires pouvant comprendre votre adresse, votre numéro de téléphone et une photo de
            profil.
        </p>

        <p>
            Informations de vérification d’identité. Pour contribuer à la création et au maintien d’un environnement de
            confiance, nous pouvons collecter des informations relatives à la vérification d’identité (comme des copies
            de votre pièce d’identité officielle, de votre passeport, de votre carte d’identité nationale ou de votre
            permis de conduire, dans la mesure autorisée par les lois applicables) ou d’autres informations
            d’authentification.
        </p>

        <p>
            Informations de paiement. Pour utiliser certaines fonctionnalités de la Plateforme Rendez Wine (telles que
            la réservation ou la création d’une Annonce), nous pouvons vous demander de fournir certaines informations
            financières (comme vos informations de compte bancaire ou de carte de crédit) en vue de faciliter le
            traitement des paiements (via Rendez Wine et STRIPE).
        </p>

        <p>
            Communications avec Rendez Wine. Lorsque vous communiquez avec Rendez Wine, nous collectons des informations
            concernant vos communications et toute information que vous décidez de transmettre.
        </p>

        <h4>
            1.1.2 Les informations que vous choisissez de nous donner.
        </h4>

        <p>
            Vous pouvez choisir de nous fournir des informations personnelles supplémentaires, afin d’obtenir une
            meilleure expérience utilisateur lorsque vous vous servez de la Plateforme Rendez Wine. Ces informations
            supplémentaires seront traitées dès lors que vous y aurez consenti.
        </p>

        <p>
            Informations de profil supplémentaires. Vous pouvez choisir de fournir des informations supplémentaires dans
            le cadre de votre profil Rendez Wine (comme le sexe, la/les langue(s) préférée(s), la ville et une
            description personnelle). Certaines de ces informations, comme indiqué dans les paramètres de votre Compte,
            font partie de votre page de profil public et seront visibles par d’autres.
        </p>

        <p>
            Autres informations. Autrement, vous pouvez choisir de nous communiquer des informations lorsque vous
            remplissez un formulaire, menez une recherche, mettez à jour ou ajoutez des informations à votre Compte
            Rendez Wine, répondez à des enquêtes, publiez sur les forums de discussion, participez à des opérations de
            promotion ou utilisez d’autres fonctionnalités de la Plateforme Rendez Wine.
        </p>

        <h4>
            1.1.3 Informations nécessaires à l’utilisation des Services de paiement.
        </h4>

        <p>
            Le Responsable de traitement des paiements doit recueillir les informations suivantes, car elles sont
            nécessaires pour la bonne exécution de l’accord entre vous et nous et pour se conformer à la loi applicable
            (telle que les réglementations pour la lutte contre le blanchiment d’argent). Sans elles, vous ne pourrez
            pas utiliser les Services de paiement :
        </p>

        <p>
            Informations de paiement. Lorsque vous utilisez les Services de paiement, le Responsable de traitement des
            paiements exige certaines informations financières (comme vos informations de compte bancaire ou de carte de
            crédit) afin de traiter les paiements et de se conformer à la loi applicable.
        </p>

        <p>
            Vérification d’identité et autres informations. Si vous êtes un Domaine, le Responsable de traitement des
            paiements peut exiger des informations de vérification d’identité (comme des copies de votre pièce
            d’identité officielle, de votre passeport, de votre carte d’identité nationale ou de votre permis de
            conduire) ou d’autres informations d’authentification, comme vos date de naissance, adresse, adresse e-mail,
            numéro de téléphone et autres renseignements, afin de vérifier votre identité, de vous offrir des Services
            de paiement et de se conformer à la loi applicable.
        </p>

        <h3>
            1.2 Informations que nous collectons automatiquement lors de votre utilisation de la Plateforme Rendez Wine
            et des Services de paiement (STRIPE).
        </h3>

        <p>
            Lorsque vous utilisez la Plateforme Rendez Wine et les Services de paiement, nous recueillons
            automatiquement des renseignements, y compris des informations personnelles, sur les services que vous
            utilisez et la façon dont vous les utilisez. Ces informations sont nécessaires pour la bonne exécution de
            l’accord entre vous et nous, pour que nous nous conformions aux obligations légales et compte tenu de notre
            intérêt légitime à être en mesure de fournir et d’améliorer les fonctionnalités de la Plateforme Rendez Wine
            et des Services de paiement.
        </p>

        <p>
            Informations de géolocalisation. Lors de votre utilisation de certaines fonctionnalités de la Plateforme
            Rendez Wine, nous pouvons collecter des informations sur votre emplacement approximatif ou précis, comme
            déterminé par des données telles que votre adresse IP ou par le GPS de votre appareil mobile, afin de vous
            offrir une meilleure expérience d’utilisateur. La majorité des appareils mobiles vous permet de contrôler ou
            de désactiver l’utilisation des services de localisation dans les applications à partir du menu des
            paramètres de l’appareil. Rendez Wine peut également recueillir ces informations, même si vous n’utilisez
            pas l’application, si cette connexion est activée dans les paramètres ou les autorisations de votre
            appareil.
        </p>

        <p>
            Informations d’utilisation. Nous recueillons des informations concernant vos interactions avec la Plateforme
            Rendez Wine, comme vos consultations de pages ou de contenu, vos recherches d’Annonces, les réservations que
            vous avez effectuées et d’autres actions sur la Plateforme Rendez Wine.
        </p>

        <p>
            Données de connexion et d’appareil. Nous collectons automatiquement les données de connexion et d’appareil
            lorsque vous accédez à et utilisez la Plateforme Rendez Wine, même si vous n’avez pas créé de Compte Rendez
            Wine ou n’êtes pas connecté. Ces informations comprennent, entre autres : des informations sur la façon dont
            vous avez utilisé la Plateforme Rendez Wine (y compris si vous avez cliqué sur des liens vers des
            applications de tiers), l’adresse IP, les dates et les heures d’accès, les données sur le matériel et le
            logiciel informatiques, les données sur l’appareil, les données associées à l’utilisation de l’appareil, les
            identifiants uniques, les données de plantage, les cookies et les pages que vous avez visualisées ou
            affichées avant ou après avoir utilisé notre Plateforme.
        </p>

        <p>
            Cookies et technologies analogues. Nous utilisons des cookies ou d’autres technologies analogues, comme les
            balises web, pixels et identifiants d’équipement mobile. Nous pouvons également autoriser nos partenaires
            commerciaux à utiliser ces technologies de traçage sur la Plateforme Rendez Wine ou souscrire les services
            de tiers pour procéder au traçage de votre comportement pour notre compte. Même si vous avez la possibilité
            de désactiver les cookies à partir des paramètres de votre navigateur, la Plateforme Rendez Wine ne modifie
            pas ses pratiques en réponse à un signal « Ne pas suivre » dans l’entête HTTP de votre navigateur ou
            application mobile, en raison d’un manque de normalisation concernant la manière dont ce signal doit être
            interprété. Pour obtenir plus d’informations sur l’utilisation que nous faisons de ces technologies,
            veuillez consulter notre Politique relative aux cookies.
        </p>

        <p>
            Informations sur les transactions de paiement. Rendez Wine recueille des renseignements concernant vos
            transactions de paiement sur la Plateforme Rendez Wine, y compris le moyen de paiement utilisé, la date et
            l’heure, le montant du paiement, la date d’expiration du moyen de paiement, le code postal de la
            facturation, l’adresse e-mail PayPal, les informations IBAN, votre adresse et d’autres informations de
            transaction associées. Ces informations sont nécessaires pour la bonne exécution de l’accord entre vous et
            nous et pour nous permettre de mettre à disposition les Services de paiement, via STRIPE.
        </p>

        <h3>
            1.3 Informations que nous collectons auprès de tiers.
        </h3>

        <p>
            Rendez Wine peuvent collecter des informations, y compris des informations personnelles, fournies par des
            tiers vous concernant lors de leur utilisation de la Plateforme Rendez Wine et des Services de paiement, ou
            obtenir des informations auprès d’autres sources et les combiner avec celles que nous collectons sur la
            Plateforme Rendez Wine et aux Services de paiement. Nous ne contrôlons pas, ne supervisons pas ou ne
            répondons pas de la façon dont les tiers fournissant vos informations traitent vos données personnelles. De
            plus, toute demande d’informations concernant la divulgation de vos informations personnelles à notre
            entreprise doit être adressée à ces tiers.
        </p>

        <p>
            Services de tiers. Si vous liez, associez ou connectez votre Compte Rendez Wine à un Service fourni par un
            tiers (par ex. Google, Facebook, WeChat), le service fourni par le tiers peut nous envoyer des informations,
            comme par exemple les informations relatives à votre inscription et à votre profil du service en question.
            Ces informations varient et sont contrôlées par le service du tiers ou dépendent des autorisations que vous
            avez transmises à ce service via les paramètres de confidentialité y afférent.
        </p>

        <p>
            Autres sources. Nous pouvons recevoir des informations vous concernant et concernant vos activités sur et en
            dehors de la Plateforme Rendez Wine au travers de partenariats, ou concernant vos expériences et
            interactions de la part de nos réseaux partenaires de publicité.
        </p>

        <h2>
            2. Utilisation des informations que nous collectons
        </h2>

        <p>
            Nous utilisons, stockons et traitons les informations, y compris des informations personnelles vous
            concernant, afin d’alimenter, comprendre, améliorer et développer la Plateforme Rendez Wine, pour créer et
            maintenir un environnement de confiance mieux sécurisé, ainsi que pour nous conformer à nos obligations
            légales.
        </p>

        <h3>
            2.1 Alimenter, améliorer et développer la Plateforme Rendez Wine.
        </h3>

        <p>
            Vous permettre d’accéder à et d’utiliser la Plateforme Rendez Wine.
        </p>

        <p>
            Gérer, protéger, améliorer et optimiser la Plateforme et l’expérience Rendez Wine, en réalisant des analyses
            et des études par exemple.
        </p>

        <p>
            Fournir un service client.
        </p>

        <p>
            Vous adresser des messages d’assistance ou relatifs aux services, des mises à jour, des alertes de sécurité
            et des notifications de Compte.
        </p>

        <p>
            Si vous nous communiquez les coordonnées de vos contacts, nous pouvons traiter ces informations : à toutes
            fins autorisées par vos soins au moment de la collecte des données.
        </p>

        <p>
            Pour gérer, protéger, améliorer et optimiser la Plateforme et l’expérience Rendez Wine, ainsi que pour
            personnaliser et adapter votre expérience (par exemple en faisant des suggestions d’Annonce ou en classant
            des résultats de recherche), nous effectuons un profilage en fonction de vos interactions avec la Plateforme
            Rendez Wine, de votre historique de recherches et de réservations, de vos informations de profil et
            préférences, ainsi que d’autres contenus que vous transmettez à la Plateforme Rendez Wine.
        </p>

        <p>
            Nous traitons ces informations étant donné notre intérêt légitime à améliorer la Plateforme Rendez Wine et
            l’expérience de nos Membres avec elle, et lorsque ces informations sont nécessaires à la bonne exécution de
            l’accord avec vous.
        </p>

        <h3>
            2.2 Créer et maintenir un environnement de confiance mieux sécurisé.
        </h3>

        <p>
            Détecter et prévenir les fraudes, spams, abus, incidents de sécurité et autres activités préjudiciables.
        </p>

        <p>
            Mener des enquêtes de sécurité et des évaluations de risque.
        </p>

        <p>
            Vérifier ou authentifier les informations ou identifications transmises par vos soins (par exemple, vérifier
            l’adresse de votre Hébergement ou comparer la photo d’identification avec une autre photo transmise par
            vous).
        </p>

        <p>
            Procéder à des vérifications avec des bases de données ou d’autres sources d’information, y compris à une
            vérification policière ou des antécédents, dans la mesure permise par les lois applicables et avec votre
            consentement, le cas échéant.
        </p>

        <p>
            Respecter nos obligations légales.
        </p>

        <p>
            Résoudre tous litiges que nous pourrions avoir avec nos Membres et faire valoir nos contrats avec les tiers.
        </p>

        <p>
            Faire appliquer nos Conditions de service ainsi que nos autres politiques.
        </p>

        <p>
            En relation avec les activités ci-dessus, nous pouvons effectuer un profilage en fonction de vos
            interactions avec la Plateforme Rendez Wine, de vos informations de profil et d’autres contenus que vous
            transmettez à la Plateforme Rendez Wine, ainsi que des informations obtenues auprès de tiers. Dans des cas
            limités, les processus automatisés peuvent restreindre ou suspendre l’accès à la Plateforme Rendez Wine si
            de tels processus détectent un Membre ou une activité qui, selon nous, représente un risque de sécurité ou
            un autre type de risque pour la Plateforme Rendez Wine, d’autres Membres ou des tiers.
        </p>

        <p>
            Nous traitons ces informations étant donné notre intérêt légitime à protéger la Plateforme Rendez Wine, pour
            mesurer la bonne exécution de notre accord avec vous et pour nous conformer aux lois applicables.
        </p>

        <h3>
            2.3 Fournir, Personnaliser, Évaluer et Améliorer notre Publicité et la Commercialisation de nos services.
        </h3>

        <p>
            Vous envoyer des messages promotionnels, des informations commerciales, publicitaires et autres qui peuvent
            vous intéresser en fonction de vos préférences (y compris des informations sur Rendez Wine ou concernant les
            campagnes et services de nos partenaires) et des publicités de réseaux sociaux via les plateformes de
            réseaux sociaux, telles que Facebook ou Google.
        </p>

        <p>
            Personnaliser, évaluer et améliorer notre publicité.
        </p>

        <p>
            Gérer les programmes de parrainage, récompenses, enquêtes, loteries, concours, ou autres activités ou
            événements promotionnels soutenus ou gérés par Rendez Wine ou ses partenaires commerciaux.
        </p>

        <p>
            Effectuer un profilage sur vos caractéristiques et préférences (en fonction des informations que vous nous
            communiquez, de vos interactions avec la Plateforme Rendez Wine, des informations obtenues auprès de tiers,
            ainsi que de votre historique de recherches et de réservations) pour vous envoyer des messages
            promotionnels, des informations commerciales, publicitaires et autres qui, selon nous, peuvent vous
            intéresser.
        </p>

        <p>
            Nous traiterons vos informations personnelles aux fins énumérées dans cette section, étant donné notre
            intérêt légitime à entreprendre des activités marketing pour vous proposer des produits ou services qui
            peuvent vous intéresser. Vous pouvez choisir de ne plus recevoir de communications marketing de notre part
            en suivant les instructions de désabonnement incluses dans nos communications marketing ou en modifiant vos
            paramètres de notification sur votre Compte Rendez Wine.
        </p>

        <h3>
            2.4 Utilisation par le Responsable de traitement des paiements des informations recueillies.
        </h3>

        <p>
            Vous permettre d’accéder aux et d’utiliser les Services de paiement.
        </p>

        <p>
            Détecter et prévenir les fraudes, abus, incidents de sécurité et autres activités préjudiciables.
        </p>

        <p>
            Mener des enquêtes de sécurité et des évaluations de risque.
        </p>

        <p>
            Effectuer des contrôles à l’égard des bases de données et d’autres sources d’informations.
        </p>

        <p>
            Se conformer aux obligations légales (telles que les réglementations pour la lutte contre le blanchiment
            d’argent).
        </p>

        <p>
            Appliquer les Conditions de paiement et autres politiques de paiement.
        </p>

        <p>
            Avec votre consentement, vous envoyer des messages promotionnels, des informations commerciales,
            publicitaires et autres qui peuvent vous intéresser, en fonction de vos préférences.
        </p>

        <p>
            Le Responsable de traitement des paiements gère ces informations, étant donné son intérêt légitime à
            améliorer les Services de paiement et l’expérience de ses utilisateurs avec ces services, si ces
            informations sont nécessaires à la bonne exécution de l’accord avec vous et pour se conformer aux lois
            applicables.
        </p>

        <h2>
            3. Partage et Divulgation
        </h2>

        <h3>
            3.1 Avec votre consentement.
        </h3>

        <p>
            Lorsque vous avez donné votre consentement, nous pouvons partager les informations vous concernant, y
            compris des informations personnelles, tel que décrit au moment de votre consentement, comme lorsque vous
            autorisez une application ou un site Web d’un tiers à accéder à votre Compte Rendez Wine ou lorsque vous
            participez à des activités promotionnelles menées par des partenaires de Rendez Wine ou des tiers.
        </p>

        <h3>
            3.2 Partage entre Membres.
        </h3>

        <p>
            Dans le but de faciliter les réservations, ou d’autres interactions entre Membres, nous pouvons avoir besoin
            de partager certaines informations, y compris des informations personnelles, avec d’autres Membres, lorsque
            cela est nécessaire pour la bonne exécution de l’accord entre vous et nous, comme suit :
        </p>

        <p>
            Lorsque vous envoyez une demande de réservation en qualité d’Utilisateur, certaines informations vous
            concernant sont partagées avec le Domaine, notamment votre nom complet, le nom complet de tout Utilisateur
            supplémentaire, l’historique de vos annulations et toutes autres informations que vous acceptez de partager.
            Une fois votre réservation confirmée, nous divulguerons des informations supplémentaires, telles que votre
            numéro de téléphone (et email le cas échéant), dans le but d’aider à la coordination de l’expérience.
        </p>

        <p>
            Lorsque vous avez confirmé une réservation en qualité de Domaine, certaines informations sont partagées avec
            l’Utilisateur pour finaliser la réservation, comme votre nom complet, votre numéro de téléphone et l’adresse
            de votre Expérience.
        </p>

        <p>
            Lorsqu’en qualité d’Utilisateur vous invitez d’autres Utilisateurs à une réservation, votre nom complet, les
            dates du voyage, le nom du Domaine, les détails de l’Annonce, l’adresse de l’Hébergement et d’autres
            informations associées seront partagées avec chaque Utilisateur supplémentaire.
        </p>

        <p>
            Lorsqu’en qualité d’Utilisateur vous déposez une Demande de réservation par paiement de groupe, certaines
            informations à propos de chaque participant comme le prénom, l’initiale du nom, la photo de profil ainsi que
            les informations de réservations sont partagées entre tous les participants de la Demande de réservation par
            paiement de groupe.
        </p>

        <p>
            Nous ne communiquons pas vos données de facturation ou de paiement à d’autres Membres.
        </p>

        <h3>
            3.3 Profils, Annonces et autres Informations Publiques.
        </h3>

        <p>
            La Plateforme Rendez Wine vous permet de publier des informations, y compris des informations personnelles,
            accessibles au public. Par exemple :
        </p>

        <p>
            Des parties de votre page de profil public, comme votre prénom, votre description et votre ville, sont
            publiquement accessibles.
        </p>

        <p>
            Les pages d’Annonce peuvent être vues par le public et comprennent des informations comme l’emplacement
            approximatif (quartier et ville) ou l’emplacement précis (si vous avez donné votre consentement) de
            l’Hébergement ou de l’Expérience, la description de l’Annonce, le calendrier de disponibilité, votre photo
            de profil public, les informations agrégées sur la demande (telles que le nombre de consultations de la page
            sur une période donnée), ainsi que toutes informations complémentaires que vous choisissez de partager.
        </p>

        <p>
            Si vous communiquez un contenu dans un forum communautaire ou de discussion, postez un contenu sur un blog
            ou un réseau social, ou utilisez une fonctionnalité similaire sur la Plateforme Rendez Wine, ce contenu est
            rendu public.
        </p>

        <p>
            Étant donné notre intérêt légitime à promouvoir la Plateforme Rendez Wine, nous pouvons afficher des parties
            de la Plateforme Rendez Wine (par exemple, votre page d’Annonce) sur des sites exploités par des partenaires
            commerciaux de Rendez Wine, à l’aide de technologies telles que des widgets ou API. Si vos Annonces sont
            affichées sur le site d’un partenaire, les informations de votre page de profil public peuvent également
            être affichées.
        </p>

        <p>
            Les informations que vous partagez publiquement sur la Plateforme Rendez Wine peuvent être indexées via des
            moteurs de recherche de tiers. Dans certains cas, il vous est possible de désactiver cette fonctionnalité
            dans vos paramètres (dans la rubrique « Compte »). Si vous modifiez vos paramètres ou votre contenu
            accessible au public, il est possible que ces moteurs de recherche ne puissent pas mettre à jour leurs bases
            de données. Nous n’avons aucun contrôle sur les pratiques des moteurs de recherche tiers, lesquels peuvent
            utiliser des mémoires cache contenant des informations qui ne sont plus à jour sur vous.
        </p>

        <h3>
            3.4 Services additionnels fournis par les Domaines.
        </h3>

        <p>
            Les Domaines peuvent avoir besoin d’utiliser des services tiers disponibles par le biais de la Plateforme
            Rendez Wine pour les aider dans la gestion de leur prestation de services supplémentaires que vous avez
            requis. Les Domaines peuvent utiliser les fonctionnalités de la Plateforme Rendez Wine pour partager des
            informations concernant l’Utilisateur (notamment les dates d’arrivée et de départ, le nom de l’Utilisateur
            et son numéro de téléphone) avec ces prestataires de services tiers aux fins de coordonner le séjour, de
            gérer l’Hébergement ou de fournir d’autres services.
        </p>

        <h3>
            3.5 Conformité avec la loi, réponse aux demandes légales, prévention des préjudices et protection de nos
            droits.
        </h3>

        <p>
            Rendez Wine peuvent divulguer vos informations, y compris vos informations personnelles, aux tribunaux,
            autorités gouvernementales ou chargées de l’application de la loi ou à des tiers autorisés, si la loi
            l’exige ou le permet, ou si une telle divulgation est raisonnablement jugée nécessaire : pour respecter nos
            obligations légales, afin de se conformer au processus judiciaire et de donner suite aux réclamations
            présentées contre Rendez Wine, pour répondre à des demandes vérifiées dans le cadre d’une enquête judiciaire
            ou d’une activité illégale prétendue ou soupçonnée ou de toute autre activité qui peut nous exposer, vous
            exposer ou exposer nos utilisateurs à une responsabilité légale, aux fins d’exécuter et d’administrer nos
            Conditions de service, les Conditions de paiement ou d’autres accords passés avec les Membres, ou pour
            protéger les droits, les biens ou la sécurité personnelle de Rendez Wine, de ses employés, de ses Membres ou
            du public.
        </p>

        <p>
            Ces divulgations peuvent être nécessaires aux fins de satisfaire à nos obligations légales, de protection de
            vos intérêts vitaux ou de ceux d’une autre personne ou aux fins de nos intérêts légitimes ou de ceux d’un
            tiers visant à maintenir la sécurité de la plateforme Rendez Wine, à prévenir tout préjudice ou délit, à
            faire valoir ou défendre des droits légaux ou à empêcher tous dommages.
        </p>

        <p>
            Si nécessaire, nous pouvons informer les Membres de ces demandes légales, excepté dans les cas suivants :
            lorsque toute notification est interdite par la procédure judiciaire elle-même, par ordonnance du tribunal
            rendue à notre effet ou conformément à la loi en vigueur, ou si nous estimons que le fait de vous informer
            serait sans intérêt, inefficace, pourrait constituer un risque de blessure ou de préjudice corporel à un
            individu ou un groupe ou créer ou intensifier un risque de fraude concernant les biens de Rendez Wine, ses
            Membres et la Plateforme Rendez Wine. Dans les cas où nous nous conformons aux exigences légales sans
            préavis pour les raisons susmentionnées, nous essaierons d’informer a posteriori le Membre concerné de la
            demande, si nécessaire et si nous estimons en toute bonne foi que nous ne sommes plus légalement tenus de ne
            pas le faire.
        </p>

        <h3>
            3.6 Prestataires de services.
        </h3>

        <p>
            Rendez Wine recourent à divers prestataires de services tiers afin de nous aider à fournir les services en
            lien avec la Plateforme Rendez Wine et les Services de paiement, notamment STRIPE.
        </p>

        <p>
            À titre d’exemple, les prestataires de services peuvent nous aider à : vérifier des informations par rapport
            à des bases de données publiques, effectuer des vérifications d’antécédents ou policières, mettre en œuvre
            une prévention des fraudes et une évaluation des risques, réaliser le développement, l’entretien et le
            débogage de produits, permettre la prestation des Services Rendez Wine via des plateformes de tiers et des
            outils logiciels (par ex. par le biais de l’intégration avec nos API), ou fournir un service client, des
            services publicitaires ou de paiement. Ces prestataires de services disposent d’un accès limité à vos
            informations dans le cadre de l’exécution de ces tâches pour notre compte et ont une obligation
            contractuelle de les protéger et de les utiliser aux seules fins pour lesquelles elles ont été divulguées et
            en conformité avec la présente Charte de protection des données à caractère personnel.
        </p>

        <p>
            Rendez Wine devra partager vos informations, y compris vos informations personnelles, afin d’assurer la
            bonne exécution de notre accord avec vous.
        </p>

        <h3>
            3.7 Plateformes de réseaux sociaux.
        </h3>

        <p>
            Lorsque cela est possible en vertu de la loi applicable, nous pouvons utiliser certaines informations
            personnelles limitées vous concernant, telles que votre adresse e-mail, pour les “hacher” et les partager
            avec les plateformes de réseaux sociaux, telles que Facebook ou Instagram, Google, afin de générer des
            clients potentiels, stimuler du trafic vers nos sites Web ou autrement promouvoir nos produits et services
            ou la Plateforme Rendez Wine. Ces activités de traitement sont basées sur notre intérêt légitime à
            entreprendre des activités marketing pour vous proposer des produits ou services qui pourraient vous
            intéresser.
        </p>

        <p>
            Les plateformes de réseaux sociaux avec lesquelles nous pouvons partager vos données personnelles ne sont ni
            contrôlées ni surveillées par Rendez Wine. Par conséquent, toute question concernant la façon dont votre
            prestataire de services de plateforme de réseau social traite vos données personnelles doit être adressée
            audit prestataire.
        </p>

        <p>
            Veuillez noter que vous pouvez à tout moment demander à Rendez Wine de cesser de traiter vos données à ces
            fins de marketing direct en envoyant un e-mail à rendez.wine@gmail.com
        </p>

        <h3>
            3.13 Cessions d’entreprise.
        </h3>

        <p>
            Si Rendez Wine entreprend ou est impliquée dans une fusion, acquisition, restructuration, cession d’actifs
            ou procédure de faillite ou d’insolvabilité, elle pourra vendre, céder ou partager tout ou partie de ses
            actifs, y compris vos informations en lien avec ladite transaction ou en prévision de ladite transaction
            (par ex., vigilance à l’égard du client). Dans ce cas, nous vous informerons avant que vos données à
            caractère personnel ne soient transférées et ne soient régies par une autre charte de protection des données
            à caractère personnel.
        </p>

        <h3>
            3.14 Données agrégées.
        </h3>

        <p>
            Nous pouvons également partager des informations agrégées (informations concernant nos utilisateurs que nous
            combinons de façon à ce qu’elles ne puissent plus identifier ou référencer un utilisateur individuel) et
            d’autres informations anonymisées à des fins de conformité aux réglementations, d’analyse du secteur et du
            marché, de profilage démographique, pour des raisons commerciales et publicitaires et autres finalités
            professionnelles.
        </p>

        <h2>
            4. Autres informations importantes
        </h2>

        <h3>
            4.5 Stripe.
        </h3>

        <p>
            Certaines parties de la plateforme Rendez Wine utilisent les services de paiement Strupe, y compris les API
            Stripe. L’utilisation de Stripe est soumise aux Conditions d’utilisation de Stripe et à la Politique de
            confidentialité de Stripe
        </p>

        <h2>
            5. Partenaires tiers et Intégrations
        </h2>

        <p>
            La Plateforme Rendez Wine peut contenir des liens vers des services ou sites Web tiers, tels que les
            intégrations de tiers, les services en partage de marques, ou les services de marque tierce (« Partenaires
            tiers »). Rendez Wine ne détient pas ni ne contrôle ces Partenaires tiers et lors de vos interactions avec
            ces derniers, vous pouvez soit fournir des informations directement au Partenaire tiers, soit à Rendez Wine
            ou aux deux. Ces Partenaires tiers auront leurs propres règles en matière de collecte, d’utilisation et de
            divulgation des informations. Nous vous encourageons à lire les politiques de confidentialité des autres
            sites Web que vous consultez.
        </p>

        <h2>
            6. Vos Droits
        </h2>

        <p>
            Vous pouvez exercer tout droit décrit dans cette section auprès de votre Responsable de traitement et de
            votre Responsable de traitement des paiements Rendez Wine désignés comme compétents par la présente Charte
            de protection des données à caractère personnel, en envoyant un e-mail à rendez.wine@gmail.com. Veuillez
            noter que nous pouvons vous demander de vérifier votre identité avant de prendre d’autres mesures concernant
            votre demande.
        </p>

        <h3>
            6.1 Gestion de vos informations.
        </h3>

        <p>
            Vous pouvez accéder à et mettre à jour certaines de vos informations dans les paramètres de votre Compte. Si
            vous avez choisi de connecter votre Compte Rendez Wine à une application tierce, comme Facebook ou Google,
            vous pouvez modifier vos paramètres et retirer l’autorisation pour l’application en changeant les paramètres
            de votre Compte. Vous êtes responsable de garder à jour vos informations personnelles.
        </p>

        <h3>
            6.2 Rectification d’informations inexactes ou incomplètes.
        </h3>

        <p>
            Vous avez le droit de nous demander de corriger des informations personnelles inexactes ou incomplètes vous
            concernant (et que vous ne pouvez pas mettre à jour vous-même sur votre Compte Rendez Wine).
        </p>

        <h3>
            6.3 Accès aux données et portabilité.
        </h3>

        <p>
            Dans certaines juridictions, la loi applicable peut vous autoriser à demander des copies de vos informations
            personnelles que nous détenons. Vous pouvez également être autorisé à demander des copies des informations
            personnelles que vous nous avez fournies dans un format structuré, couramment utilisé et lisible à la
            machine et/ou nous demander de transmettre ces informations à un autre prestataire de services (lorsque cela
            est techniquement possible).
        </p>

        <h3>
            6.4 Conservation et suppression des données.
        </h3>

        <p>
            En général, nous conservons vos informations personnelles aussi longtemps que nécessaire pour l’exécution de
            l’accord entre vous et nous et pour nous conformer à nos obligations légales. Si vous ne souhaitez plus que
            nous utilisions vos informations pour vous fournir les services de la Plateforme Rendez Wine, vous pouvez
            nous demander de supprimer vos informations personnelles et de fermer votre Compte Rendez Wine. Veuillez
            noter que si vous demandez la suppression de vos informations personnelles :
        </p>

        <p>
            Nous pouvons conserver certaines de vos informations personnelles nécessaires pour nos intérêts commerciaux
            légitimes, tels que la détection et la prévention de la fraude et l’amélioration de la sécurité. Par
            exemple, si nous suspendons un Compte Rendez Wine pour des raisons de fraude ou de sécurité, nous pouvons
            conserver certaines informations de ce Compte Rendez Wine pour empêcher ce Membre d’ouvrir un nouveau Compte
            Rendez Wine à l’avenir.
        </p>

        <p>
            Nous pouvons conserver et utiliser vos informations personnelles dans la mesure nécessaire pour nous
            conformer à nos obligations légales. Par exemple, Rendez Wine et Rendez Wine peuvent conserver certaines de
            vos informations pour des obligations fiscales, de rapports juridiques et de vérification.
        </p>

        <p>
            Les informations que vous avez partagées avec d’autres personnes (par ex., commentaires, publications sur un
            forum) peuvent continuer à être publiques sur la Plateforme Rendez Wine, même après la suppression de votre
            Compte Rendez Wine. Cependant, ces informations ne vous seront plus attribuées. En outre, quelques copies de
            vos informations (par ex., les journaux) peuvent rester dans notre base de données, mais sont dissociées des
            identifiants personnels.
        </p>

        <p>
            Parce que nous entretenons la Plateforme Rendez Wine pour la protéger de toute perte accidentelle ou
            malveillante et de toute destruction, des copies résiduelles de vos informations personnelles peuvent ne pas
            être supprimées de nos systèmes de sauvegarde pendant une période de temps limitée.
        </p>

        <h3>
            6.5 Retrait du consentement et limitation du traitement.
        </h3>

        <p>
            Si vous avez donné votre consentement au traitement de vos informations personnelles par Rendez Wine, vous
            pouvez retirer votre consentement à tout moment en modifiant les paramètres de votre Compte ou en envoyant
            une communication à Rendez Wine en spécifiant le consentement que vous retirez. Veuillez noter que le
            retrait de votre consentement n’a pas d’effet sur la légalité de toute activité de traitement fondée sur
            ledit consentement avant son retrait. De plus, dans certaines juridictions, la loi applicable peut vous
            donner le droit de limiter la façon dont nous utilisons vos informations personnelles, en particulier dans
            les cas où vous contestez l’exactitude de vos informations personnelles ; le traitement est illégal et vous
            vous opposez à la suppression de vos informations personnelles ; nous n’avons plus besoin de vos
            informations personnelles aux fins du traitement, mais vous exigez les informations pour l’établissement,
            l’exercice ou la défense de revendications juridiques ; ou vous avez émis une objection au traitement en
            vertu de la section 6.6 et en attendant l'analyse sur le point de savoir si les intérêts légitimes de Rendez
            Wine priment sur les vôtres.
        </p>

        <h3>
            6.6 Objection au traitement.
        </h3>

        <p>
            Dans certaines juridictions, la loi applicable peut vous autoriser à demander à Rendez Wine et Rendez Wine
            de ne pas utiliser vos informations personnelles à certaines fins spécifiques (y compris le profilage) si
            ledit traitement est basé sur un intérêt légitime. Si vous vous opposez à ce traitement, Rendez Wine et/ou
            Rendez Wine ne traiteront plus vos informations personnelles à ces fins, à moins que nous puissions
            démontrer des raisons sérieuses et légitimes à ce traitement ou que ce traitement soit nécessaire pour
            l’établissement, l’exercice ou la défense de revendications juridiques.
        </p>

        <p>
            Si vos informations personnelles sont traitées à des fins de marketing direct, vous pouvez à tout moment
            demander à Rendez Wine de cesser de traiter vos données à ces fins de marketing direct en envoyant un e-mail
            à rendez.wine@gmail.com.
        </p>

        <h3>
            6.7 Dépôt de plaintes.
        </h3>

        <p>
            Vous avez le droit de déposer des plaintes concernant les activités de traitement de données effectuées par
            Rendez Wine et Rendez Wine devant les autorités compétentes de protection des données. Si votre Responsable
            de traitement est Rendez Wine Irlande ou Rendez Wine GSL, vous avez le droit de déposer une plainte auprès
            de son autorité de surveillance principale, le Commissaire à la protection des données irlandais. Si votre
            Responsable de traitement des paiements est Rendez Wine, vous avez le droit de déposer une plainte auprès de
            son autorité de surveillance principale, le Bureau du préposé à la protection des données.
        </p>

        <h2>
            7. ACTIVITÉS À L’ÉCHELLE MONDIALE
        </h2>

        <p>
            Pour faciliter nos activités mondiales, Rendez Wine peuvent transférer, conserver et traiter vos
            informations dans notre famille d’entreprises ou avec des fournisseurs basés en Europe, en Inde, en
            Asie-Pacifique, ainsi qu’en Amérique du Nord et du Sud. Les lois dans ces pays peuvent différer de la
            législation applicable à votre Pays de résidence. Par exemple, les informations recueillies au sein de l’EEE
            peuvent être transférées, conservées et traitées à l’extérieur de l’EEE aux fins décrites dans la présente
            Charte de protection des données à caractère personnel. Lorsque nous transférerons, conservons et traitons
            vos informations personnelles en dehors de l’EEE, nous veillons à ce que des mesures de protection
            appropriées soient en place pour assurer un niveau adéquat de protection des données.
        </p>

        <h2>
            8. SÉCURITÉ
        </h2>

        <p>
            Nous mettons en place et actualisons en permanence des mesures administratives, techniques et physiques en
            matière de sécurité pour protéger vos informations contre les accès, pertes, destructions ou altérations non
            autorisés. Certaines des mesures de protection que nous utilisons pour protéger vos informations sont des
            pare-feu et le cryptage des données, ainsi que des contrôles d’accès aux informations. Si vous savez ou avez
            des raisons de croire que vos informations d’identification de Compte Rendez Wine ont été perdues, volées,
            détournées ou autrement compromises, ou en cas d’utilisation non autorisée réelle ou soupçonnée de votre
            Compte Rendez Wine, veuillez nous contacter en suivant les instructions de la section « Contactez-nous »
            ci-dessous.
        </p>

        <h2>
            9. Modifications de la présente Charte de protection des données à caractère personnel
        </h2>

        <p>
            Rendez Wine se réserve le droit d’apporter toute modification à la présente Charte de protection des données
            à caractère personnel à tout moment, conformément à la présente clause. Si nous apportons une modification à
            la présente Charte de protection des données à caractère personnel, nous publierons la nouvelle version sur
            la Plateforme Rendez Wine et actualiserons la date de « Dernière mise à jour » figurant en haut de la
            présente Charte de protection des données à caractère personnel. Nous vous informerons également de la
            modification par e-mail dans un délai minimum de trente (30) jours avant la date de sa prise d’effet. Si
            vous n’êtes pas d’accord avec les termes de la nouvelle Charte de protection des données à caractère
            personnel, vous pouvez supprimer votre Compte. Si vous ne supprimez pas votre Compte avant la date d’entrée
            en vigueur de la nouvelle Charte de protection des données à caractère personnel, votre accès et votre
            utilisation continus de la Plateforme Rendez Wine seront soumis à la nouvelle Charte de protection des
            données à caractère personnel.
        </p>

        <h2>
            10. Contactez-nous
        </h2>

        <p>
            Si vous avez des questions ou des plaintes à formuler concernant la présente Charte de protection des
            données à caractère personnel ou les pratiques de Rendez Wine en matière de traitement des informations,
            vous pouvez nous contacter via la page contact ou par courriel à l’adresse suivante : rendez.wine@gmail.com
            ou par courrier postal : 33 rue des échaliers 21200 Beaune.
        </p>
    </div>
</div>
