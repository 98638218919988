import { VisitInterface } from '../interfaces/visit.interface';

export class ClientVisitModel {
  private readonly _visits: VisitInterface[];
  private readonly _futureVisits: VisitInterface[];
  private readonly _passedVisits: VisitInterface[];

  constructor(visits: VisitInterface[]) {
    this._visits = visits;
    this._futureVisits = visits.filter(
      (elem) => elem.start.toMillis() > new Date().getTime()
    );
    this._passedVisits = visits.filter(
      (elem) => elem.start.toMillis() < new Date().getTime()
    );
  }

  get visits(): VisitInterface[] {
    return this._visits;
  }

  get futureVisits(): VisitInterface[] {
    return this._futureVisits;
  }

  get passedVisits(): VisitInterface[] {
    return this._passedVisits;
  }
}
