import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiButtonModule, TuiDialogContext } from '@taiga-ui/core';
import { TuiMarkerIconModule } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { VisitInterface } from '../../../interfaces/visit.interface';
import { BookService } from '../../../services/book/book.service';

@Component({
  selector: 'rdw-confirm-cancel-booking',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TuiButtonModule,
    TuiMarkerIconModule,
  ],
  templateUrl: './confirm-cancel-booking.component.html',
  styleUrls: ['./confirm-cancel-booking.component.scss'],
})
export class ConfirmCancelBookingComponent {
  readonly prefixTranslation = 'modal.confirm-cancel-booking.';
  isLoading = false;

  constructor(
    private readonly bookService: BookService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      void,
      { visit: VisitInterface; userId: string }
    >
  ) {}

  close() {
    this.context.completeWith();
  }

  async confirmCancelBooking() {
    this.isLoading = true;
    const { visit, userId } = this.context.data;
    await this.bookService.cancelBooking(visit, userId);
    this.isLoading = false;
    this.context.completeWith();
  }
}
