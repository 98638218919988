import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TuiButtonModule, TuiRootModule } from '@taiga-ui/core';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { Auth, authState } from '@angular/fire/auth';
import { ModalService } from './shared/services/modal/modal.service';
import { of, switchMap, tap } from 'rxjs';
import { ClientService } from './shared/services/client/client.service';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    TuiRootModule,
    TuiButtonModule,
    NavbarComponent,
    FooterComponent,
  ],
  selector: 'rdw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'rdwine-webapp-nossr';

  constructor(
    private readonly auth: Auth,
    private readonly modalService: ModalService,
    private readonly clientService: ClientService
  ) {}

  ngOnInit() {
    authState(this.auth)
      .pipe(
        switchMap((user) => {
          if (user) {
            return this.clientService.getClientFromUser(user.uid).pipe(
              tap((client) => {
                if (!client) {
                  this.modalService.showRegistrationModal();
                }
              })
            );
          }

          return of(undefined);
        })
      )
      .subscribe();
  }
}
