import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { LoginFormInterface } from '../../interfaces/login-form.interface';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from '@angular/fire/auth';
import { ButtonComponent } from '../../../../../button/button.component';
import {
  TuiInputPhoneInternationalModule,
  TuiSortCountriesPipeModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-login-phone-form',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TuiInputPhoneInternationalModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiLabelModule,
    TranslocoModule,
    TuiSortCountriesPipeModule,
    TuiButtonModule,
  ],
  template: `
    <h2 class="headline2 md:headline1 text-primary mt-12 mb-5">
      {{ prefixTranslation + 'title' | transloco }}
    </h2>
    <p class="body1 text-primary-dark mb-8">
      {{ prefixTranslation + 'description' | transloco }}
    </p>
    <form [formGroup]="loginForm">
      <label
        class="label w-full mb-10"
        [tuiLabel]="prefixTranslation + 'phone-label' | transloco"
      >
        <tui-input-phone-international
          class="phone"
          nativeId="phoneNumber"
          formControlName="phoneNumber"
          tuiTextfieldSize="l"
          [tuiTextfieldLabelOutside]="true"
          [countries]="(countries | tuiSortCountries | async) || []"
          [(countryIsoCode)]="countryIsoCode"
        >
        </tui-input-phone-international>
      </label>
    </form>

    <div id="check-phone-verifier"></div>

    <button
      class="w-fit mx-auto"
      [showLoader]="isSendingCode"
      [disabled]="loginForm.invalid"
      (click)="loginWithPhone()"
      tuiButton
      size="l"
    >
      {{ prefixTranslation + 'check-phone' | transloco }}
    </button>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPhoneFormComponent {
  isSendingCode = false;
  readonly prefixTranslation = 'navbar.login.modal.';
  readonly countries = Object.values(TuiCountryIsoCode) as TuiCountryIsoCode[];
  readonly loginForm = this.fb.group<LoginFormInterface>({
    phoneNumber: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  @Output() phoneSubmit = new EventEmitter<void>();

  countryIsoCode = TuiCountryIsoCode.FR;

  constructor(private readonly fb: FormBuilder, private readonly auth: Auth) {}

  async loginWithPhone() {
    this.isSendingCode = true;
    if (this.loginForm.valid) {
      const appVerifier = new RecaptchaVerifier(
        'check-phone-verifier',
        {
          size: 'invisible',
        },
        this.auth
      );
      const { phoneNumber } = this.loginForm.value;
      const confirmationResult = await signInWithPhoneNumber(
        this.auth,
        phoneNumber || '',
        appVerifier
      );
      localStorage.setItem(
        'confirmationResult',
        JSON.stringify(confirmationResult.verificationId)
      );
      this.isSendingCode = false;
      this.phoneSubmit.emit();
    }
  }
}
