<h2 class="headline3 mb-5 text-primary-dark" id="establishment-offers">
    {{ prefixTranslation + 'title' | transloco }}
</h2>

<div *ngIf="offers.length; else emptyOffers"
     class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-7 gap-y-3.5">
    <rdw-offer-card *ngFor="let offer of offers" [establishmentId]="establishmentId" [offer]="offer"
                    class="flex-1"></rdw-offer-card>
</div>

<ng-template #emptyOffers>
    <p class="body1">
        {{ prefixTranslation + 'no-offer' | transloco }}
    </p>
</ng-template>
