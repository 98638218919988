import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { AboutHeaderComponent } from './components/about-header/about-header.component';
import { AboutFeaturesComponent } from './components/about-features/about-features.component';
import { AboutHowItWorksComponent } from './components/about-how-it-works/about-how-it-works.component';
import { AboutWinemakersComponent } from './components/about-winemakers/about-winemakers.component';
import { AboutDownloadAppComponent } from './components/about-download-app/about-download-app.component';

@Component({
  selector: 'rdw-about',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    AboutHeaderComponent,
    AboutFeaturesComponent,
    AboutHowItWorksComponent,
    AboutWinemakersComponent,
    AboutDownloadAppComponent,
  ],
  template: `
    <rdw-about-header></rdw-about-header>
    <rdw-about-features></rdw-about-features>
    <rdw-about-how-it-works></rdw-about-how-it-works>
    <rdw-about-winemakers></rdw-about-winemakers>
    <img
      src="assets/images/divider-about.png"
      alt=""
      class="object-cover object-center"
    />
    <rdw-about-download-app></rdw-about-download-app>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent {
  readonly prefixTranslation = 'about.';
}
