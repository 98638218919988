import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-about-header',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  template: `
    <div
      class="h-screen bg-cover bg-center flex flex-col justify-center items-center px-5"
      style="background-image: url('assets/images/bg-about.png')"
    >
      <h1
        class="headline1 text-white text-center mb-5 md:mb-8 max-w-xl"
        [innerHTML]="prefixTranslation + 'title' | transloco"
      ></h1>
      <p
        class="body1 text-white text-center max-w-xl"
        [innerHTML]="prefixTranslation + 'description' | transloco"
      ></p>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutHeaderComponent {
  readonly prefixTranslation = 'about.header.';
}
