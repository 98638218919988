import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiBreadcrumbsModule } from '@taiga-ui/kit';
import { TuiLinkModule } from '@taiga-ui/core';
import { FaqItemInterface } from './components/faq-section/interfaces/faq-item.interface';
import { FaqSectionComponent } from './components/faq-section/faq-section.component';

@Component({
  selector: 'rdw-faq',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TuiBreadcrumbsModule,
    TuiLinkModule,
    FaqSectionComponent,
  ],
  template: `
    <div class="flex pt-20">
      <div class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
          <ng-container *ngFor="let item of breadcrumbItems">
            <a
              class="ariane text-primary-dark"
              *tuiItem
              tuiLink
              [href]="item.routerLink"
            >
              {{ item.caption | transloco }}
            </a>
          </ng-container>
        </tui-breadcrumbs>
        <h1 class="headline1 text-primary mb-8">
          {{ prefixTranslation + 'title' | transloco }}
        </h1>
        <div class="flex gap-x-5">
          <a
            href="/help#faq-account"
            target="_top"
            class="bg-primary-light body2 font-medium text-primary-dark px-5 py-4 rounded-full"
          >
            {{ prefixTranslation + 'account' | transloco }}
          </a>
          <a
            href="/help#faq-book"
            target="_top"
            class="bg-primary-light body2 font-medium text-primary-dark px-5 py-4 rounded-full"
          >
            {{ prefixTranslation + 'book' | transloco }}
          </a>
          <a
            href="/help#faq-cancel"
            class="bg-primary-light body2 font-medium text-primary-dark px-5 py-4 rounded-full"
          >
            {{ prefixTranslation + 'cancel' | transloco }}
          </a>
        </div>
        <h2 class="mt-12 headline3 text-primary-dark mb-8" id="faq-account">
          {{ prefixTranslation + 'account' | transloco }}
        </h2>
        <rdw-faq-section [faqItems]="accountFAQItems"></rdw-faq-section>
        <h2 class="mt-12 headline3 text-primary-dark mb-8" id="faq-book">
          {{ prefixTranslation + 'book' | transloco }}
        </h2>
        <rdw-faq-section [faqItems]="bookingFAQItems"></rdw-faq-section>
        <h2 class="mt-10 headline3 text-primary-dark mb-8" id="faq-cancel">
          {{ prefixTranslation + 'cancel' | transloco }}
        </h2>
        <div class="pb-12">
          <rdw-faq-section [faqItems]="cancelFAQItems"></rdw-faq-section>
        </div>
        <div
          class="flex flex-col md:flex-row bg-primary-light mb-16 rounded-lg shadow"
        >
          <img
            src="assets/images/faq-contact.png"
            alt=""
            class="w-full h-auto md:w-1/3 object-cover object-center rounded-lg"
          />
          <div class="flex flex-col mt-8 md:mt-0 px-8 md:p-20 md:mx-auto">
            <h2 class="headline4 text-secondary mb-4">
              {{ prefixTranslation + 'contact-title' | transloco }}
            </h2>
            <p
              class="body1 text-secondary mb-6"
              [innerHTML]="
                prefixTranslation + 'contact-description' | transloco
              "
            ></p>
            <div class="pb-8">
              <a
                href="/contact"
                class="bg-white px-6 py-4 body1 text-secondary font-semibold rounded-lg"
              >
                {{ prefixTranslation + 'contact-button' | transloco }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
  readonly prefixTranslation = 'faq.';
  readonly breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.faq`,
      routerLink: `/help`,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  readonly accountFAQItems: FaqItemInterface[] = [
    {
      title: this.getTranslation('create-account-title'),
      description: this.getTranslation('create-account-description'),
    },
  ];

  readonly bookingFAQItems: FaqItemInterface[] = [
    {
      title: this.getTranslation('book-location-title'),
      description: this.getTranslation('book-location-description'),
    },
    {
      title: this.getTranslation('cancel-book-title'),
      description: this.getTranslation('cancel-book-description'),
    },
    {
      title: this.getTranslation('update-book-title'),
      description: this.getTranslation('update-book-description'),
    },
    {
      title: this.getTranslation('refused-book-title'),
      description: this.getTranslation('refused-book-description'),
    },
    {
      title: this.getTranslation('pay-taxes-book-title'),
      description: this.getTranslation('pay-taxes-book-description'),
    },
    {
      title: this.getTranslation('know-cancel-book-title'),
      description: this.getTranslation('know-cancel-book-description'),
    },
  ];

  readonly cancelFAQItems: FaqItemInterface[] = [
    {
      title: this.getTranslation('ask-service-title'),
      description: this.getTranslation('ask-service-description'),
    },
    {
      title: this.getTranslation('no-book-title'),
      description: this.getTranslation('no-book-description'),
    },
    {
      title: this.getTranslation('more-info-title'),
      description: this.getTranslation('more-info-description'),
    },
    {
      title: this.getTranslation('change-name-title'),
      description: this.getTranslation('change-name-description'),
    },
    {
      title: this.getTranslation('add-book-title'),
      description: this.getTranslation('add-book-description'),
    },
    {
      title: this.getTranslation('signal-problem-title'),
      description: this.getTranslation('signal-problem-description'),
    },
    {
      title: this.getTranslation('how-contact-title'),
      description: this.getTranslation('how-contact-description'),
    },
    {
      title: this.getTranslation('accept-group-title'),
      description: this.getTranslation('accept-group-description'),
    },
    {
      title: this.getTranslation('corporate-event-title'),
      description: this.getTranslation('corporate-event-description'),
    },
  ];

  private getTranslation(key: string) {
    return `${this.prefixTranslation}${key}`;
  }
}
