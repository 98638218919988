import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureInterface } from './interfaces/feature.interface';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-about-features',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslocoModule],
  template: `
    <div
      class="px-4 py-28 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-8 md:px-28 md:py-24"
    >
      <div
        *ngFor="let feature of features"
        class="px-7 py-24 bg-white shadow rounded-lg flex flex-col gap-y-5 md:px-14 md:py-20"
      >
        <rdw-icon
          [iconName]="feature.icon"
          className="fill-primary-dark"
        ></rdw-icon>
        <h2 class="headline3 text-primary-dark">
          {{ prefixTranslation + feature.title | transloco }}
        </h2>
        <p class="body1 font-medium text-primary-dark">
          {{ prefixTranslation + feature.description | transloco }}
        </p>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutFeaturesComponent {
  readonly prefixTranslation = 'about.features.';
  readonly features: FeatureInterface[] = [
    {
      icon: 'share-about',
      title: 'share-title',
      description: 'share-description',
    },
    {
      icon: 'passion',
      title: 'passion-title',
      description: 'passion-description',
    },
    {
      icon: 'near',
      title: 'near-title',
      description: 'near-description',
    },
    {
      icon: 'diversity',
      title: 'diversity-title',
      description: 'diversity-description',
    },
  ];
}
