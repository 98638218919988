import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Auth, authState, signOut } from '@angular/fire/auth';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import {
  TuiDataListModule,
  TuiDialogService,
  TuiHostedDropdownComponent,
  TuiHostedDropdownModule,
} from '@taiga-ui/core';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ProfileNavigationItemType } from './types/profile-navigation-item.type';
import { Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { ClientModel } from '../../../../models/client.model';

@Component({
  selector: 'rdw-navbar-login',
  standalone: true,
  imports: [
    CommonModule,
    TuiHostedDropdownModule,
    TuiDataListModule,
    TranslocoModule,
  ],
  template: `
    <div [ngClass]="classname" class="flex space-x-4 body1 font-bold">
      <ng-container *ngIf="user$ | async as user; else notConnected">
        <tui-hosted-dropdown [content]="dropdown" [(open)]="open">
          <button class="flex flex-row space-x-2 items-center">
            <img
              [src]="'/assets/images/account.png'"
              class="h-10 w-auto"
              alt="Compte - Rendez Wine"
            />
            <div
              class="flex flex-col items-start"
              *ngIf="clientAccount$ | async as client"
            >
              <p class="body2 font-normal">
                {{ prefixTranslation + 'account' | transloco }}
              </p>
              <p class="body2">
                {{ client.firstName }}
              </p>
            </div>
          </button>
        </tui-hosted-dropdown>
        <ng-template #dropdown let-close="close">
          <tui-data-list>
            <tui-opt-group>
              <button
                *ngFor="let item of items"
                tuiOption
                (click)="onClick(item)"
              >
                {{ prefixTranslation + item | transloco }}
              </button>
            </tui-opt-group>
          </tui-data-list>
        </ng-template>
      </ng-container>
      <ng-template #notConnected>
        <button (click)="showLoginModal()">
          {{ prefixTranslation + 'register-login' | transloco }}
        </button>
      </ng-template>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarLoginComponent {
  @Input() classname = '';
  @ViewChild(TuiHostedDropdownComponent) component?: TuiHostedDropdownComponent;
  /*readonly items: ProfileNavigationItemType[] = [`profile`, `visits`, `logout`];*/
  readonly items: ProfileNavigationItemType[] = [`visits`, `logout`];
  readonly prefixTranslation = 'navbar.login.';
  user$ = authState(this.auth);
  open = false;
  clientAccount$ = authState(this.auth).pipe(
    filter((user) => !!user),
    switchMap((user) =>
      docData(doc(this.firestore, `clients/${user?.uid}`), {
        idField: 'id',
      }).pipe(map((client) => ClientModel.fromDocumentData(client)))
    )
  );
  private readonly loginDialog = this.dialogService.open(
    new PolymorpheusComponent(LoginModalComponent, this.injector),
    { closeable: false }
  );

  constructor(
    private readonly auth: Auth,
    private readonly firestore: Firestore,
    private readonly dialogService: TuiDialogService,
    private readonly injector: Injector,
    private readonly router: Router
  ) {}

  async onClick(item: ProfileNavigationItemType): Promise<void> {
    switch (item) {
      case 'profile':
        await this.router.navigate(['/profile']);
        break;
      case 'visits':
        await this.router.navigate(['/visits']);
        break;
      case 'logout':
        await signOut(this.auth);
        break;
    }

    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  showLoginModal() {
    this.loginDialog.subscribe();
  }
}
