import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiInputModule,
  TuiMarkerIconModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiErrorModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { firstValueFrom } from 'rxjs';
import { ClientService } from '../../../services/client/client.service';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-registration-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiCheckboxModule,
    TuiLabelModule,
    TuiTextfieldControllerModule,
    TuiErrorModule,
    TuiCheckboxLabeledModule,
    TuiButtonModule,
    TranslocoModule,
    TuiMarkerIconModule,
  ],
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss'],
})
export class RegistrationModalComponent {
  registrationForm: FormGroup;
  isLoading = false;
  readonly prefixTranslation = 'modal.registration.';

  constructor(
    private formBuilder: FormBuilder,
    private readonly clientService: ClientService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext
  ) {
    this.registrationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      acceptCGU: [false, Validators.requiredTrue],
    });
  }

  async onSubmit() {
    console.log(this.registrationForm.value);
    if (this.registrationForm.valid) {
      this.isLoading = true;
      const { firstName, lastName, email } =
        this.registrationForm.getRawValue();

      await firstValueFrom(
        this.clientService.setClientFirstTime(firstName, lastName, email)
      );

      this.isLoading = false;

      this.context.completeWith();
    }
  }
}
