<div class="flex pt-20">
    <div class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
            <ng-container *ngFor="let item of breadcrumbItems">
                <a
                        *tuiItem
                        [href]="item.routerLink"
                        class="ariane text-primary-dark"
                        tuiLink
                >
                    {{ item.caption | transloco }}
                </a>
            </ng-container>
        </tui-breadcrumbs>

        <ng-container *ngIf="!isLoadingStartPage; else loader">
            <ng-container
                    *ngIf="establishments$ | async as establishments"
            >
                <div class="flex flex-row justify-between">
                    <h1 class="headline1 text-primary">
                        {{ prefixTranslation + 'title' | transloco:{count: establishments.length} }}
                    </h1>

                    <div
                            *ngIf="establishments.length"
                            class="flex md:flex-row md:space-x-2 md:items-center"
                    >
                        <tui-toggle
                                [(ngModel)]="showMapControl"
                                class="toggle"
                                id="show-map"
                                nativeId="show-map"
                                size="l"
                        ></tui-toggle>
                        <label class="body2 text-neutral-dark font-medium" for="show-map">
                            {{ prefixTranslation + 'show-map' | transloco }}
                        </label>
                    </div>
                </div>

                <div class="flex flex-row overflow-x-auto gap-x-6 mt-6">
                    <tui-select
                            (ngModelChange)="loadEstablishment({vineyardLocation: $event})"
                            *tuiLet="listVineyardLocation"
                            [(ngModel)]="selectedVineyardLocation"
                            [tuiTextfieldCleaner]="true"
                            [valueContent]="content"
                            class="w-72"
                    >
                        {{ prefixTranslation + 'vineyard-filter' | transloco }}
                        <tui-data-list-wrapper
                                *tuiDataList
                                [itemContent]="content"
                                [items]="listVineyardLocation"
                                size="l"
                        ></tui-data-list-wrapper>
                    </tui-select>

                    <ng-template
                        #content
                        let-data
                    >
                        <div class="template">
                            <div class="name">{{ 'general.vineyardLocation.' + data | transloco }}</div>
                        </div>
                    </ng-template>

                    <tui-input-date
                            (ngModelChange)="loadEstablishment({date: $event})"
                            [(ngModel)]="selectedDate"
                            [disabledItemHandler]="selectableDays"
                            [tuiTextfieldCleaner]="true"
                            class="w-72"
                            tuiTextfieldSize="l"
                    >
                        {{ prefixTranslation + 'prefDate' | transloco}}
                    </tui-input-date>
                </div>

                <ng-container *ngIf="!isLoadingEstablishments; else loader">

                    <div class="flex gap-x-3">
                        <div [ngClass]="{ 'not-display-grid': showMapControl, 'display-grid': !showMapControl}"
                             class="mt-8 mb-12 md:mb-20 gap-x-5 gap-y-4"
                        >
                            <rdw-establishment-card
                                    *ngFor="let establishment of establishments"
                                    [establishment]="establishment"
                            ></rdw-establishment-card>
                        </div>

                        <rdw-establishment-map [establishments]="establishments"
                                               [ngClass]="{'flex-1': showMapControl, hidden: !showMapControl}"
                                               class="mt-8 mb-12 md:mb-20"
                        ></rdw-establishment-map>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-template #loader>
            <tui-loader [showLoader]="true">
                <div class="w-full h-48"></div>
            </tui-loader>
        </ng-template>
    </div>
</div>
