<google-map [center]="mapCenter" [options]="mapOptions" [zoom]="zoom"
            class="w-full test-map md:sticky md:top-20 relative">
    <map-marker #marker="mapMarker"
                (mapClick)="showInfoWindow(markerPostion, marker)"
                (mouseout)="hideInfoWindow(markerPostion)"
                (mouseover)="showInfoWindow(markerPostion, marker)"
                *ngFor="let markerPostion of markers"
                [icon]="markerPostion.icon"
                [label]="markerPostion.label"
                [position]="markerPostion.position">
    </map-marker>

    <map-info-window *ngIf="activeMarker">
        <div class="flex gap-x-3">
            <img [src]="getPhoto(activeMarker.establishment)" alt=""
                 class="h-16 w-16 object-center object-cover rounded-lg">

            <div class="flex flex-col">
                <h1 class="text-lg text-primary-dark">
                    {{ activeMarker.title }}
                </h1>
                <a *ngIf="activeMarker" [routerLink]="['/establishments', activeMarker.id]"
                   class="text-primary-dark underline">
                    {{ prefixTranslation + 'see-establishment' | transloco }}
                </a>
            </div>
        </div>
    </map-info-window>
</google-map>
