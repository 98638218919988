<div class="flex justify-center">
    <tui-marker-icon
            class="tui-space_right-2 mb-8"
            mode="success"
            new
            size="xl"
            src="tuiIconCheckLarge"
    ></tui-marker-icon>
</div>

<h1 class="text-secondary headline2 mb-4">
    {{prefixTranslation + 'title' | transloco}}
</h1>

<p class="text-primary-dark body1 mb-6">
    {{prefixTranslation + 'description' | transloco}}
</p>

<div class="flex justify-center">
    <button (click)="close()" class="mx-auto" tuiButton>
        {{prefixTranslation + 'close-button' | transloco}}
    </button>
</div>
