import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstablishmentInterface } from '../../interfaces/establishment.interface';
import { IconComponent } from '../icon/icon.component';
import { TuiButtonModule } from '@taiga-ui/core';
import { ClientService } from '../../services/client/client.service';
import { Constant } from '../../utils/constant';

@Component({
  selector: 'rdw-establishment-card',
  standalone: true,
  imports: [CommonModule, IconComponent, TuiButtonModule],
  template: `
    <div
      class="flex flex-col grow-0 bg-white shadow rounded-lg overflow-hidden"
    >
      <div
        class="aspect-square w-full bg-cover bg-center rounded-t-lg relative mb-3"
      >
        <img
          [src]="getPhoto()"
          class="w-full h-full aspect-square object-cover object-center rounded-t-lg"
        />
        <a [href]="url" class="w-full h-full absolute top-0 left-0">
          <span class="sr-only"> Voir {{ establishment.name }} </span>
        </a>
        <button
          (click)="toggleFavorite(establishment)"
          tuiIconButton
          size="m"
          appearance="whiteblock"
          shape="rounded"
          [icon]="
            !!establishment.isFavorite ? 'tuiIconHeartFilled' : 'tuiIconHeart'
          "
          class="right-5 top-5 absolute"
        ></button>
      </div>
      <a [href]="url">
        <p
          class="px-3 body1 text-primary font-extrabold mb-1 whitespace-nowrap text-ellipsis"
        >
          {{ establishment.name }}
        </p>
        <div class="flex space-x-1 px-3 justify-start items-center mb-5">
          <rdw-icon
            iconName="marker"
            className="fill-primary h-4 w-auto"
          ></rdw-icon>
          <span class="body2 text-primary-dark">
            {{ establishment.address.split(',')[1] }}
          </span>
        </div>
      </a>
    </div>
  `,
  styles: [
    `
      :host {
        :ng-deep {
          [tuiIconButton] {
            @apply bg-white/75;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstablishmentCardComponent {
  @Input() establishment!: EstablishmentInterface;

  constructor(private readonly clientService: ClientService) {}

  get url() {
    return `/establishments/${this.establishment.id}`;
  }

  toggleFavorite(establishment: EstablishmentInterface) {
    this.clientService.toggleFavorite(establishment).subscribe();
  }

  getPhoto() {
    const photo = this.establishment.photos[0].src;
    return photo ? photo : Constant.noImgUrl;
  }
}
