import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-about-how-it-works',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  template: `
    <div class="bg-primary-dark py-8 px-4 md:py-20 md:px-20">
      <h2 class="headline2 text-white mb-8 text-start md:text-center md:mb-16">
        {{ prefixTranslation + 'title' | transloco }}
      </h2>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-x-6 gap-y-6">
        <div
          class="px-8 py-12 bg-white shadow-lg flex flex-col rounded-lg gap-y-5"
          *ngFor="let step of steps; let index = index"
        >
          <div
            class="h-12 w-12 bg-primary-light rounded-full flex justify-center item-center"
          >
            <p
              class="text-center headline3 text-primary-dark mx-auto my-auto font-extrabold"
            >
              {{ index + 1 }}
            </p>
          </div>
          <h3 class="headline3 text-primary-dark">
            {{ prefixTranslation + step.title | transloco }}
          </h3>
          <p
            class="body1 text-primary-dark"
            [innerHTML]="prefixTranslation + step.description | transloco"
          ></p>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutHowItWorksComponent {
  readonly prefixTranslation = 'about.how.';
  readonly steps: { title: string; description: string }[] = [
    {
      title: 'plan-title',
      description: 'plan-description',
    },
    {
      title: 'download-title',
      description: 'download-description',
    },
    {
      title: 'book-title',
      description: 'book-description',
    },
    {
      title: 'visit-title',
      description: 'visit-description',
    },
  ];
}
