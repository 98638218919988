import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientService } from '../../../../shared/services/client/client.service';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiButtonModule } from '@taiga-ui/core';
import { SettingItemInterface } from '../../interfaces/setting-item.interface';

@Component({
  selector: 'rdw-profile-mobile-content',
  standalone: true,
  imports: [CommonModule, TranslocoModule, TuiButtonModule],
  template: `
    <div class="flex flex-col" *ngIf="client$ | async as client">
      <div
        class="flex flex-col justify-center items-center py-12 bg-primary-dark"
      >
        <img
          class="mb-5"
          src="assets/icons/profile-logo.svg"
          alt="Logo Rendez Wine profil"
        />
        <p class="body1 text-white">
          {{ client.firstName }} <b>{{ client.lastName }}</b>
        </p>
      </div>

      <div class="px-4 mt-8 mb-2">
        <h2
          class="mb-4 font-extrabold font-montserrat headline5 text-primary-dark"
        >
          {{ prefixTranslation + 'profile-settings' | transloco }}
        </h2>
        <a
          *ngFor="let item of profileSettings"
          [href]="'/profile/' + item.href"
          class="flex flex-row items-center space-x-2 py-2 text-primary-dark w-full body1 mb-2"
        >
          <img
            class="h-4 w-4"
            [src]="'assets/icons/' + item.icon + '.svg'"
            alt=""
          />
          <span>{{ prefixTranslation + item.title | transloco }}</span>
        </a>
      </div>
    </div>

    <div class="px-4 mt-8 mb-2">
      <h2
        class="mb-4 font-extrabold font-montserrat headline5 text-primary-dark"
      >
        {{ prefixTranslation + 'profile-assist' | transloco }}
      </h2>
      <a
        *ngFor="let item of helpSettings"
        [href]="item.href"
        class="flex flex-row items-center space-x-2 py-2 text-primary-dark w-full body1 mb-2"
      >
        <img
          class="h-4 w-4"
          [src]="'assets/icons/' + item.icon + '.svg'"
          alt=""
        />
        <span>{{ prefixTranslation + item.title | transloco }}</span>
      </a>
    </div>

    <div class="px-4 mt-8 mb-2">
      <h2
        class="mb-4 font-extrabold font-montserrat headline5 text-primary-dark"
      >
        {{ prefixTranslation + 'profile-political' | transloco }}
      </h2>
      <a
        *ngFor="let item of politicalSettings"
        [href]="item.href"
        class="flex flex-row items-center space-x-2 py-2 text-primary-dark w-full body1 mb-2"
      >
        <img
          class="h-4 w-4"
          [src]="'assets/icons/' + item.icon + '.svg'"
          alt=""
        />
        <span>{{ prefixTranslation + item.title | transloco }}</span>
      </a>
    </div>

    <div class="flex justify-center mt-16 mb-20" *ngIf="client$ | async">
      <button
        (click)="clientService.logout()"
        tuiButton
        appearance="whiteblock-active"
      >
        {{ prefixTranslation + 'logout' | transloco }}
      </button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMobileContentComponent {
  readonly prefixTranslation = 'profile.content.';

  readonly profileSettings: SettingItemInterface[] = [
    {
      title: 'personal-information',
      icon: 'person',
      href: '',
    },
    {
      title: 'preferences',
      icon: 'filter',
      href: '',
    },
    {
      title: 'privacy',
      icon: 'lock',
      href: '',
    },
  ];
  readonly helpSettings: SettingItemInterface[] = [
    {
      title: 'contact',
      icon: 'contact',
      href: '/contact',
    },
    {
      title: 'help',
      icon: 'help-assist',
      href: '/help',
    },
  ];
  readonly politicalSettings: SettingItemInterface[] = [
    {
      title: 'cgu',
      icon: 'book',
      href: '/cgu',
    },
    {
      title: 'terms',
      icon: 'book',
      href: '/cgs',
    },
    {
      title: 'privacy-policy',
      icon: 'lock',
      href: '/privacy-policy',
    },
    {
      title: 'refund-policy',
      icon: 'book',
      href: '/refund-policy',
    },
  ];

  client$ = this.clientService.client$;

  constructor(public readonly clientService: ClientService) {}
}
