import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GoogleMapsModule,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { GeoPoint } from '@angular/fire/firestore';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-establishment-map',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule, TranslocoModule],
  template: `
    <h2 class="headline3 mb-5 text-primary-dark" id="establishment-location">
      {{ prefixTranslation + 'access' | transloco }}
    </h2>
    <div class="flex flex-row w-full aspect-[21/9] rounded-lg overflow-hidden">
      <google-map
        [center]="center"
        width="90vw"
        height="500px"
        [zoom]="zoom"
        (mapClick)="moveMap($event)"
      >
        <map-marker
          #marker="mapMarker"
          *ngFor="let markerPosition of markerPositions"
          [position]="markerPosition"
          [options]="markerOptions"
          (mapClick)="openInfoWindow(marker)"
        ></map-marker>
        <map-info-window>
          {{ establishmentName }}
        </map-info-window>
      </google-map>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstablishmentMapComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;

  readonly prefixTranslation = 'establishments.establishment-map.';

  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  zoom = 12;

  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];

  @Input() localization!: GeoPoint;
  @Input() establishmentName!: string;

  ngOnInit() {
    this.center = {
      lat: this.localization.latitude,
      lng: this.localization.longitude,
    };

    this.markerPositions.push({ ...this.center });
    this.markerOptions.title = this.establishmentName;
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.center = event.latLng.toJSON();
    }
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.center = event.latLng.toJSON();
    }
  }

  openInfoWindow(marker: MapMarker) {
    this.infoWindow?.open(marker);
  }
}
