import { Injectable } from '@angular/core';
import { Auth, authState, signOut } from '@angular/fire/auth';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';
import {
  collection,
  doc,
  docData,
  Firestore,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { ClientInterface } from '../../interfaces/client.interface';
import { EstablishmentInterface } from '../../interfaces/establishment.interface';
import { ModalService } from '../modal/modal.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  favoriteEstablishmentIds$ = authState(this.auth).pipe(
    switchMap((user) =>
      !user
        ? of([])
        : docData(doc(this.firestore, `clients/${user.uid}`)).pipe(
            map((client) =>
              !!client && !!client['favorites']
                ? (client['favorites'] as string[])
                : []
            )
          )
    )
  ) as Observable<string[]>;
  client$ = authState(this.auth).pipe(
    switchMap((user) =>
      !user
        ? of(undefined)
        : docData(doc(this.firestore, `clients/${user.uid}`), {
            idField: 'id',
          }).pipe(map((client) => client as ClientInterface))
    )
  ) as Observable<ClientInterface | undefined>;
  private readonly clientCollection = collection(this.firestore, 'clients');

  constructor(
    private readonly auth: Auth,
    private readonly firestore: Firestore,
    private readonly modalService: ModalService,
    private readonly toastService: ToastService
  ) {}

  async logout() {
    try {
      await signOut(this.auth);
      this.toastService.showSuccess('sign-out', {});
    } catch (e) {}
  }

  toggleFavorite(establishment: EstablishmentInterface) {
    return this.client$.pipe(
      tap(async (client) => {
        if (client) {
          try {
            await updateDoc(doc(this.clientCollection, client.id), {
              favorites: establishment.isFavorite
                ? client.favorites.filter((fav) => fav !== establishment.id)
                : [...client.favorites, establishment.id],
            });

            this.toastService.showSuccess(
              establishment.isFavorite ? 'remove-favorite' : 'add-favorite',
              { name: establishment.name }
            );
          } catch (e) {}

          return;
        }

        this.modalService.showLoginDialog();
      }),
      take(1)
    );
  }

  getClientFromUser(userId: string): Observable<ClientInterface | undefined> {
    return docData(doc(this.firestore, `clients/${userId}`), {
      idField: 'id',
    }).pipe(
      map((client) => {
        if (client) {
          return client as ClientInterface;
        }

        return undefined;
      })
    );
  }

  setClientFirstTime(firstName: string, lastName: string, email: string) {
    return authState(this.auth).pipe(
      switchMap((user) => {
        if (!user) {
          return of(undefined);
        }

        return setDoc(doc(this.firestore, `clients/${user.uid}`), {
          firstName,
          lastName,
          email,
          favorites: [],
          acceptCGU: new Date(),
        });
      })
    );
  }
}
