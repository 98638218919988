import { DocumentData } from '@angular/fire/firestore';

export class ClientModel {
  private id: string;
  private readonly lastName: string;
  private email: string;
  private readonly _firstName: string;

  constructor(id: string, firstName: string, lastName: string, email: string) {
    this.id = id;
    this._firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  get firstName(): string {
    return this._firstName;
  }

  get completeName(): string {
    return `${this.lastName} ${this._firstName}`;
  }

  static fromDocumentData(data: DocumentData): ClientModel {
    const { id, firstName, lastName, email } = data;
    return new ClientModel(id, firstName, lastName, email);
  }
}
