import { ContactInterface } from '../../../interfaces/contact.interface';

export class MailModel<T> {
  to: string[];
  template: Template<T>;

  constructor(to: string[], template: Template<T>) {
    this.to = to;
    this.template = template;
  }

  static ofContactTemplate(
    contact: ContactInterface
  ): MailModel<ContactInterface> {
    return new MailModel(['contact@rendezwine.com'], {
      data: contact,
      name: 'contact_v2',
    });
  }

  toJSON() {
    return {
      to: this.to,
      template: this.template,
    };
  }
}

export interface Template<T> {
  data: T;
  name: string;
}
