import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-contact-success-modal',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  template: `
    <div class="flex flex-col items-center justify-center">
      <img
        src="assets/images/contact-success.png"
        alt="Mail Envoyé avec succès - Rendez Wine"
        class="mb-8"
      />
      <h2 class="headline2 text-primary-dark text-center mb-4">
        {{ prefixTranslation + 'title' | transloco }}
      </h2>
      <p class="body1 text-primary-dark text-center mb-8">
        {{ prefixTranslation + 'description' | transloco }}
      </p>
      <a
        href="/"
        class="px-4 py-2 border border-primary-dark rounded-lg text-primary-dark font-semibold"
      >
        {{ prefixTranslation + 'button' | transloco }}
      </a>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSuccessModalComponent {
  readonly prefixTranslation = 'contact.contact-success.';
}
