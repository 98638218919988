import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiBreadcrumbsModule } from '@taiga-ui/kit';
import { TuiLinkModule } from '@taiga-ui/core';

@Component({
  selector: 'rdw-cgu',
  standalone: true,
  imports: [CommonModule, TranslocoModule, TuiBreadcrumbsModule, TuiLinkModule],
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss'],
})
export class CguComponent {
  breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.cgu`,
      routerLink: `/cgu`,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  readonly prefixTranslation = 'cgu.';
}
