import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstablishmentCardComponent } from '../../shared/components/establishment-card/establishment-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiBreadcrumbsModule, TuiToggleModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiLinkModule } from '@taiga-ui/core';
import { VisitService } from '../../shared/services/visits/visit.service';
import { VisitCardComponent } from './components/visit-card/visit-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { ModalService } from '../../shared/services/modal/modal.service';
import { VisitInterface } from '../../shared/interfaces/visit.interface';

@Component({
  selector: 'rdw-visits',
  standalone: true,
  imports: [
    CommonModule,
    EstablishmentCardComponent,
    TranslocoModule,
    TuiBreadcrumbsModule,
    TuiButtonModule,
    TuiLinkModule,
    TuiToggleModule,
    VisitCardComponent,
  ],
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit {
  breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.visits`,
      routerLink: `/visits`,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  readonly prefixTranslation = 'visits.';

  constructor(
    public readonly visitService: VisitService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        tap(({ booking }) => {
          console.log('visits.component Line 54:', booking);
          if (booking && booking === 'success') {
            this.modalService.showSuccessBookingModal(
              async () => await this.router.navigate(['/visits'])
            );
          }
        })
      )
      .subscribe();
  }

  displayCancelModal(visit: VisitInterface) {
    this.modalService.showCancelBookingModal(visit);
  }
}
