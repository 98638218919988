<div class="flex pt-20">
    <div class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
            <ng-container *ngFor="let item of breadcrumbItems">
                <a
                        *tuiItem
                        [routerLink]="item.routerLink"
                        class="ariane text-primary-dark"
                        tuiLink
                >
                    {{ item.caption | transloco }}
                </a>
            </ng-container>
        </tui-breadcrumbs>

        <h1 class="headline1 text-primary mb-3">
            {{ prefixTranslation + 'title' | transloco }}
        </h1>

        <p class="text-sm italic">Dernière mise à jour: 13/02/2023</p>

        <h2>
            Conditions d'annulation et de remboursement
        </h2>

        <p>
            RENDEZ WINE applique les conditions d’annulation et de remboursement suivantes. RENDEZ WINE les applique
            pour protéger à la fois le professionnel et le visiteur. Chaque expérience et réservation sur notre site
            précise clairement les conditions d'annulation. Les visiteurs peuvent annuler et vérifier les pénalités
            encourues en consultant leur récapitulatif de voyage et en cliquant sur Annuler sur la réservation en
            question.
        </p>

        <h2>
            Annulation par le visiteur
        </h2>

        <p>
            Une réservation est officiellement annulée si le visiteur clique sur le bouton « annuler ma réservation »
            dans l’onglet Visites > a venir > annuler ma réservation
            Le montant total de la réservation est remboursé à l’utilisateur si l’annulation intervient dans le délai
            minimum de 6H avant la dégustation.
            La Politique de remboursement des voyageurs, les cas de force majeure ou les annulations par RENDEZ WINE
            pour toute autre raison prévue par les Conditions générales peuvent supplanter les conditions d'annulation.
        </p>

        <h2>
            Annulation par RENDEZ WINE
        </h2>

        <p class="mb-10">
            RENDEZ WINE peut parfois être amené à annuler une réservation pour protéger les utilisateurs contre la
            fraude ou pour des raisons de sécurité. Dans de tels cas, RENDEZ WINE informera toujours les professionnels
            et les visiteurs de la situation. Lorsque RENDEZ WINE doit annuler une expérience, les visiteurs recevront
            un remboursement complet et les professionnels ne seront généralement pas admissibles à un revenu.
        </p>
    </div>
</div>
