import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiAccordionModule } from '@taiga-ui/kit';
import { FaqItemInterface } from './interfaces/faq-item.interface';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-faq-section',
  standalone: true,
  imports: [CommonModule, TuiAccordionModule, TranslocoModule],
  template: `
    <tui-accordion>
      <tui-accordion-item *ngFor="let faqItem of faqItems">
        {{ faqItem.title | transloco }}
        <ng-template tuiAccordionItemContent>
          <p [innerHTML]="faqItem.description | transloco"></p>
        </ng-template>
      </tui-accordion-item>
    </tui-accordion>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./faq-section.component.scss'],
})
export class FaqSectionComponent {
  @Input() faqItems!: FaqItemInterface[];
}
