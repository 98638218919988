import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { EstablishmentInterface } from '../../../../shared/interfaces/establishment.interface';
import { EstablishmentCardComponent } from '../../../../shared/components/establishment-card/establishment-card.component';
import { TuiButtonModule } from '@taiga-ui/core';
import { TuiToggleModule } from '@taiga-ui/kit';
import { FormsModule } from '@angular/forms';
import { EstablishmentMapComponent } from '../../../../shared/components/establishment-map/establishment-map.component';

@Component({
  selector: 'rdw-all-establishment',
  standalone: true,
  styleUrls: ['./all-establishment.component.scss'],
  imports: [
    CommonModule,
    TranslocoModule,
    EstablishmentCardComponent,
    TuiButtonModule,
    TuiToggleModule,
    FormsModule,
    EstablishmentMapComponent,
  ],
  template: `
    <div class="flex justify-between items-center px-4 md:px-28">
      <h2 class="headline1 text-primary">
        {{ prefixTranslation + 'title' | transloco }}
      </h2>
      <div
        *ngIf="establishments.length && mapOptionEnabled"
        class="flex md:flex-row md:space-x-2 md:items-center"
      >
        <tui-toggle
          [(ngModel)]="showMapControl"
          class="toggle"
          id="show-map"
          nativeId="show-map"
          size="l"
        ></tui-toggle>
        <label class="body2 text-neutral-dark font-medium" for="show-map">
          {{ prefixTranslation + 'show-map' | transloco }}
        </label>
      </div>
    </div>
    <div class="flex gap-x-3 px-4 md:px-28">
      <div
        [ngClass]="{
          'not-display-grid': showMapControl,
          'display-grid': !showMapControl
        }"
        class="mt-8 mb-12 md:mb-20 gap-x-5 gap-y-4"
      >
        <rdw-establishment-card
          *ngFor="
            let establishment of establishments
              | slice: 0:(withSlice ? 8 : establishments.length)
          "
          [establishment]="establishment"
        ></rdw-establishment-card>
      </div>

      <rdw-establishment-map
        *ngIf="mapOptionEnabled"
        [establishments]="establishments"
        [ngClass]="{ 'flex-1': showMapControl, hidden: !showMapControl }"
        class="mt-8 mb-12 md:mb-20"
      ></rdw-establishment-map>
    </div>

    <div class="flex mb-20" *ngIf="establishments.length >= 8">
      <a
        href="/establishments"
        tuiButton
        appearance="whiteblock-active"
        class="mx-auto"
      >
        {{ prefixTranslation + 'all-domain-button' | transloco }}
      </a>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllEstablishmentComponent {
  @Input() establishments!: EstablishmentInterface[];
  @Input() withSlice = true;
  @Input() mapOptionEnabled = false;

  readonly prefixTranslation = 'home.all-establishments.';

  showMapControl = false;
}
