import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-about-download-app',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  template: `
    <div class="px-4 py-24 md:px-28">
      <div
        class="flex flex-col bg-primary-light rounded-lg shadow md:flex-row md:max-w-screen-xl md:mx-auto"
      >
        <img
          src="assets/images/download-app.png"
          alt="Rendez Wine application mobile"
          class="w-full h-auto rounded-t-lg object-cover object-center mb-8 md:w-2/5 md:h-full md:mb-0 md:rounded-t-none md:rounded-l-lg"
        />
        <div class="md:flex md:flex-col md:justify-center md:px-10">
          <h2 class="headline3 text-primary-dark mb-5 px-3">
            {{ prefixTranslation + 'title' | transloco }}
          </h2>
          <p class="body1 text-primary-dark mb-5 px-3">
            {{ prefixTranslation + 'description' | transloco }}
          </p>
          <div class="flex flex-col gap-y-4 mb-6 px-3 md:flex-row md:gap-x-4">
            <a
              href="https://apps.apple.com/us/app/rendez-wine/id1615186820"
              target="_blank"
            >
              <img
                src="assets/icons/apple.svg"
                alt="Rendez Wine App Store"
                class="h-12 w-auto"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.eddymonnot.rdwine&gl=FR&pli=1"
              target="_blank"
            >
              <img
                src="assets/icons/google.svg"
                alt="Rendez Wine Google Play Store"
                class="h-12 w-auto"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutDownloadAppComponent {
  readonly prefixTranslation = 'about.download.';
}
