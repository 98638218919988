import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ProfileMobileContentComponent } from './components/profile-mobile-content/profile-mobile-content.component';
import { ProfileDesktopContentComponent } from './components/profile-desktop-content/profile-desktop-content.component';

@Component({
  selector: 'rdw-profile',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ProfileMobileContentComponent,
    ProfileDesktopContentComponent,
  ],
  template: ` <div class="flex pt-16 md:pt-24">
    <div class="w-full px-0 md:px-28">
      <rdw-profile-mobile-content
        class="block md:hidden"
      ></rdw-profile-mobile-content>
      <rdw-profile-desktop-content
        class="hidden md:block"
      ></rdw-profile-desktop-content>
    </div>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
  readonly prefixTranslation = 'profile.';
}
