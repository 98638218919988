import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiSvgModule,
} from '@taiga-ui/core';
import { TuiMarkerIconModule } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'rdw-booking-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TuiSvgModule,
    TuiButtonModule,
    TuiMarkerIconModule,
  ],
  templateUrl: './success-booking-modal.component.html',
  styleUrls: ['./success-booking-modal.component.scss'],
})
export class SuccessBookingModalComponent {
  readonly prefixTranslation = 'modal.success-booking.';

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext
  ) {}

  close() {
    this.context.completeWith();
  }
}
