<div class="flex flex-col py-5 px-6 bg-white rounded-lg">

    <div class="flex flex-col md:flex-row md:justify-between">
        <div>
            <div class="flex gap-x-1 items-center mb-2.5">
                <tui-svg [src]="'tuiIconCheckCircle'" class="text-secondary"></tui-svg>
                <p class="body1 text-secondary">
                    {{ getTag() | transloco }}
                </p>
            </div>

            <p class="headline4 text-primary-dark mb-5">
                {{ visit.offerName }}
            </p>

            <div class="flex flex-col gap-x-0 md:flex-row md:gap-x-3">
                <img [src]="getSrcImage()" alt="" class="w-36 h-36 rounded-lg object-cover object-center mb-9">
                <div>
                    <p class="body1 text-primary-dark font-bold mb-4">
                        {{ prefixTranslation + 'recap-booking' | transloco }}
                    </p>

                    <div class="flex gap-x-1 items-center mb-4">
                        <tui-svg [src]="'tuiIconHome'" class="text-neutral-dark"></tui-svg>
                        <a [routerLink]="['/establishments', visit.establishmentId]"
                           class="body1 text-primary-dark underline underline-offset-1">
                            {{ visit.establishmentName }}
                        </a>
                    </div>

                    <div class="flex gap-x-1 items-center mb-4">
                        <tui-svg [src]="'tuiIconClock'" class="text-neutral-dark"></tui-svg>
                        <p class="body1 text-neutral-dark">
                            {{ visit.start.toDate() | date:'dd/MM/yyyy HH:mm' }} - {{visit.end.toDate() | date:'HH:mm'}}
                        </p>
                    </div>

                    <div class="flex gap-x-1 items-center mb-9">
                        <tui-svg [src]="'tuiIconClock'" class="text-neutral-dark"></tui-svg>
                        <p class="body1 text-neutral-dark">
                            {{ prefixTranslation + 'recap-people' | transloco:{people: visit.nbPerson} }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <button (click)="cancelButton()" *ngIf="isFutureVisit && isBefore6Hours()" [appearance]="'flat'" tuiButton>
            {{ prefixTranslation + 'cancel-button' | transloco }}
        </button>
    </div>

    <p *ngIf="isFutureVisit" class="body2 italic text-neutral-dark">
        {{ prefixTranslation + 'cancel-visit-policy' | transloco }}
    </p>
</div>
