import { VineyardLocationEnum } from '../enums/vineyard-location.enum';

export class Constant {
  static noImgUrl =
    'https://firebasestorage.googleapis.com/v0/b/rd-wine.appspot.com/o/shared%2Fno-img%2FLogo-RDW-RVB-2L-vert.png?alt=media&token=bd53f600-fbcb-4ddd-b17b-a8b834a1c557';

  static vineyardDisplayFilter: {
    name: VineyardLocationEnum;
    photo: string;
  }[] = [
    {
      name: VineyardLocationEnum.COTE_CHALONNAISE,
      photo: 'assets/images/vineyard/cote_chalonnaise.jpeg',
    },
    {
      name: VineyardLocationEnum.COTE_MACONNAISE,
      photo: 'assets/images/vineyard/cote_maconnaise.jpeg',
    },
    {
      name: VineyardLocationEnum.COTE_DE_BEAUNE,
      photo: 'assets/images/vineyard/cote_de_beaune.jpeg',
    },
    {
      name: VineyardLocationEnum.COTE_DE_NUITS,
      photo: 'assets/images/vineyard/cote_de_nuits.jpeg',
    },
    {
      name: VineyardLocationEnum.CHABLISIEN,
      photo: 'assets/images/vineyard/chablis.jpeg',
    },
  ];
}
