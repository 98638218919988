import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiInputDateModule,
  TuiInputMonthRangeModule,
  TuiSelectModule,
} from '@taiga-ui/kit';
import { AvailabilityInterface } from '../../../../shared/interfaces/availability.interface';
import { LanguageType } from '../../../../shared/types/language.type';
import { TuiBooleanHandler, TuiDay } from '@taiga-ui/cdk';

@Component({
  selector: 'rdw-book-offer-content',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    TuiButtonModule,
    TuiDataListWrapperModule,
    TuiInputDateModule,
    TuiInputMonthRangeModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
  ],
  templateUrl: './book-offer-content.component.html',
  styleUrls: ['./book-offer-content.component.scss'],
})
export class BookOfferContentComponent {
  @Input() bookForm!: FormGroup;
  @Input() availabilityTimeSlots: AvailabilityInterface[] = [];
  @Input() daysAvailable: Date[] = [];
  @Input() isBooking = false;
  @Input() languages: LanguageType[] = [];
  @Input() possiblePersons: number[] = [];
  @Input() price!: number;

  @Output() sendBookForm = new EventEmitter();

  readonly prefixTranslation = 'offers.offer.';

  selectedDay: TuiBooleanHandler<TuiDay> = (date) => {
    const dateFromCalendar = new Date(date.year, date.month, date.day);

    return !this.daysAvailable.find((item) => {
      return item.getTime() == dateFromCalendar.getTime();
    });
  };

  sendBook() {
    this.sendBookForm.emit();
  }
}
