import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferInterface } from '../../interfaces/offer.interface';
import { Constant } from '../../utils/constant';
import { TuiSvgModule } from '@taiga-ui/core';
import { tuiIconClock, tuiIconMap, tuiIconUser } from '@taiga-ui/icons';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'rdw-offer-card',
  standalone: true,
  imports: [CommonModule, TuiSvgModule, TranslocoModule, RouterLink],
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferCardComponent {
  @Input() offer!: OfferInterface;
  @Input() establishmentId!: string;

  readonly prefixTranslation: string = 'offers.offer-card.';
  protected readonly tuiIconMap = tuiIconMap;
  protected readonly tuiIconClock = tuiIconClock;
  protected readonly tuiIconUser = tuiIconUser;

  getPhoto() {
    return this.offer.photo?.src ?? Constant.noImgUrl;
  }
}
