import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GoogleMapsModule,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { EstablishmentInterface } from '../../interfaces/establishment.interface';
import { RouterLink } from '@angular/router';
import { Marker } from './interface/marker.interface';
import { Constant } from '../../utils/constant';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'rdw-establishment-map',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule, RouterLink, TranslocoModule],
  templateUrl: './establishment-map.component.html',
  styleUrls: ['./establishment-map.component.scss'],
})
export class EstablishmentMapComponent {
  markers: Marker[] = [];
  mapCenter = { lat: 47.280513, lng: 4.999437 }; // Set the initial map center coordinates
  zoom = 10;
  mapOptions: google.maps.MarkerOptions = { draggable: true, clickable: true }; // Set the initial zoom level
  activeMarker: Marker | undefined;

  readonly prefixTranslation = 'establishments.establishment-search-map.';

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  private _establishments: EstablishmentInterface[] = [];

  get establishments(): EstablishmentInterface[] {
    return this._establishments;
  }

  @Input()
  set establishments(value: EstablishmentInterface[]) {
    this._establishments = value;
    this.markers = this.establishments.map<Marker>((establishment) => {
      let { latitude, longitude } = establishment.localization;

      if (!latitude || !longitude) {
        const localization = establishment.localization as unknown as {
          _latitude: number;
          _longitude: number;
        };
        console.log('establishment-map.component Line 43:', localization);
        latitude = localization._latitude;
        longitude = localization._longitude;
      }

      return {
        id: establishment.id,
        title: establishment.name,
        description: establishment.description.slice(0, 30),
        position: {
          lat: latitude,
          lng: longitude,
        },
        label: '',
        establishment,
        icon: '/assets/icons/icon_marker.png',
      };
    });
    // Perform any additional logic or actions when the input value changes
  }

  showInfoWindow(marker: Marker, mapMarker: MapMarker) {
    this.activeMarker = marker;
    this.infoWindow.open(mapMarker);
  }

  hideInfoWindow(marker: Marker) {
    if (this.activeMarker === marker) {
      this.activeMarker = undefined;
    }
  }

  getPhoto(establishment: EstablishmentInterface) {
    if (!establishment || !establishment.photos[0]) {
      return Constant.noImgUrl;
    }

    return establishment.photos[0].src;
  }
}
