import { Route } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { EstablishmentComponent } from './pages/establishment/establishment.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { AboutComponent } from './pages/about/about.component';
import { OfferComponent } from './pages/offer/offer.component';
import { CguComponent } from './pages/legals/pages/cgu/cgu.component';
import { CgsComponent } from './pages/legals/pages/cgs/cgs.component';
import { PrivacyPolicyComponent } from './pages/legals/pages/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './pages/legals/pages/refund-policy/refund-policy.component';
import { EstablishmentSearchComponent } from './pages/establishment-search/establishment-search.component';
import { VisitsComponent } from './pages/visits/visits.component';

export const appRoutes: Route[] = [
  {
    title: 'Accueil - Rendez Wine',
    path: '',
    loadComponent: () => HomeComponent,
  },
  {
    path: 'establishments',
    loadComponent: () => EstablishmentSearchComponent,
  },
  {
    path: 'establishments/:id',
    loadComponent: () => EstablishmentComponent,
  },
  {
    path: 'establishments/:id/offers/:offerId',
    loadComponent: () => OfferComponent,
  },
  {
    title: 'Favoris - Rendez Wine',
    path: 'favorites',
    loadComponent: () => FavoritesComponent,
  },
  {
    title: 'Profil - Rendez Wine',
    path: 'profile',
    loadComponent: () => ProfileComponent,
  },
  {
    title: 'Mes réservations - Rendez Wine',
    path: 'visits',
    loadComponent: () => VisitsComponent,
  },
  {
    title: 'Contact - Rendez Wine',
    path: 'contact',
    loadComponent: () => ContactComponent,
  },
  {
    title: 'Aide - Rendez Wine',
    path: 'help',
    loadComponent: () => FaqComponent,
  },
  {
    title: 'A propos de nous - Rendez Wine',
    path: 'about',
    loadComponent: () => AboutComponent,
  },
  {
    title: "Conditions générale d'utilisation - Rendez Wine",
    path: 'cgu',
    loadComponent: () => CguComponent,
  },
  {
    title: 'Conditions générale de service - Rendez Wine',
    path: 'cgs',
    loadComponent: () => CgsComponent,
  },
  {
    title: 'Politique de confidentialité - Rendez Wine',
    path: 'privacy-policy',
    loadComponent: () => PrivacyPolicyComponent,
  },
  {
    title: 'Politique de remboursement - Rendez Wine',
    path: 'refund-policy',
    loadComponent: () => RefundPolicyComponent,
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
