import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiButtonModule, TuiLinkModule } from '@taiga-ui/core';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { TuiBreadcrumbsModule } from '@taiga-ui/kit';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'rdw-contact',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TuiButtonModule,
    ContactFormComponent,
    TuiBreadcrumbsModule,
    TuiLinkModule,
    RouterLink,
  ],
  template: `
    <div class="flex pt-20">
      <div class="w-full px-4 md:px-28">
        <tui-breadcrumbs class="w-full pt-14 mb-16 hidden md:block">
          <ng-container *ngFor="let item of breadcrumbItems">
            <a
              class="ariane text-primary-dark"
              *tuiItem
              tuiLink
              [href]="item.routerLink"
            >
              {{ item.caption | transloco }}
            </a>
          </ng-container>
        </tui-breadcrumbs>
        <h1 class="headline1 text-primary mb-3">
          {{ prefixTranslation + 'title' | transloco }}
        </h1>
        <p class="body1 text-primary-dark">
          {{ prefixTranslation + 'description' | transloco }}
        </p>
        <rdw-contact-form></rdw-contact-form>
      </div>
      <div
        style="background-image: url('assets/images/wine-contact.png')"
        class="hidden md:block md:w-[40%] bg-cover bg-center"
      ></div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent {
  breadcrumbItems = [
    {
      caption: `breadcrumb.home`,
      routerLink: `/`,
    },
    {
      caption: `breadcrumb.contact`,
      routerLink: `/contact`,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  readonly prefixTranslation = 'contact.';
}
