import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { WinemakerInterface } from './interfaces/winemaker.interface';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
  selector: 'rdw-about-winemakers',
  standalone: true,
  imports: [CommonModule, TranslocoModule, IconComponent],
  template: `
    <div class="flex flex-col py-8 px-4 md:py-20 md:px-20">
      <h2 class="headline2 text-primary-dark mb-8">
        {{ prefixTranslation + 'title' | transloco }}
      </h2>
      <p class="body1 text-primary-dark mb-16">
        {{ prefixTranslation + 'description' | transloco }}
      </p>
      <div
        class="flex flex-row gap-x-5 gap-y-3 overflow-x-scroll md:grid md:grid-cols-3 md:overflow-x-hidden md:items-stretch"
      >
        <div
          class="bg-white shadow-lg rounded-lg flex flex-col"
          *ngFor="let winemaker of winemakers"
        >
          <div
            [style.background-image]="'url(' + winemaker.photo + ')'"
            class="h-72 w-72 md:w-full bg-cover bg-center rounded-t-lg mb-2"
          ></div>
          <h3 class="headline5 text-primary-dark font-extrabold mb-2.5 px-3">
            {{ winemaker.name }}
          </h3>
          <div class="flex flex-row px-3 mb-3 space-x-1">
            <rdw-icon
              iconName="marker"
              className="fill-primary-dark h-4 w-auto"
            ></rdw-icon>

            <span class="body2 text-primary-dark">{{
              winemaker.location
            }}</span>
          </div>
          <blockquote class="body2 italic text-secondary px-3 pb-10">
            « {{ winemaker.arrived }} »
          </blockquote>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutWinemakersComponent {
  readonly prefixTranslation = 'about.winemakers.';
  readonly winemakers: WinemakerInterface[] = [
    {
      photo:
        'https://storage.googleapis.com/rd-wine.appspot.com/establishments%2FKzd8IkbOozUvFzY4ogtfABnEJ3o1%2FCHATEAU%20CHAMIREY%20PHOTO_1920x1080.jpeg',
      name: 'Château de Chamirey',
      location: '71640 Mercurey',
      arrived: 'Disponible depuis Mai 2023',
    },
    {
      photo:
        'https://storage.googleapis.com/rd-wine.appspot.com/establishments%2FD0nImZSAS3XV8cisV3TWD5MS5nV2%2F20180911_074246%20%281%29_1920x1080.jpeg',
      name: 'Domaine Catherine Papillon',
      location: '71260 Péronne',
      arrived: 'Disponible depuis Avril 2023',
    },
    {
      photo:
        'https://storage.googleapis.com/rd-wine.appspot.com/establishments%2FKiAp8yAcCgXDLo8oFMGy9kNdO5e2%2F2018-04-26%20-%20PRO%20-%20CAVE%20FERRAND%20-%200020_1920x1080.jpeg',
      name: 'Domaine Nadine Ferrand',
      location: '71850 Charnay-lès-Mâcon',
      arrived: 'Disponible depuis Avril 2023',
    },
    {
      photo:
        'https://storage.googleapis.com/rd-wine.appspot.com/establishments%2FSNhXOLsW1CYo7CL7sFaK9NHiUhy1%2Fimage%20caveau%20retouch%C3%A9%20800x533%20web_1920x1080.jpeg',
      name: 'La Maison Verizet',
      location: '71260 Viré',
      arrived: 'Disponible depuis Avril 2023',
    },
    {
      photo:
        'https://storage.googleapis.com/rd-wine.appspot.com/establishments%2Fx840RRra7FdcwKw7KzxAkOU3dB72%2Fsenneceretouche_1920x1080.jpeg',
      name: 'VÉRIZET - Le Chais de Sennecé',
      location: '71000 Mâcon',
      arrived: 'Disponible depuis Avril 2023',
    },
    {
      photo:
        'https://storage.googleapis.com/rd-wine.appspot.com/establishments%2FCMlJNhJprNTxf5MGJpcCtJuOF662%2FDomaine%20Vervier%20-%20Version%20Basse%20def%20%2810%29_1920x1080.jpeg',
      name: 'Domaine VERVIER Cindy et Jérémy',
      location: '71960 Solutré-Pouilly',
      arrived: 'Disponible depuis Mars 2023',
    },
  ];
}
