<div class="flex flex-col bg-white rounded-lg drop-shadow overflow-hidden sticky top-24">
    <div class="bg-primary-light pt-6 pb-7 text-center">
        <p *ngIf="price > 0; else free" class="text-primary-dark">
            <span class="font-bold text-[24px]">{{price}} €</span>
            <span class="text-[20px] ml-2">{{ prefixTranslation + 'by-person' | transloco }}</span>
        </p>

        <ng-template #free>
            <p class="text-primary-dark font-bold text-[24px]">
                {{ prefixTranslation + 'free' | transloco}}
            </p>
        </ng-template>
    </div>

    <div class="flex flex-col py-7 px-8">
        <p class="body1 text-primary-dark mb-2">
            {{prefixTranslation + 'preferences' | transloco}}
        </p>

        <form [formGroup]="bookForm" class="mb-3">
            <tui-select
                    [tuiTextfieldLabelOutside]="true"
                    class="tui-space_vertical-4 mb-6"
                    formControlName="nbPerson"
                    tuiTextfieldSize="m"
            >
                {{prefixTranslation + 'nbPersonPref' | transloco}}
                <input
                        [placeholder]="prefixTranslation + 'nbPersonPref' | transloco"
                        tuiTextfield
                />
                <tui-data-list-wrapper
                        *tuiDataList
                        [items]="possiblePersons"
                ></tui-data-list-wrapper>
            </tui-select>
            <tui-select
                    [tuiTextfieldLabelOutside]="true"
                    class="tui-space_vertical-4 mb-6"
                    formControlName="language"
                    tuiTextfieldSize="m"
            >
                {{prefixTranslation + 'languagePref' | transloco}}
                <input
                        [placeholder]="prefixTranslation + 'languagePref' | transloco"
                        tuiTextfield
                />
                <tui-data-list-wrapper
                        *tuiDataList
                        [itemContent]="languageContent"
                        [items]="languages"
                ></tui-data-list-wrapper>

                <ng-template #languageContent let-data>
                    <p class="text-primary-dark font-montserrat">
                        {{ 'general.languages.' + data | transloco }}
                    </p>
                </ng-template>
            </tui-select>
            <div class="flex gap-x-3">
                <div class="flex-1">

                    <tui-input-date
                            [disabledItemHandler]="selectedDay"
                            [tuiTextfieldLabelOutside]="true"
                            class="tui-space_vertical-4 flex-1"
                            formControlName="date"
                            tuiTextfieldSize="m"
                    >
                        {{ prefixTranslation + 'prefDate' | transloco}}
                    </tui-input-date>
                </div>

                <div class="flex-1">
                    <tui-select
                            [disabled]="availabilityTimeSlots.length <= 0"
                            [tuiTextfieldLabelOutside]="true"
                            [valueContent]="timeSlotContent"
                            class="tui-space_vertical-5 flex-1"
                            formControlName="availability"
                            tuiTextfieldSize="m"
                    >

                        {{ (prefixTranslation + (availabilityTimeSlots.length ? 'prefTime' : 'noneTime')) | transloco}}

                        <tui-data-list-wrapper
                                *tuiDataList
                                [itemContent]="timeSlotContent"
                                [items]="availabilityTimeSlots"
                        ></tui-data-list-wrapper>
                    </tui-select>

                    <ng-template
                        #timeSlotContent
                        let-item
                    >
                        <div class="card-item">
                                        <span class="card-name">{{ item.startDate | date:'HH:mm' }}
                                            - {{ item.endDate | date:'HH:mm'}}</span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </form>

        <button (click)="sendBook()" [disabled]="bookForm.invalid"
                [showLoader]="isBooking"
                tuiButton>
            {{ prefixTranslation + 'book-button' | transloco }}
        </button>
    </div>

</div>
