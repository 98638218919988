<div class="flex justify-center">
    <tui-marker-icon
            class="tui-space_right-2 mb-8"
            mode="primary"
            new
            size="l"
            src="tuiIconUserCheckLarge"
    ></tui-marker-icon>
</div>

<h1 class="text-secondary headline2 mb-4">
    {{prefixTranslation + 'title' | transloco}}
</h1>

<p class="text-primary-dark body1 mb-6">
    {{prefixTranslation + 'description' | transloco}}
</p>

<form (submit)="onSubmit()" [formGroup]="registrationForm" class="flex flex-col space-y-4">
    <label
            [tuiLabel]="prefixTranslation + 'firstname-label' | transloco"
            class="tui-space_bottom-4"
    >
        <tui-input
                [tuiTextfieldLabelOutside]="true"
                formControlName="firstName"
                tuiTextfieldSize="m"
        >

        </tui-input>

        <div *ngIf="registrationForm.get('firstName')?.invalid && registrationForm.get('firstName')?.touched"
             class="error-message">
            <span *ngIf="registrationForm.get('firstName')?.errors?.['required']">
                {{prefixTranslation + 'field-required' | transloco}}
            </span>
        </div>
    </label>

    <label
            [tuiLabel]="prefixTranslation + 'lastname-label' | transloco"
            class="tui-space_bottom-4"
    >
        <tui-input
                [tuiTextfieldLabelOutside]="true"
                formControlName="lastName"
                tuiTextfieldSize="m"
        >

        </tui-input>

        <div *ngIf="registrationForm.get('lastName')?.invalid && registrationForm.get('lastName')?.touched"
             class="error-message">
            <span *ngIf="registrationForm.get('lastName')?.errors?.['required']">
                {{prefixTranslation + 'field-required' | transloco}}
            </span>
        </div>
    </label>

    <label
            [tuiLabel]="prefixTranslation + 'email-label' | transloco"
            class="tui-space_bottom-4"
    >
        <tui-input
                [tuiTextfieldLabelOutside]="true"
                formControlName="email"
                tuiTextfieldSize="m"
        >

        </tui-input>

        <div *ngIf="registrationForm.get('email')?.invalid && registrationForm.get('email')?.touched"
             class="error-message">
            <span *ngIf="registrationForm.get('email')?.errors?.['required']">
                {{prefixTranslation + 'field-required' | transloco}}
            </span>
            <span *ngIf="registrationForm.get('email')?.errors?.['email']">
                {{prefixTranslation + 'invalid-email' | transloco}}
            </span>
        </div>
    </label>

    <tui-checkbox-labeled class="mb-4" formControlName="acceptCGU">
        {{prefixTranslation + 'cgu-label' | transloco}}

        <div *ngIf="registrationForm.get('acceptCGU')?.invalid && registrationForm.get('acceptCGU')?.touched"
             class="error-message">
            <span *ngIf="registrationForm.get('acceptCGU')?.errors?.['requiredTrue']">
                {{prefixTranslation + 'cgu-required' | transloco}}
            </span>
        </div>
    </tui-checkbox-labeled>

    <button [disabled]="registrationForm.invalid" [showLoader]="isLoading" tuiButton type="submit">
        {{prefixTranslation + 'send-button' | transloco}}
    </button>
</form>
