import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ContactFormInterface } from '../../interfaces/contact-form.interface';
import {
  personJobToString,
  personJobTypeList,
} from '../../types/person-job.type';
import {
  TuiInputModule,
  TuiInputPhoneInternationalModule,
  TuiRadioListModule,
  TuiSortCountriesPipeModule,
  TuiTextAreaModule,
} from '@taiga-ui/kit';
import { TranslocoModule } from '@ngneat/transloco';
import {
  TuiDialogService,
  TuiLabelModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { ContactInterface } from '../../interfaces/contact.interface';
import { MailModel } from './model/contact-mail.model';
import { addDoc, collection, Firestore } from '@angular/fire/firestore';
import { ContactSuccessModalComponent } from '../contact-success-modal/contact-success-modal.component';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'rdw-contact-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiRadioListModule,
    TranslocoModule,
    TuiLabelModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiInputPhoneInternationalModule,
    TuiSortCountriesPipeModule,
    TuiTextAreaModule,
    ButtonComponent,
  ],
  template: `
    <form [formGroup]="form" class="mt-10">
      <label class="body1 text-primary-dark" for="person-job">
        Vous êtes :
      </label>
      <tui-radio-list
        class="mt-2 block md:hidden"
        id="person-job"
        formControlName="personJob"
        [items]="personJobList"
        [itemContent]="personJobContent"
      ></tui-radio-list>
      <tui-radio-list
        class="mt-2 hidden md:block"
        id="person-job"
        orientation="horizontal"
        formControlName="personJob"
        [items]="personJobList"
        [itemContent]="personJobContent"
      ></tui-radio-list>
      <ng-template #personJobContent let-data>
        <p class="text-primary-dark font-montserrat">
          {{ data.description | transloco }}
        </p>
      </ng-template>

      <div class="flex flex-col w-full md:flex-row md:gap-x-8 gap-y-6 mt-6">
        <label
          class="label w-full"
          [tuiLabel]="prefixTranslation + 'lastName' | transloco"
        >
          <tui-input
            class="input"
            nativeId="lastName"
            formControlName="lastName"
            tuiTextfieldSize="m"
            [tuiTextfieldLabelOutside]="true"
          >
          </tui-input>
        </label>

        <label
          class="label w-full"
          [tuiLabel]="prefixTranslation + 'firstName' | transloco"
        >
          <tui-input
            class="input"
            nativeId="firstName"
            formControlName="firstName"
            tuiTextfieldSize="m"
            [tuiTextfieldLabelOutside]="true"
          >
          </tui-input>
        </label>
      </div>

      <label
        class="label mt-6 w-full md:w-1/2"
        [tuiLabel]="prefixTranslation + 'phoneNumber' | transloco"
      >
        <tui-input-phone-international
          class="phone"
          nativeId="phoneNumber"
          formControlName="phoneNumber"
          tuiTextfieldSize="m"
          [tuiTextfieldLabelOutside]="true"
          [countries]="(countries | tuiSortCountries | async) || []"
          [(countryIsoCode)]="countryIsoCode"
        >
        </tui-input-phone-international>
      </label>

      <label
        class="label mt-6 w-full md:w-1/2"
        [tuiLabel]="prefixTranslation + 'email' | transloco"
      >
        <tui-input
          class="input"
          nativeId="email"
          formControlName="email"
          tuiTextfieldSize="m"
          [tuiTextfieldLabelOutside]="true"
        >
        </tui-input>
      </label>

      <label
        class="label mt-6 w-full md:w-2/3"
        [tuiLabel]="prefixTranslation + 'subject' | transloco"
      >
        <tui-input
          class="input"
          nativeId="subject"
          formControlName="subject"
          tuiTextfieldSize="m"
          [tuiTextfieldLabelOutside]="true"
        >
        </tui-input>
      </label>

      <label
        class="label mt-6 mb-10 w-full md:w-2/3"
        [tuiLabel]="prefixTranslation + 'message' | transloco"
      >
        <tui-text-area
          class="input"
          nativeId="message"
          formControlName="message"
          [rows]="10"
          [maxLength]="500"
          [tuiTextfieldLabelOutside]="true"
        ></tui-text-area>
      </label>

      <div class="flex justify-center pb-28 md:justify-start">
        <rdw-button
          [isDisabled]="form.invalid"
          (buttonClick)="submitForm()"
          iconRight="triangle"
          [buttonText]="prefixTranslation + 'send-button' | transloco"
        ></rdw-button>
      </div>
    </form>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent {
  readonly prefixTranslation = 'contact.contact-form.';
  readonly form: FormGroup<ContactFormInterface>;

  readonly personJobList = personJobTypeList;
  readonly countries = Object.values(TuiCountryIsoCode) as TuiCountryIsoCode[];

  countryIsoCode = TuiCountryIsoCode.FR;

  private readonly successDialog = this.dialogService.open(
    new PolymorpheusComponent(ContactSuccessModalComponent, this.injector),
    {
      closeable: false,
      dismissible: false,
    }
  );

  constructor(
    private readonly fb: FormBuilder,
    private readonly firestore: Firestore,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector
  ) {
    this.form = this.fb.nonNullable.group<ContactFormInterface>({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
      }),
      firstName: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      lastName: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      message: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      personJob: new FormControl(this.personJobList[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      phoneNumber: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      subject: new FormControl('', {
        validators: [Validators.required, Validators.nullValidator],
        nonNullable: true,
      }),
    });
  }

  async submitForm() {
    if (this.form.valid) {
      const {
        email,
        firstName,
        lastName,
        message,
        personJob,
        phoneNumber,
        subject,
      } = this.form.value;
      const contact: ContactInterface = {
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        message: message || '',
        personJob: personJob ? personJobToString(personJob.value) : '',
        phoneNumber: phoneNumber || '',
        subject: subject || '',
      };
      const mailModel = MailModel.ofContactTemplate(contact);

      await addDoc(collection(this.firestore, 'mail'), mailModel.toJSON());

      this.clearForm();
      this.showSuccessDialog();
    }
  }

  private clearForm() {
    this.form.patchValue({
      email: '',
      firstName: '',
      lastName: '',
      personJob: this.personJobList[0],
      message: '',
      phoneNumber: '',
      subject: '',
    });
  }

  private showSuccessDialog() {
    this.successDialog.subscribe();
  }
}
