import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  Firestore,
  query,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { AvailabilityInterface } from '../../interfaces/availability.interface';
import {
  RequestBookingInterface,
  RequestBookingViaStripe,
} from '../../interfaces/request-booking.interface';
import { BookFreeVisitInterface } from '../../interfaces/book-free-visit.interface';
import { RequestCancelBookingInterface } from '../../interfaces/request-cancel-booking.interface';
import { VisitInterface } from '../../interfaces/visit.interface';
import { ToastService } from '../toast/toast.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookService {
  readonly getAvailability = httpsCallableData<
    {
      establishmentId: string;
      startDate: string;
      offerId: string;
    },
    AvailabilityInterface[]
  >(this.functions, 'getAvailabilityWeb');

  readonly bookAvailability = httpsCallableData<
    RequestBookingInterface,
    BookFreeVisitInterface
  >(this.functions, 'bookAvailability');

  readonly bookViaStripe = httpsCallableData<RequestBookingViaStripe, any>(
    this.functions,
    'bookViaStripeWeb'
  );

  readonly cancelBookingFunction = httpsCallableData<
    RequestCancelBookingInterface,
    any
  >(this.functions, 'cancelReservedSlots');

  constructor(
    private readonly functions: Functions,
    private readonly firestore: Firestore,
    private readonly toastService: ToastService
  ) {}

  getDateAvailable(establishmentId: string) {
    return collectionData(
      query(
        collection(
          this.firestore,
          `establishments/${establishmentId}/availabilities`
        ),
        where('start', '>=', Timestamp.fromDate(new Date()))
      ),
      { idField: 'id' }
    );
  }

  async cancelBooking(visit: VisitInterface, userId: string) {
    try {
      await firstValueFrom(
        this.cancelBookingFunction({
          reservedSlotId: visit.id,
          eventId: visit.eventId,
          establishmentId: visit.establishmentId,
          clientId: userId,
        })
      );

      this.toastService.showSuccess('cancel-booking', {});
    } catch (e) {
      this.toastService.showError('cancel-booking', {});
    }
  }
}
