import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponent } from '../../../icon/icon.component';
import {
  TuiDialogContext,
  TuiLabelModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TuiInputPhoneInternationalModule,
  TuiSortCountriesPipeModule,
} from '@taiga-ui/kit';
import { ButtonComponent } from '../../../button/button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginPhoneFormComponent } from './components/login-phone-form/login-phone-form.component';
import { LoginPhoneCodeComponent } from './components/login-phone-code/login-phone-code.component';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'rdw-login-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    IconComponent,
    TuiLabelModule,
    TuiInputPhoneInternationalModule,
    TuiTextfieldControllerModule,
    TuiSortCountriesPipeModule,
    ButtonComponent,
    ReactiveFormsModule,
    LoginPhoneFormComponent,
    LoginPhoneCodeComponent,
  ],
  template: `
    <div class="flex flex-col">
      <div class="relative">
        <h1 class="modal-label text-center text-primary-dark">
          {{ prefixTranslation + 'label' | transloco }}
        </h1>
        <button
          (click)="closeModal()"
          class="absolute rounded-full bg-primary-light top-0 right-0 h-8 w-8 p-2 flex justify-center items-center"
        >
          <rdw-icon
            iconName="cross"
            className="object-fit fill-primary-dark"
          ></rdw-icon>
        </button>
      </div>

      <rdw-login-phone-form
        *ngIf="isPhoneStep"
        (phoneSubmit)="goToCodeStep()"
        class="flex flex-col"
      ></rdw-login-phone-form>

      <rdw-login-phone-code
        (connectionComplete)="closeModal()"
        (previousStep)="backToPhoneStep()"
        *ngIf="isCodeStep"
        class="flex flex-col"
      ></rdw-login-phone-code>
    </div>
  `,
  styles: [
    `
      .modal-label {
        @apply font-poppins font-bold text-[28px] leading-[42px];
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent {
  readonly prefixTranslation = 'navbar.login.modal.';

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext
  ) {}

  private _connectionStep: 'phone' | 'code' = 'phone';

  get connectionStep(): 'phone' | 'code' {
    return this._connectionStep;
  }

  set connectionStep(value: 'phone' | 'code') {
    this._connectionStep = value;
  }

  get isPhoneStep(): boolean {
    return this.connectionStep === 'phone';
  }

  get isCodeStep(): boolean {
    return this.connectionStep === 'code';
  }

  goToCodeStep() {
    this.connectionStep = 'code';
  }

  backToPhoneStep() {
    this.connectionStep = 'phone';
  }

  closeModal() {
    this.context.completeWith();
  }
}
