export enum VineyardLocationEnum {
  COTE_CHALONNAISE = 'COTE_CHALONNAISE',
  COTE_MACONNAISE = 'COTE_MACONNAISE',
  COTE_DE_BEAUNE = 'COTE_DE_BEAUNE',
  COTE_DE_NUITS = 'COTE_DE_NUITS',
  CHABLISIEN = 'CHABLISIEN',
  OENOTOURISME = 'OENOTOURISME',
}

export const listVineyardLocation = [
  VineyardLocationEnum.COTE_CHALONNAISE,
  VineyardLocationEnum.COTE_MACONNAISE,
  VineyardLocationEnum.COTE_DE_BEAUNE,
  VineyardLocationEnum.COTE_DE_NUITS,
  VineyardLocationEnum.CHABLISIEN,
  VineyardLocationEnum.OENOTOURISME,
];
